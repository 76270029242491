import { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  linearProgressClasses,
  styled,
  Typography,
} from '@mui/material';
import { getRandomNumber } from '../../../../../Utils/NumberUtils';
import GameModal from '../GameModal';
import AnswerBox from './AnswerBox';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import { getTimerColor } from '../../../../../Utils/ColourUtils';
import HowToPlayModal from './HowToPlayModal';
import { getFirebaseImage } from '../../../../../Utils/FirebaseUtils';

interface CountdownTimerProps {}

export type RandomNumberAnswer = {
  num: number;
  correct: boolean;
};

const startTimer = getRandomNumber(20, 30);
// const startTimer = 2;

const boxCount = 45;
const answerCount = 5;
const max = boxCount - 1;
const min = 0;

const totalUserAttempts = answerCount + 1;

const devMode = false;
// const devMode = true;

const CountdownTimer: FC<CountdownTimerProps> = () => {
  const [exampleImg1, setExampleImg1] = useState<string>('');
  const [exampleImg2, setExampleImg2] = useState<string>('');
  const [exampleImg3, setExampleImg3] = useState<string>('');

  useEffect(() => {
    getFirebaseImage('/games/countdown-timer/example-1.png', setExampleImg1);
    getFirebaseImage('/games/countdown-timer/example-2.png', setExampleImg2);
    getFirebaseImage('/games/countdown-timer/example-3.png', setExampleImg3);
  }, []);

  const [currentTime, setCurrentTime] = useState(new Date());
  const [seconds, setSeconds] = useState<number>(startTimer);
  const [secondsStr, setSecondsStr] = useState<string>(`${startTimer}`);
  const [randomNumbers, setRandomNumbers] = useState<RandomNumberAnswer[]>([]);

  const [isGameOver, setIsGameOver] = useState(false);
  const [userHasWon, setUserHasWon] = useState(false);

  const [userAttempts, setUserAttempts] = useState(totalUserAttempts);

  const [countdownIntervalId, setCountdownIntervalId] = useState<number | null>(
    null
  );

  const [animationId, setAnimationId] = useState<number | null>(null);
  const animationIdRef = useRef<number | null>(null);

  const [progress, setProgress] = useState(100);

  const [showAlert, setShowAlert] = useState(true);

  const [isHowToPlayModalOpen, setIsHowToPlayModalOpen] = useState(false);

  useEffect(() => {
    // Function to handle the animation frame
    const updateProgress = (timestamp: number) => {
      const elapsed = timestamp - startTimer; // Calculate elapsed time since animation started
      const newProgress = Math.max(
        100 - (elapsed / 1000) * (100 / startTimer),
        0
      ); // Decrease over time, at 10% per second

      setProgress(newProgress);

      // Continue the animation as long as progress is greater than 0
      if (newProgress > 0) {
        // const id = requestAnimationFrame(updateProgress);
        animationIdRef.current = requestAnimationFrame(updateProgress);
        setAnimationId(id); // Store the animation ID in state
      }
    };

    // Start the animation
    const id = requestAnimationFrame(updateProgress);
    setAnimationId(id);

    // Cleanup function to stop the animation when the component unmounts
    return () => cancelAnimationFrame(id);
  }, []);

  // Function to stop the animation
  const stopAnimationRef = useCallback(() => {
    if (animationIdRef.current !== null) {
      cancelAnimationFrame(animationIdRef.current);
      animationIdRef.current = null; // Clear the animation ID after stopping
    }
  }, []);

  useEffect(() => {
    // Generate random numbers only once when the component mounts
    let numbers: RandomNumberAnswer[] = [];
    while (numbers.length < answerCount) {
      let randomNumber = getRandomNumber(min, max);
      // Check if the generated number is already in the array
      if (!numbers.some((item) => item.num === randomNumber)) {
        numbers.push({ num: randomNumber, correct: false });
      }
    }
    setRandomNumbers(numbers);
    console.log('Random Numbers: ', numbers);

    // Set up the interval for updating time and countdown
    const interval = window.setInterval(() => {
      // console.log('seconds: ', seconds);
      if (seconds >= 0) {
        setSeconds((prevSeconds) => {
          const newSeconds = prevSeconds - 1;

          if (newSeconds < 0) return 0;

          const remainingSeconds = newSeconds;
          setSecondsStr(`${remainingSeconds}`);
          return newSeconds;
        });
      } else {
        setIsGameOver(true);
      }
    }, 1000);

    setCountdownIntervalId(interval);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures this runs only once

  useEffect(() => {
    if (randomNumbers.length === 0) return; // Prevents running logic on empty array

    // Check if all answers have been correctly guessed
    const correctCount = randomNumbers.filter(
      (item) => item.correct === true
    ).length;

    if (correctCount === randomNumbers.length) {
      console.log('CONGRATULATIONS, YOU HAVE WON!');
      setUserHasWon(true);
      gameOverHandler();
    } else {
      console.log(`KEEP GOING! ${correctCount}/${randomNumbers.length}`);
    }
  }, [randomNumbers]); // This will only run when randomNumbers changes

  useEffect(() => {
    if (seconds === 0) {
      setIsGameOver(true);
    }
  }, [seconds]);

  useEffect(() => {
    if (userAttempts <= 0) {
      gameOverHandler();
    }
  }, [userAttempts]);

  const updateCorrect = (numToUpdate: number) => {
    const updatedNumbers = randomNumbers.map((item) =>
      item.num === numToUpdate ? { ...item, correct: true } : item
    );
    setUserAttempts((prev) => prev - 1);
    setRandomNumbers(updatedNumbers);
  };

  const indexExists = (index: number) => {
    return randomNumbers.find(
      (obj) => obj.num === index && obj.correct === true
    );
  };

  const stopInterval = () => {
    // Stop the interval using the interval ID
    if (countdownIntervalId !== null) {
      // Ensure intervalId is not null
      window.clearInterval(countdownIntervalId);
      console.log('Interval stopped!');
    }
  };

  const gameOverHandler = () => {
    stopInterval();
    stopAnimationRef();
    setIsGameOver(true);
  };

  const openHowToPlayModalHandler = () => {
    setIsHowToPlayModalOpen((prev) => !prev);
  };

  const gridItems = Array.from({ length: boxCount }, (_, index) => {
    let currentSecondsWithAddedSecondStr = secondsStr;

    if (randomNumbers.find((obj) => obj.num === index)) {
      if (seconds > 0) {
        let currentSecondsWithAddedSecond = seconds - 1;
        const remainingSeconds = currentSecondsWithAddedSecond;
        currentSecondsWithAddedSecondStr = `${remainingSeconds}`;
      }
    }

    return (
      <Grid
        item
        key={index}
        sx={{
          flex: '0 0 calc(20%)', // 5 items per row, adjusting for spacing
          maxWidth: 'calc(20%)', // Ensures each item is limited to 20% width
          paddingLeft: 1,
          paddingTop: 1,
        }}
      >
        <GridItem
          onClick={(e) => {
            updateCorrect(index);
            e.currentTarget.blur(); // Remove focus after click
          }}
          sx={(theme) => ({
            backgroundColor: indexExists(index) ? '#7FDBAC' : '',
            color: indexExists(index) ? theme.palette.common.white : '',
            border: indexExists(index)
              ? '2px solid #2D9F64'
              : `2px solid #cccccc`,
            // #949494, #eeeeee, #cccccc
            fontWeight: 'bold',
            paddingLeft: 1,
            paddingTop: 1,
          })}
        >
          <Grid container direction={'row'} alignItems="center">
            <Grid
              item
              xs={12}
              sx={{
                lineHeight: '0rem',
                height: '28px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {indexExists(index) ? (
                <SentimentSatisfiedAltIcon />
              ) : (
                <Typography
                  variant="body1"
                  sx={(theme) => ({
                    fontWeight: 500,
                    color:
                      devMode && randomNumbers.find((obj) => obj.num === index)
                        ? 'red'
                        : '',
                  })}
                >
                  {currentSecondsWithAddedSecondStr}
                </Typography>
              )}
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </GridItem>
      </Grid>
    );
  });

  const answerBoxes = randomNumbers.map((randNumObj, index) => (
    <AnswerBox key={index} index={index} value={randNumObj} />
  ));

  const userAttemptsItems = Array.from(
    { length: totalUserAttempts },
    (_, index) => {
      return (
        <Grid
          key={index}
          item
          sx={{
            flex: ' 0 0 calc(16.6%)',
            maxWidth: 'calc(16%)',
            paddingTop: '10px !important',
          }}
        >
          <AttemptItem
            sx={(theme) => ({
              backgroundColor:
                index >= userAttempts
                  ? theme.palette.containerPrimary?.light
                  : '#7FDBAC',
            })}
          />
        </Grid>
      );
    }
  );

  return (
    <Fragment>
      {showAlert && (
        <Alert
          severity="info"
          onClose={() => {
            setShowAlert(false);
          }}
        >
          This game is still in development but feel free to have a play anyway!
        </Alert>
      )}
      <Grid
        container
        direction="row"
        spacing={2}
        justifyContent="center"
        sx={{ p: 2, textAlign: 'center' }}
      >
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => openHowToPlayModalHandler()}
          >
            How to Play
          </Button>
        </Grid>
      </Grid>
      <Container sx={{ maxWidth: '700px !important', marginBottom: 10 }}>
        {/* <GridContainer container spacing={3}>
          <Grid item xs={12}>
            <div
              style={{
                textAlign: 'center',
                fontSize: '1.4rem',
              }}
            >
            </div>
          </Grid>
        </GridContainer> */}
        <GridContainer
          container
          direction="row"
          spacing={1}
          justifyContent="space-between"
        >
          {answerBoxes}
        </GridContainer>
        <Box sx={{ width: '100%', paddingTop: 2 }}>
          <BorderLinearProgress
            variant="determinate"
            value={progress}
            sx={{
              '& .MuiLinearProgress-bar': {
                backgroundColor: getTimerColor(seconds, startTimer), // Progress bar color
              },
              '&.MuiLinearProgress-colorPrimary': {
                backgroundColor: 'efefef', // Background color
              },
            }}
          />
        </Box>
        <GridContainer
          container
          direction="row"
          spacing={2}
          justifyContent="space-between"
        >
          {userAttemptsItems}
        </GridContainer>
        <GridContainer
          container
          spacing={1}
          sx={{
            justifyContent: 'space-between', // Distribute items evenly
            flexWrap: 'wrap',
          }}
        >
          {gridItems}
        </GridContainer>
        <GameModal
          isGameOver={isGameOver}
          seconds={seconds}
          userHasWon={userHasWon}
          highscore={0}
        />
        <HowToPlayModal
          isOpen={isHowToPlayModalOpen}
          setIsHowToPlayModalOpen={setIsHowToPlayModalOpen}
          openHowToPlayModalHandler={openHowToPlayModalHandler}
        >
          <Grid item>
            <Typography
              variant="h5"
              sx={{ fontSize: '24px', fontWeight: '700' }}
            >
              How To Play
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '16px' }}>
              Tap the 'wrong' seconds before time runs out
            </Typography>
            <br />
            <Typography variant="body1" sx={{ ml: 2 }}>
              <ul>
                <li>There are 5 incorrect seconds</li>
                <li>You have a max of 6 attempts</li>
                <li>Keep an eye out for how much time you have left</li>
              </ul>
            </Typography>
            <br />
            <Typography
              variant="body1"
              sx={{ fontSize: '16px', fontWeight: 700 }}
            >
              Examples
            </Typography>
            <Typography variant="body2">
              Tap <b>40</b> here
            </Typography>
            <img src={exampleImg1} alt="Example 1" />
            <br />

            <Typography variant="body2">
              Tap <b>19</b> here
            </Typography>
            <img src={exampleImg2} alt="Example 2" />
            <br />
            <Typography variant="body2">
              After tapping on <b>38</b>
            </Typography>
            <img src={exampleImg3} alt="Example 3" />
            <br />
            <Typography variant="h6">Good luck!</Typography>
          </Grid>
        </HowToPlayModal>
      </Container>
    </Fragment>
  );
};

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

export const GridContainer = styled(Grid)(({ theme }) => ({
  marginTop: '0px',
}));

export const GridItem = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  padding: 10,
  borderRadius: '4px',
  backgroundColor: 'transparent',
  // color: `${theme.palette.primary.main}`,
  transition: 'background-color 0.3s, color 0.3s', // Smooth transition for hover effect
  // boxShadow: "rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px",

  // On hover
  '&:hover': {
    backgroundColor: '#D7F4FF', // Change background on hover
    color: '#000', // Change text color on hover
    border: '2px solid #3893de', // Change border color on hover
    cursor: 'pointer',
  },
}));

export const AttemptItem = styled(`div`)(({ theme }) => ({
  border: '1px solid #ccc',
  backgroundColor: 'green',
  height: '20px',
  borderRadius: '4px',
}));

export default CountdownTimer;
