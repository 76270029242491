import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface QuestionOverlayBoxProps {
  overlayVisible?: boolean;
  indexToRun: number;
  index: number;
  startTime: number;
}

const QuestionOverlayBox: React.FC<QuestionOverlayBoxProps> = ({
  overlayVisible,
  indexToRun,
  index,
  startTime,
}) => {
  const [initialVisible, setInitialVisible] = useState(overlayVisible);
  const [aniDone, setAniDone] = useState(false);

  console.log('index + indexToRun + : ', index, indexToRun + 1);

  // Start transition after initial render and reset width when timer resets
  useEffect(() => {
    if (initialVisible !== overlayVisible) {
      setInitialVisible(overlayVisible); // Trigger transition
    }
  }, [overlayVisible]); // Trigger every t

  const onTransitionEnd = () => {
    setAniDone(true);
  };

  return (
    <Box
      sx={(theme) => ({
        position: 'absolute',
        width: !aniDone
          ? initialVisible && index === indexToRun
            ? '0%'
            : '106%'
          : '0%', // Transition between 100% and 0%
        height: '100%',
        // bgcolor: '#e0f0ff',
        // bgcolor: theme.palette.containerSecondary?.light,
        // bgcolor: 'rgba(243, 243, 243, 1)',
        bgcolor: '#edf5fd',
        borderLeft:
          index < indexToRun
            ? `2px solid #ddd`
            : `2px solid ${theme.palette.primary.main}`,
        borderRight: `1px solid #ddd`,
        // bgcolor: '#cdfbf2',
        top: 0,
        left: 0,
        zIndex: 1, // Ensure it overlays the background
        // clipPath:
        //   'polygon(0 0, 100% 0, 100% 0, calc(100% - 20px) 100%, 0% 100%)',
        transition: initialVisible && !aniDone && `width ${startTime}s linear`,
      })}
      onTransitionEnd={onTransitionEnd}
    />
  );
};

export default QuestionOverlayBox;
