import React, { useState } from 'react';

const ImageWithPlaceholder = ({
  src,
  alt,
  width = 300,
  height = 200,
  onClick,
}) => {
  const [imageSrc, setImageSrc] = useState(
    `https://via.placeholder.com/${width}x${height}`
  );
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
    setImageSrc(src); // Swap to the actual image once it has loaded
  };

  return (
    <img
      src={
        isLoaded
          ? imageSrc
          : `https://via.placeholder.com/${width}x${height}/EEE/444`
      }
      alt={alt}
      width={width}
      height={height}
      onLoad={handleImageLoad}
      style={{
        transition: 'opacity 0.3s ease-in-out',
        opacity: isLoaded ? 1 : 0.5,
        borderRadius: '4px',
      }}
    />
  );
};

export default ImageWithPlaceholder;
