import React, { Fragment, useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import QuestionOverlayBox from './QuestionOverlayBox';
import { NUMBER_QUESTIONS } from '../../../../../constants';

interface QuestionsContainerProps {
  items: any;
  // maxItemsOnScreen: number;
  question: string;
  flash: boolean;
  greenFlash: boolean;
  addQuestionToArrayHandler: () => void;
  setNumberQuestionHandler: () => void;
}

const startTime = 10;

const QuestionsContainer: React.FC<QuestionsContainerProps> = ({
  items,
  addQuestionToArrayHandler,
  setNumberQuestionHandler,
}) => {
  const theme = useTheme();
  const [indexToRun, setIndexToRun] = useState<number>(-1);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [translateBoxX, setTranslateBoxX] = useState(0);
  const [time, setTime] = useState<number>(startTime); // Timer value
  const [textColor, setTextColor] = useState(theme.palette.primary.main);

  useEffect(() => {
    const timerId = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 1) {
          setTranslateBoxX((prevPosition) => prevPosition - 400); // Example of box movement
          setIndexToRun((prev) => prev + 1); // Increment indexToRun
          setTime(startTime); // Reset the timer
          addQuestionToArrayHandler();
          setTextColor('#ddd');
          setNumberQuestionHandler();
          return startTime; // Reset the timer value
        }
        return prevTime - 1; // Decrement the timer
      });
    }, 1000); // Update every second

    return () => clearInterval(timerId); // Cleanup the interval on component unmount
  }, []); // The empty dependency array ensures this runs only once on mount

  useEffect(() => {
    // Trigger the opacity change after the component mounts
    setOverlayVisible(true);
  }, []);

  return (
    // <Fragment>
    <Box
      sx={{
        // overflow: 'hidden',
        display: 'flex',
        // position: 'relative',
        transition: 'transform 1s ease',
        transform: `translateX(${translateBoxX}px)`,
        // width: '100%',
      }}
    >
      {items.map((item, index) => (
        <Box
          key={index}
          sx={(theme) => ({
            color: '#fff',
            height: '50px',
            width: '400px', // Adjust width as needed
            minWidth: '400px',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative', // Allows absolute positioning for overlay
            borderTop:
              index < indexToRun + 1
                ? `2px solid #ddd`
                : `2px solid ${theme.palette.primary.main}`,
            // borderLeft:
            //   index < indexToRun + 1
            //     ? '2px solid #ddd'
            //     : `2px solid ${theme.palette.primary.light}`,
            // borderTop:
            //   index < indexToRun + 1
            //     ? '2px solid #ddd'
            //     : `2px solid ${theme.palette.primary.light}`,
          })}
        >
          {/* Overlay box */}
          <QuestionOverlayBox
            index={index}
            indexToRun={indexToRun + 1}
            overlayVisible={overlayVisible}
            startTime={startTime}
          />

          {/* Text content */}
          <Typography
            variant="h5"
            sx={(theme) => ({
              position: 'relative', // Ensures it displays above the grey overlay
              zIndex: 2, // Higher z-index to stay above overlay
              fontSize: '1.5rem',
              fontWeight: 500,
              color:
                index < indexToRun + 1 ? '#ddd' : theme.palette.primary.main,
              backgroundColor: 'transparent', // Keep background transparent for text
            })}
          >
            {NUMBER_QUESTIONS[item].label}
          </Typography>
        </Box>
      ))}
    </Box>
    // </Fragment>
  );
};

export default QuestionsContainer;
