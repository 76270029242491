import henry1 from '../../resources/images/profile/henry-1.png';
import '../../style/Image.scss';

const BackgroundDiv: React.FC = () => {
  return (
    <div className="background-container">
      {/* <div className="col-sm-6 col-md-3">
        <div className="thumbnail">
          <span className="faded faded-top faded-bottom faded-left">
            <img src="https://loremflickr.com/250/250" />
          </span>{" "}
          <div className="caption">
            <h3>3 edges</h3>
            <p>
              Use: <strong>faded faded-left faded-right faded-bottom</strong>
            </p>
          </div>
        </div>
      </div> */}
      <div className="background-div"></div>
      <div className="content">
        {/* <span
          className="faded faded-left"
          style={{ float: "right", width: "50%", height: "70vh" }}
        >
          <img src={henry1} style={{ height: "100%", width: "100%" }} />
        </span>{" "} */}
      </div>
    </div>
  );
};

export default BackgroundDiv;
