// import '@fontsource/nunito-sans/200.css'; // Specify weight
import { Box, Grid, Typography } from '@mui/material';
import CardWithChipAndDesc from '../../../Card/CardWithChipAndDesc';
import { Item, SectionContainerBox } from '../../../General/Components';
import { Fade, Slide } from 'react-awesome-reveal';
import { TypographyTitle, AboutGridContainer, AboutChip } from './About';
// import Fade from "react-awesome-reveal/Fade";

type ExperienceProps = {
  text?: string;
  backgroundColour: string;
};

type ExperienceType = {
  title: string;
  descriptions: string[];
  company: string;
  companyLink: string;
  location: string;
  period: string;
};

const experiences: ExperienceType[] = [
  {
    title: 'Solution Architect II',
    descriptions: [
      'Actively contributed to refining the demo platform, enhancing user experience, and incorporating feedback from clients and internal teams.  ',
      'Resolved technical challenges swiftly, ensuring uninterrupted demo delivery and seamless account provisioning.',
      'Consistently prioritized user-friendliness and reliability, ensuring Fluent OMS catered to users of various technical backgrounds and needs.',
    ],
    company: 'Fluent Commerce',
    companyLink: 'https://www.fluentcommerce.com',
    location: 'Sydney, Australia',
    period: 'June 2022 - Oct 2024',
  },
  {
    title: 'Software Engineer II',
    descriptions: [
      'Maintained and enhanced the core Order Management System (OMS) by developing new features and resolving platform issues to improve functionality and stability.',
      'Utilized a 2-week Agile sprint process to effectively manage project timelines, ensuring timely delivery of features and bug fixes.',
      'Prioritized development tasks based on client feedback, ensuring the platform remained responsive to user needs.',
      'As Tech Lead, developed a NodeJS command-line tool to facilitate key tasks for internal staff and partners, such as data uploads and account maintenance, significantly boosting team productivity.',
    ],
    company: 'Fluent Commerce',
    companyLink: 'https://www.fluentcommerce.com',
    location: 'Sydney, Australia',
    period: 'June 2021 - June 2022',
  },
  {
    title: 'Site Reliability Engineer',
    descriptions: [
      'Responsibilities include investigating and finding solutions to support tickets raised by clients in an effective and timely manner.',
      'Developing solutions to issues that arise with the platform and creating solutions to make day to day tasks easier for internal staff and clients.',
      'Work with complex AWS solutions as well as developing Web Apps to provide functionality to staff and clients to improve their day to day processes.',
    ],
    company: 'Fluent Commerce',
    companyLink: 'https://www.fluentcommerce.com',
    location: 'Sydney, Australia',
    period: 'June 2019 - June 2021',
  },
  {
    title: 'Software Engineer',
    descriptions: [
      'Developed Java Spring web applications using TDD, supporting clients in viewing wealth portfolios and fulfilling business requirements.',
      'Created a web project using Java 8, Spring MVC, and Bootstrap templates, enhancing security and maintainability.',
      'Worked within an Agile team environment, participating in 2-week sprints and daily stand-ups.',
      'Spearheaded Java Spring web app development using TDD, revolutionising client wealth portfolio management and meeting critical business needs.',
    ],
    company: 'Davy',
    companyLink: 'https://www.davy.ie/',
    location: 'Dublin, Ireland',
    period: 'Oct 2016 - Aug 2018',
  },
  {
    title: 'Junior Software Developer',
    descriptions: [
      'Developed, maintained, and supported Revenue Customs systems, including writing database scripts, creating system process wikis, and designing architecture diagrams.',
      'Enhanced existing systems by refactoring code and implementing new technologies like REST, using Java and related technologies (XML, SQL, Unix scripting) to build and deploy updates in integration and system environments.',
      'Upgraded an SMS Messaging system to improve efficiency, replacing outdated technology with a RESTful web service for better performance.',
      'Provided support to external traders, assisting with integration of their systems into Revenue Customs services, troubleshooting messaging issues, and delivering efficient solutions for complex queries.',
    ],
    company: 'Version 1',
    companyLink: 'https://www.version1.com/',
    location: 'Dublin, Ireland',
    period: 'Sept 2015 - Oct 2016',
  },
];

const tools = [
  'VsCode',
  'IntelliJ',
  'Unity',
  'SourceCode',
  'Google Sheets',
  'Chrome',
  'Miro',
  'Mozilla Firefox Developer Edition',
  'Figma',
  'Terminal / Command Line (iTerm2)',
];

const technologiesAndSkills = [
  'React',
  'Typescript',
  'HTML',
  'CSS',
  'JavaScript',
  // 'SCSS',
  // 'LESS',
  // 'jQuery',
  // 'ChatGPT',
  'Java',
  'JSON',

  'AWS',
  'UI/UX Design',

  'Atlassian Suite',
];

const Experience = ({ text, backgroundColour }: ExperienceProps) => {
  return (
    <SectionContainerBox bgcolor={backgroundColour}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{ mb: 2 }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="h3"
              sx={{ fontWeight: 200, fontFamily: 'inherit' }}
            >
              My Experience
            </Typography>
          </Box>
        </Grid>
        {experiences.map((exp, index) => (
          <>
            <Grid item sm={2} xs={12}></Grid>
            <Grid item sm={8} xs={12}>
              {/* <Slide
                direction={index % 2 === 0 ? 'left' : 'right'}
                duration={2000}
                triggerOnce
              > */}
              <Fade duration={2000} triggerOnce>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Item sx={{ width: '100%' }}>
                    <CardWithChipAndDesc obj={exp} />
                  </Item>
                </Box>
              </Fade>
              {/* </Slide> */}
            </Grid>
            <Grid item sm={2} xs={12}></Grid>
          </>
        ))}
        <Grid item xs={12}>
          <Fade duration={2000}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Item>
                <TypographyTitle
                  sx={{ textAlign: 'center', borderLeft: 'none' }}
                >
                  Technologies and Skills
                </TypographyTitle>
                <AboutGridContainer
                  container
                  direction="row"
                  sx={{ px: { xs: 3, sm: 10 } }}
                >
                  {technologiesAndSkills.map((tas) => (
                    <Grid>
                      <Item>
                        <AboutChip
                          label={tas}
                          color="primary"
                          style={{ fontSize: '16px', fontWeight: 500 }}
                        />
                      </Item>
                    </Grid>
                  ))}
                </AboutGridContainer>
              </Item>
            </Box>
          </Fade>
        </Grid>
        <Grid item xs={12}>
          <Fade duration={2000}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Item>
                <TypographyTitle
                  sx={{ textAlign: 'center', borderLeft: 'none' }}
                >
                  Apps and Tools
                </TypographyTitle>
                <AboutGridContainer
                  container
                  direction="row"
                  sx={{ px: { xs: 3, sm: 10 } }}
                >
                  {tools.map((tas) => (
                    <Grid>
                      <Item>
                        <AboutChip
                          label={tas}
                          color="primary"
                          style={{ fontSize: '16px', fontWeight: 500 }}
                        />
                      </Item>
                    </Grid>
                  ))}
                </AboutGridContainer>
              </Item>
            </Box>
          </Fade>
        </Grid>
      </Grid>
    </SectionContainerBox>
  );
};

export default Experience;
