export const convertWeight = (weight, fromUnit, toUnit) => {
  // Convert all units to grams as an intermediate step
  let grams;
  switch (fromUnit.toLowerCase()) {
    case 'kg':
      grams = weight * 1000;
      break;
    case 'g':
      grams = weight;
      break;
    case 'lb':
      grams = weight * 453.592;
      break;
    case 'oz':
      grams = weight * 28.3495;
      break;
    default:
      return 'Invalid "from" unit';
  }

  // Convert from grams to the desired unit
  let result;
  switch (toUnit.toLowerCase()) {
    case 'kg':
      result = grams / 1000;
      break;
    case 'g':
      result = grams;
      break;
    case 'lb':
      result = grams / 453.592;
      break;
    case 'oz':
      result = grams / 28.3495;
      break;
    default:
      return 'Invalid "to" unit';
  }

  return result;
};

export const convertDistance = (distance, fromUnit, toUnit) => {
  // Convert all units to meters as an intermediate step
  let meters;
  switch (fromUnit.toLowerCase()) {
    case 'm':
      meters = distance;
      break;
    case 'km':
      meters = distance * 1000;
      break;
    case 'ft':
      meters = distance * 0.3048;
      break;
    case 'mi':
      meters = distance * 1609.34;
      break;
    default:
      return 'Invalid "from" unit';
  }

  // Convert from meters to the desired unit
  let result;
  switch (toUnit.toLowerCase()) {
    case 'm':
      result = meters;
      break;
    case 'km':
      result = meters / 1000;
      break;
    case 'ft':
      result = meters / 0.3048;
      break;
    case 'mi':
      result = meters / 1609.34;
      break;
    default:
      return 'Invalid "to" unit';
  }

  return result;
};
