import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Divider, Grid, useTheme } from '@mui/material';
import { TypeAnimation } from 'react-type-animation';

interface GameModalProps {
  isGameOver: boolean;
  userHasWon: boolean;
  seconds: number;
  highscore: number;
  showMessages: boolean;
  showTitle: boolean;
}

// const style = {
//   position: "absolute" as "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   maxHeight: "400px",
//   height: "60%",
//   bgcolor: "background.paper",
//   borderRadius: "6px",
//   boxShadow: 24,
//   maxWidth: "365px",
//   p: 4,
//   pt: 6,
//   pb: 4,
// };

const style = (theme: any) => ({
  // position: 'absolute' as 'absolute',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  // // maxHeight: '400px',
  // // height: '60%',
  // // bgcolor: 'background.paper',
  // bgcolor: '#fff',
  // borderRadius: '6px',
  // boxShadow: 24,
  // // maxWidth: '600px',
  // width: '100%',
  // p: 4,
  // pt: 6,
  // pb: 4,

  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // maxHeight: '400px',
  // height: '60%',
  bgcolor: theme.palette.background.paper, // Use theme's background paper color
  borderRadius: theme.shape.borderRadius, // Use theme's default border radius
  boxShadow: 24,
  // maxWidth: '600px',
  width: '100%',
  // padding: theme.spacing(4),
  // paddingTop: theme.spacing(6),
  // paddingBottom: theme.spacing(4),
  p: 4,
  pt: 6,
  pb: 4,
});
const winningMessages = [
  // Same substring at the start will only be typed out once, initially
  "You're Awesome!",
  1000, // wait 1s before replacing "Mice" with "Hamsters"
  "You're A Rockstar!",
  1000,
  "You're A Genius!",
  1000,
];

const losingMessages = [
  // Same substring at the start will only be typed out once, initially
  'You should try again...',
  1000,
  'Better luck next time!',
  1000, // wait 1s before replacing "Mice" with "Hamsters"
  'Have you had your coffee?',
  1000,
  'Offt, that was close!',
  1000,
];

const GameModal: FC<GameModalProps> = ({
  isGameOver,
  userHasWon,
  seconds,
  highscore = 0,
  showMessages = true,
  showTitle = true,
}) => {
  const theme = useTheme();

  const [open, setOpen] = useState(isGameOver);
  const handleOpen = () => setOpen(true);

  const titleText = userHasWon ? 'Congratulations!' : 'Unlucky!';
  const messages = userHasWon ? winningMessages : losingMessages;

  const handleClose = (
    event: SyntheticEvent,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    // Only close if not a backdrop click
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  useEffect(() => {
    setOpen(isGameOver);
  }, [isGameOver]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        sx={{
          '&:focus-visible': {
            outline: 'none',
          },
          '& *:focus': {
            outline: 'none',
          },
        }}
      >
        <Fade in={open}>
          <Box sx={{ ...style(theme), width: { xs: '95%', sm: '600px' } }}>
            <Grid
              container
              direction="column"
              spacing={0}
              sx={{
                height: '100%',
                // paddingY: 7,
                justifyContent: 'space-between', // Distribute items evenly
              }}
            >
              <Grid item sx={{ textAlign: 'center', mb: 1 }}>
                <Typography
                  id="transition-modal-title"
                  variant="h3"
                  sx={{ fontWeight: 500 }}
                >
                  {highscore}
                </Typography>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  sx={{ fontWeight: 300, mb: 2 }}
                >
                  Highscore
                </Typography>
                {showTitle && (
                  <Divider
                    sx={(theme) => ({
                      bgcolor: theme.palette.primary.main,
                      height: 1,
                    })}
                  />
                )}
              </Grid>

              <Grid item sx={{ textAlign: 'center' }}>
                {showTitle && (
                  <Typography
                    id="transition-modal-title"
                    variant="h4"
                    component="h2"
                    sx={{ fontWeight: 700 }}
                  >
                    {titleText}
                  </Typography>
                )}
                {showMessages && (
                  <TypeAnimation
                    style={{
                      padding: '4px 0px',
                      fontSize: '1.5rem',
                      display: 'inline-block',
                      margin: '10px 0px',
                    }}
                    sequence={messages}
                    wrapper="span"
                    speed={40}
                    repeat={Infinity}
                    preRenderFirstString
                    deletionSpeed={70}
                  />
                )}
                {userHasWon && (
                  <Typography id="transition-modal-description">
                    With <span style={{ fontSize: '1.3rem' }}> </span>
                    <span
                      style={{
                        color: '#2D9F64',
                        fontSize: '1.3rem',
                      }}
                    >
                      {seconds}{' '}
                    </span>
                    seconds to spare...
                  </Typography>
                )}
              </Grid>
              {/* TODO add in functionality for statistics */}
              {/* <Grid item sx={{ borderTop: '2px solid #dedede', paddingTop: 1 }}>
                <Grid container direction="row" spacing={2}>
                  <StatisticsItem value={345} text="Played" />
                  <StatisticsItem value={77} text="Win %" />
                  <StatisticsItem value={38} text="Current Streak" />
                  <StatisticsItem value={987} text="Max Streak" />
                </Grid>
              </Grid> */}
              <Grid item sx={{ mt: 2, textAlign: 'center' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ borderRadius: 10 }}
                  size="large"
                  onClick={() => window.location.reload()}
                >
                  Play
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

const StatisticsItem = ({ value, text }) => {
  return (
    <Grid item xs={3}>
      <Typography variant="h4" fontWeight={600} textAlign={'center'}>
        {value}
      </Typography>
      <Typography
        variant="body1"
        sx={{ fontSize: '0.95rem', textAlign: 'center' }}
      >
        {text}
      </Typography>
    </Grid>
  );
};

export default GameModal;
