import {
  Alert,
  Button,
  Drawer,
  Stack,
  Typography,
  capitalize,
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import {
  BLOCKED_STATUS,
  BLOCKED_STATUS_COLOUR,
  COMPLETED_STATUS,
  COMPLETED_STATUS_COLOUR,
  IN_PROGRESS_STATUS,
  IN_PROGRESS_STATUS_COLOUR,
  ON_HOLD_STATUS,
  ON_HOLD_STATUS_COLOUR,
  PENDING_STATUS,
  PENDING_STATUS_COLOUR,
  WONT_DO_STATUS_COLOUR,
} from '../../../../constants';
import StatusList from './StatusList';
import { list } from '@firebase/storage';
import { getRandomNumber } from '../../../../Utils/NumberUtils';
import { generateLoremIpsum } from '../../../../Utils/StringUtils';

type TaskListProps = {
  text?: string;
};

export type TaskType = {
  status: string;
  statusColour: string;
  title: string;
  description: string;
  dueDate: string;
  priority: string;
  tags: TagType[];
};

export type TagType = {
  text: string;
  colour: string;
};

export type StatusListType = {
  status: string;
  statusColour: string;
  items: TaskType[];
};

export type Anchor = 'top' | 'left' | 'bottom' | 'right';

const taskList = [
  {
    status: 'In Progress',
    statusColour: IN_PROGRESS_STATUS_COLOUR,
    title: 'Task 1',
    description: 'Description for Task 1',
    dueDate: '2023-12-01',
    priority: 'High',
  },
  {
    status: 'In Progress',
    statusColour: IN_PROGRESS_STATUS_COLOUR,
    title: 'Task 2',
    description: 'Description for Task 2',
    dueDate: '2023-12-05',
    priority: 'Medium',
  },
  {
    status: 'In Progress',
    statusColour: IN_PROGRESS_STATUS_COLOUR,
    title: 'Task 3',
    description: 'Description for Task 3',
    dueDate: '2023-12-10',
    priority: 'Low',
  },
  {
    status: 'Completed',
    statusColour: COMPLETED_STATUS_COLOUR,
    title: 'Task 4',
    description: 'Description for Task 4',
    dueDate: '2023-11-25',
    priority: 'High',
  },
  {
    status: 'Completed',
    statusColour: COMPLETED_STATUS_COLOUR,
    title: 'Task 5',
    description: 'Description for Task 5',
    dueDate: '2023-11-30',
    priority: 'Low',
  },
  {
    status: 'Completed',
    statusColour: COMPLETED_STATUS_COLOUR,
    title: 'Task 6',
    description: 'Description for Task 6',
    dueDate: '2023-12-02',
    priority: 'Medium',
  },
  {
    status: 'Pending',
    statusColour: PENDING_STATUS_COLOUR,
    title: 'Task 7',
    description: 'Description for Task 7',
    dueDate: '2023-12-15',
    priority: 'Medium',
  },
  {
    status: 'Pending',
    statusColour: PENDING_STATUS_COLOUR,
    title: 'Task 8',
    description: 'Description for Task 8',
    dueDate: '2023-12-20',
    priority: 'High',
  },
  {
    status: 'Pending',
    statusColour: PENDING_STATUS_COLOUR,
    title: 'Task 9',
    description: 'Description for Task 9',
    dueDate: '2023-12-25',
    priority: 'Low',
  },
  {
    status: 'On Hold',
    statusColour: ON_HOLD_STATUS_COLOUR,
    title: 'Task 7',
    description: 'Description for Task 7',
    dueDate: '2023-12-15',
    priority: 'Medium',
  },
  {
    status: 'On Hold',
    statusColour: ON_HOLD_STATUS_COLOUR,
    title: 'Task 8',
    description: 'Description for Task 8',
    dueDate: '2023-12-20',
    priority: 'High',
  },
  {
    status: 'On Hold',
    statusColour: ON_HOLD_STATUS_COLOUR,
    title: 'Task 9',
    description: 'Description for Task 9',
    dueDate: '2023-12-25',
    priority: 'Low',
  },
  {
    status: 'Blocked',
    statusColour: BLOCKED_STATUS_COLOUR,
    title: 'Task 7',
    description: 'Description for Task 7',
    dueDate: '2023-12-15',
    priority: 'Medium',
  },
  {
    status: 'Blocked',
    statusColour: BLOCKED_STATUS_COLOUR,
    title: 'Task 8',
    description: 'Description for Task 8',
    dueDate: '2023-12-20',
    priority: 'High',
  },
  {
    status: 'Blocked',
    statusColour: BLOCKED_STATUS_COLOUR,
    title: 'Task 9',
    description: 'Description for Task 9',
    dueDate: '2023-12-25',
    priority: 'Low',
  },
];
const groupAndSortByStatus = (items: TaskType[]): StatusListType[] => {
  // Group the data by status
  const groupedData = items.reduce((result: any, item: TaskType) => {
    const status = item.status;
    if (!result[status]) {
      result[status] = [];
    }
    result[status].push(item);
    return result;
  }, {});

  console.log('groupedData: ', groupedData);

  // console.log("groupedData", groupedData);

  // Define the order in which statuses should be sorted
  const statusOrder = [
    'Completed',
    'In Progress',
    'Pending',
    'Blocked',
    'On Hold',
  ]; // You can adjust this order

  // Sort the grouped data based on status order
  const sortedData = statusOrder.map((status) => {
    console.log('status: ', status);
    console.log(' groupedData[status][0]?.statusColour: ', groupedData[status]);

    if (groupedData[status] === undefined) {
      // Do something if groupedData[status] is undefined
      groupedData[status] = []; // For example, initialize it as an empty array
    }

    return {
      status,
      statusColour:
        groupedData[status][0]?.statusColour || BLOCKED_STATUS_COLOUR,
      items: groupedData[status] || [],
    };
  });

  console.log('sortedData', sortedData);

  return sortedData;
};

const getRandomElement = (array: string[]) => {
  return array[Math.floor(Math.random() * array.length)];
};

const getRandomDateString = () => {
  const year = 2023;
  const month = Math.floor(Math.random() * 12) + 1;
  const day = Math.floor(Math.random() * 31) + 1;
  return `${year}-${month.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}`;
};

const generateRandomItems = (numItems: number): TaskType[] => {
  const statuses = [
    IN_PROGRESS_STATUS,
    COMPLETED_STATUS,
    PENDING_STATUS,
    ON_HOLD_STATUS,
    BLOCKED_STATUS,
  ];
  const statusColours = [
    IN_PROGRESS_STATUS_COLOUR,
    COMPLETED_STATUS_COLOUR,
    PENDING_STATUS_COLOUR,
    ON_HOLD_STATUS_COLOUR,
    BLOCKED_STATUS_COLOUR,
  ];

  const randomItems: TaskType[] = [];

  const startingArray = [
    {
      status: COMPLETED_STATUS,
      statusColour: IN_PROGRESS_STATUS_COLOUR,
      title: generateLoremIpsum(getRandomNumber(2, 6)),
      description: 'Description for Task 1',
      dueDate: '2023-12-01',
      priority: 'High',
      tags: [{ text: generateLoremIpsum(1), colour: '#eee' }],
    },
    {
      status: IN_PROGRESS_STATUS,
      statusColour: IN_PROGRESS_STATUS_COLOUR,
      title: generateLoremIpsum(getRandomNumber(2, 6)),
      description: 'Description for Task 2',
      dueDate: '2023-12-05',
      priority: 'Medium',
      tags: [{ text: generateLoremIpsum(1), colour: '#eee' }],
    },
    {
      status: PENDING_STATUS,
      statusColour: PENDING_STATUS_COLOUR,
      title: generateLoremIpsum(getRandomNumber(2, 6)),
      description: 'Description for Task 3',
      dueDate: '2023-12-10',
      priority: 'Low',
      tags: [{ text: generateLoremIpsum(1), colour: '#eee' }],
    },
    {
      status: BLOCKED_STATUS,
      statusColour: BLOCKED_STATUS_COLOUR,
      title: generateLoremIpsum(getRandomNumber(2, 6)),
      description: 'Description for Task 4',
      dueDate: '2023-11-25',
      priority: 'High',
      tags: [{ text: generateLoremIpsum(1), colour: '#eee' }],
    },
    {
      status: ON_HOLD_STATUS,
      statusColour: ON_HOLD_STATUS_COLOUR,
      title: generateLoremIpsum(getRandomNumber(2, 6)),
      description: 'Description for Task 5',
      dueDate: '2023-11-30',
      priority: 'Low',
      tags: [{ text: generateLoremIpsum(1), colour: '#eee' }],
    },
  ];

  randomItems.push(...startingArray);

  for (let i = 0; i < numItems; i++) {
    const randomStatus = getRandomElement(statuses);
    let statusColour = getRandomElement(statusColours);
    const randomTitle = capitalize(generateLoremIpsum(getRandomNumber(2, 6)));
    const randomDescription = capitalize(
      generateLoremIpsum(getRandomNumber(10, 20))
    );
    const randomDueDate = getRandomDateString();
    const randomPriority = ['High', 'Medium', 'Low'][
      Math.floor(Math.random() * 3)
    ];
    const randomTags: TagType[] = [];
    randomTags.push({ text: generateLoremIpsum(1), colour: '#eee' });

    switch (randomStatus) {
      case IN_PROGRESS_STATUS:
        statusColour = IN_PROGRESS_STATUS_COLOUR;
        break;
      case COMPLETED_STATUS:
        statusColour = COMPLETED_STATUS_COLOUR;
        break;
      case PENDING_STATUS:
        statusColour = PENDING_STATUS_COLOUR;
        break;
      case ON_HOLD_STATUS:
        statusColour = ON_HOLD_STATUS_COLOUR;
        break;
      case BLOCKED_STATUS:
        statusColour = BLOCKED_STATUS_COLOUR;
        break;
      default:
        break;
    }

    randomItems.push({
      status: randomStatus,
      statusColour: statusColour,
      title: randomTitle,
      description: randomDescription,
      dueDate: randomDueDate,
      priority: randomPriority,
      tags: randomTags,
    });
  }

  return randomItems;
};

// These are three objects within the initialData array, representing different statuses with items that have descriptions, due dates, and priorities.

const TaskList = ({ text }: TaskListProps) => {
  const [tasks, setTasks] = useState<TaskType[]>(generateRandomItems(14));
  const [sortedTasks, setSortedTasks] = useState<StatusListType[]>([]);
  const [alertOpen, setAlertOpen] = useState(true);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  console.log('Here: ');

  useEffect(() => {
    console.log('sorting tasklist');
    setSortedTasks(groupAndSortByStatus(tasks));
  }, [tasks]);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const addItem = (status: string) => {
    const tempTasks = tasks;
    const newTask = generateRandomItems(1)[0];
    newTask.status = status;
    setTasks([...tasks, newTask]);
  };

  // Function to remove an item from the state array by title
  const removeItem = (title: string) => {
    setTasks(tasks.filter((item) => item.title !== title));
  };

  const changeTaskStatus = (title: string, status: string) => {
    // Make a copy of the original array to avoid mutating the state directly
    const updatedItems = [...tasks];

    // Find the index of the item with the specified title
    const indexToUpdate = updatedItems.findIndex(
      (item) => item.title === title
    );

    if (indexToUpdate !== -1) {
      // If the item with the specified title is found, update its status
      updatedItems[indexToUpdate].status = status;
      // Update the state with the new array
      setTasks(updatedItems);
    }
  };

  return (
    <Fragment>
      <Stack direction="column">
        {alertOpen && (
          <Alert
            severity="info"
            onClose={() => {
              setAlertOpen(false);
            }}
          >
            This 'Tasks' page is for viewing and interacting with the features,
            it does not save any of the changes you make. That feature is in
            development.
          </Alert>
        )}
        <Stack direction="row" padding={6} spacing={3}>
          {sortedTasks.length > 0 &&
            sortedTasks.map((tl: StatusListType, index) => (
              <StatusList
                index={index}
                obj={tl}
                addItem={addItem}
                removeItem={removeItem}
                changeTaskStatus={changeTaskStatus}
                toggleDrawer={toggleDrawer}
              />
            ))}
        </Stack>
        <Drawer
          anchor="right"
          open={state['right']}
          onClose={toggleDrawer('right', false)}
          PaperProps={{
            sx: { width: '50%' },
          }}
        >
          <Stack direction="column" sx={{ p: 6 }}>
            <Typography>
              Magna elit sint occaecat pariatur. Quis fugiat enim in proident
              minim tempor amet velit exercitation aliquip in magna esse. Sit
              irure mollit labore fugiat veniam adipisicing ipsum laborum cillum
              do fugiat officia sunt. Eu reprehenderit adipisicing sunt
              exercitation pariatur magna laboris enim aliquip aliquip. Mollit
              adipisicing velit commodo dolore nulla magna minim qui esse eu
              incididunt incididunt eu. Duis ex minim dolor magna sunt ut eu
              qui. Officia tempor veniam amet aliqua aute commodo ipsum esse
              excepteur excepteur aute. Incididunt dolore sit nulla ad anim
              eiusmod quis. Nisi irure Lorem dolore dolor sint consequat nisi
              sunt consectetur commodo mollit velit velit occaecat.
              Reprehenderit dolore laboris esse consectetur nulla aute minim. Et
              ipsum duis in non occaecat mollit. Voluptate exercitation
              excepteur aliquip est tempor occaecat aute. Mollit enim eiusmod
              cillum aliqua qui dolor dolor aliqua culpa proident. Duis quis
              nulla nulla reprehenderit eu. Amet veniam labore minim ea. Sunt
              adipisicing eu culpa labore aute elit sunt Lorem consectetur
              exercitation pariatur. Dolor elit dolor irure eu excepteur cillum.
            </Typography>
          </Stack>
        </Drawer>
      </Stack>
    </Fragment>
  );
};

export default TaskList;
