export const lightenHexColor = (hex, percent) => {
  // Ensure the percent is within the range [0, 100]
  percent = Math.min(100, Math.max(0, percent));

  // Remove the hash if present
  hex = hex.replace(/^#/, '');

  // Parse the hex color to RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate the amount to lighten
  const amount = (percent / 100) * 255;

  // Calculate the new RGB values
  const newR = Math.min(r + amount, 255);
  const newG = Math.min(g + amount, 255);
  const newB = Math.min(b + amount, 255);

  // Convert the new RGB values back to hex
  const newHex = `#${Math.round(newR)
    .toString(16)
    .padStart(2, '0')}${Math.round(newG)
    .toString(16)
    .padStart(2, '0')}${Math.round(newB).toString(16).padStart(2, '0')}`;

  // console.log('newHex', newHex);

  return newHex;
};

export const darkenHexColor = (hex, percent) => {
  // Ensure the percent is within the range [0, 100]
  percent = Math.min(100, Math.max(0, percent));

  // Remove the hash if present
  hex = hex.replace(/^#/, '');

  // Parse the hex color to RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate the amount to darken
  const amount = (percent / 100) * 255;

  // Calculate the new RGB values
  const newR = Math.max(r - amount, 0);
  const newG = Math.max(g - amount, 0);
  const newB = Math.max(b - amount, 0);

  // Convert the new RGB values back to hex
  const newHex = `#${Math.round(newR)
    .toString(16)
    .padStart(2, '0')}${Math.round(newG)
    .toString(16)
    .padStart(2, '0')}${Math.round(newB).toString(16).padStart(2, '0')}`;

  // console.log('newHex: ', newHex);

  return newHex;
};

export const getTimerColor = (remainingTime, totalTime) => {
  let GreenColourRGB = [6, 214, 160];
  let RedColourRGB = [216, 17, 89];

  // Ensure the values are within bounds
  remainingTime = Math.max(0, Math.min(remainingTime, totalTime));

  // Calculate the percentage of time remaining (0 to 1)
  const percentage = remainingTime / totalTime;

  // Green to red interpolation
  // Interpolate between green (#B9FBC0 -> 185, 251, 192) and red (#F1C0E8 -> 241, 192, 232)
  //First numbers are the green RGB values etc

  const red = Math.round(
    GreenColourRGB[0] * percentage + RedColourRGB[0] * (1 - percentage)
  );
  const green = Math.round(
    GreenColourRGB[1] * percentage + RedColourRGB[1] * (1 - percentage)
  );
  const blue = Math.round(
    GreenColourRGB[2] * percentage + RedColourRGB[2] * (1 - percentage)
  );

  // Convert RGB values to hex
  // const redHex = red.toString(16).padStart(2, "0");
  // const greenHex = green.toString(16).padStart(2, "0");
  // const blueHex = blue.toString(16).padStart(2, "0");

  // // Return the hex color string
  // return `#${redHex}${greenHex}${blueHex}`;

  return `rgb(${red}, ${green}, ${blue}, 0.8)`;
  // return `rgb(100, 100, 100, 0.45)`;
};

export const hexToRgba = (hex, alpha = 1) => {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // Parse r, g, b values based on hex length (3 or 6 characters)
  let r, g, b;
  if (hex.length === 3) {
    r = parseInt(hex[0] + hex[0], 16);
    g = parseInt(hex[1] + hex[1], 16);
    b = parseInt(hex[2] + hex[2], 16);
  } else if (hex.length === 6) {
    r = parseInt(hex.slice(0, 2), 16);
    g = parseInt(hex.slice(2, 4), 16);
    b = parseInt(hex.slice(4, 6), 16);
  } else {
    throw new Error('Invalid hex format. Use #RRGGBB or #RGB.');
  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const getRgbaFromNumber = (number) => {
  // Ensure the number is between 0 and 100
  const clampedNumber = Math.min(100, Math.max(0, number));

  // Calculate the grayscale value based on the number (0 = black, 100 = white)
  const grayValue = Math.abs(Math.floor((1 - clampedNumber / 100) * 255));

  // Return the rgba value
  return `rgba(${grayValue}, ${grayValue}, ${grayValue}, 1)`;
};

export const getRgbaValue = (str) => {
  const match = str.match(/rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)/);
  return match ? match[0] : null; // Returns the full RGBA match or null if not found
};
