import { CONVERSIONS } from '../constants';

export const addObjectToArrayInLocalStorage = (key, objectToAdd) => {
  // console.log("key: ", key);
  // console.log("objectToAdd: ", objectToAdd);
  // Retrieve the existing array from localStorage (or create an empty array if it doesn't exist)
  var existingArray = JSON.parse(localStorage.getItem(key)) || [];

  // Add the new object to the array
  // existingArray.push(objectToAdd);

  // Add the new object to the array
  existingArray.unshift(objectToAdd);

  // Convert the updated array to a JSON string
  var updatedJsonString = JSON.stringify(existingArray);

  // Store the updated JSON string back in localStorage
  localStorage.setItem(key, updatedJsonString);
};

export const getItemFromLocalStorage = (key) => {
  // console.log('get key: ', key);
  return localStorage.getItem(key);
};

export const addItemToLocalStorage = (key, valueToAdd) => {
  console.log('key: ', key);
  console.log('valueToAdd: ', valueToAdd);
  localStorage.setItem(key, valueToAdd);
};

export const removeItemByUUID = (key, uuid) => {
  // console.log("remooving item with id: ", uuid);
  const items = JSON.parse(localStorage.getItem(key) || '[]');
  const updatedItems = items.filter((item) => item.uuid !== uuid);
  localStorage.setItem(key, JSON.stringify(updatedItems));
};

export const getJSONFromLocalStorage = (key) => {
  try {
    const jsonString = localStorage.getItem(key);
    if (jsonString) {
      const jsonObject = JSON.parse(jsonString);
      return jsonObject;
    } else {
      console.error(`No data found under the key '${key}' in localStorage.`);
      return null;
    }
  } catch (error) {
    console.error(`Error retrieving JSON from localStorage: ${error}`);
    return null;
  }
};

export const clearObjectInLocalStorage = (key) => {
  localStorage.removeItem(key);
  // localStorage.setItem(CONVERSIONS, []);
};
