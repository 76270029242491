import Carousel from 'react-material-ui-carousel';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';
import '../../../../style/GalleryCarousel.scss';
import beach1 from '../../../../resources/images/beach1.jpg';
import beach2 from '../../../../resources/images/beach2.jpg';
import beach3 from '../../../../resources/images/beach3.jpg';
import beach4 from '../../../../resources/images/beach4.jpg';
import beach5 from '../../../../resources/images/beach5.jpg';
import beach6 from '../../../../resources/images/beach6.jpg';
import beach7 from '../../../../resources/images/beach7.jpg';
import beach8 from '../../../../resources/images/beach8.jpg';
import beach9 from '../../../../resources/images/beach9.jpg';
import beach10 from '../../../../resources/images/beach10.jpg';

type CarouselProps = {
  randomImageUrls: string[];
};

const GalleryCarousel = ({ randomImageUrls }: CarouselProps) => {
  return (
    <div style={{ marginTop: 0, color: '#494949' }}>
      <br />
      <Carousel
        className="Example"
        autoPlay={false}
        indicators
        swipe
        cycleNavigation
        navButtonsAlwaysVisible
        animation="fade"
        duration={1000}
      >
        {items.map((item, index) => {
          return (
            <Banner
              item={item}
              key={index}
              contentPosition={item.contentPosition}
              randomImageUrls={randomImageUrls}
              bannerIndex={index}
            />
          );
        })}
      </Carousel>
      <br />
    </div>
  );
};

type Item = {
  Name: string;
  Caption: string;
  contentPosition: 'left' | 'right' | 'middle';
  Items: { Name: string; Image: string }[];
};

interface BannerProps {
  item: Item;
  contentPosition: 'left' | 'right' | 'middle';
  length?: number;
  randomImageUrls: string[];
  bannerIndex: number;
}

const Banner = (props: BannerProps) => {
  const contentPosition = props.contentPosition
    ? props.contentPosition
    : 'left';
  const totalItems: number = props.length ? props.length : 3;
  const mediaLength = totalItems - 1;

  let items = [];
  const content = (
    <Grid item xs={4} key="content">
      <CardContent className="Content">
        <Typography className="Title">{props.item.Name}</Typography>

        <Typography className="Caption">{props.item.Caption}</Typography>

        <Button variant="outlined" className="ViewButton">
          View Now
        </Button>
      </CardContent>
    </Grid>
  );

  for (let i = 0; i < mediaLength; i++) {
    const item = props.item.Items[i];
    item.Image = props.randomImageUrls[i + props.bannerIndex * 2];

    console.log('props.ran', props.randomImageUrls);
    console.log('item', item);

    const media = (
      <Grid item xs={4} key={item.Name}>
        <CardMedia className="Media" image={item.Image} title={item.Name}>
          <Typography className="MediaCaption">{item.Name}</Typography>
        </CardMedia>
      </Grid>
    );

    items.push(media);
  }

  if (contentPosition === 'left') {
    items.unshift(content);
  } else if (contentPosition === 'right') {
    items.push(content);
  } else if (contentPosition === 'middle') {
    items.splice(items.length / 2, 0, content);
  }

  return (
    <Card raised className="Banner">
      <Grid container spacing={0} className="BannerGrid">
        {items}
      </Grid>
    </Card>
  );
};

const items: Item[] = [
  {
    Name: 'Coogee Bay',
    Caption: 'On a gorgeous blue sky day!',
    contentPosition: 'left',
    Items: [
      {
        Name: 'North Side View',
        Image: beach1,
      },
      {
        Name: 'Beach View',
        Image: beach2,
      },
    ],
  },
  {
    Name: 'Blue Mountains',
    Caption: 'Perfect for any level hiker!',
    contentPosition: 'middle',
    Items: [
      {
        Name: 'Backland Beach',
        Image: beach3,
      },
      {
        Name: 'Waterlace Canyon',
        Image: beach4,
      },
    ],
  },
  {
    Name: 'Bondi Beach',
    Caption: 'Who would not want to live in Sydney!?',
    contentPosition: 'right',
    Items: [
      {
        Name: 'Ocean Views',
        Image: beach5,
      },
      {
        Name: 'Clear AF Water...',
        Image: beach6,
      },
    ],
  },
  {
    Name: 'Gordons Bay',
    Caption: 'Blue is the colour!',
    contentPosition: 'middle',
    Items: [
      {
        Name: 'Ocean Views',
        Image: beach5,
      },
      {
        Name: 'Clear AF Water...',
        Image: beach6,
      },
    ],
  },
];

export default GalleryCarousel;
