import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider, createTheme, ThemeOptions } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from 'react';
import { useEffect } from 'react';
// 1. import our newly fresh yum exported theme
import { color as ThemeColors } from './index';

// Define the type for colorMode
interface ColorModeContextType {
  toggleColorMode: () => void;
  shuffleColorTheme: () => void;
  toggleShowThemeComp: () => void;
  isDarkMode: () => boolean;
  getMode: () => string;
}

export const ThemeContext = React.createContext<
  ColorModeContextType | undefined
>(undefined);

export const ThemeContextQuery = React.createContext({
  theme: 0,
  showThemeComp: false,
});

type MyThemeProviderProps = {
  children?: React.ReactNode;
};
const MyThemeProvider = ({ children }: MyThemeProviderProps) => {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const prefersDarkMode = false;
  // const prefersDarkMode = true;
  const savedTheme: 'light' | 'dark' =
    (localStorage.getItem('theme') as 'light' | 'dark') || 'light';

  const [mode, setMode] = useState<'light' | 'dark'>(
    (localStorage.getItem('theme') as 'light' | 'dark') || 'light'
  );

  const [theme, setTheme] = React.useState<0 | 1 | 2 | 3>(0);
  const [showThemeComp, setShowThemeComp] = useState(false);

  // Update localStorage when the theme changes
  useEffect(() => {
    localStorage.setItem('theme', mode);
  }, [mode]);

  // Avoid FOUC by wrapping theme loading in useEffect or initial render
  useEffect(() => {
    document.body.classList.add(mode);
  }, [mode]);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        console.log('Toggling Theme');
        setMode((prevMode) => {
          const newTheme = prevMode === 'light' ? 'dark' : 'light';
          localStorage.setItem('theme', newTheme);
          return newTheme;
        });
      },
      shuffleColorTheme: () => {
        setTheme((prevTheme) => ((prevTheme + 1) % 4) as 0 | 1 | 2 | 3);
      },
      toggleShowThemeComp: () => {
        setShowThemeComp((prevToggle) => (prevToggle === false ? true : false));
      },
      isDarkMode: () => {
        return mode === 'dark';
      },
      getMode: () => {
        return mode;
      },
    }),
    [mode]
  );
  // 2. create theme object, pick theme from the mega theme object we exported earlier
  // based on our theme and mode values
  const _theme = React.useMemo(
    () => createTheme(ThemeColors[theme][mode] as ThemeOptions),
    [mode, theme]
  );
  return (
    <ThemeContext.Provider value={colorMode}>
      <ThemeContextQuery.Provider value={{ theme, showThemeComp }}>
        {/* 3. supply it to MUI ThemeProvider */}
        <ThemeProvider theme={_theme}>
          <GlobalStyles styles={{}} />
          <CssBaseline enableColorScheme />
          {children}
        </ThemeProvider>
      </ThemeContextQuery.Provider>
    </ThemeContext.Provider>
  );
};

export default MyThemeProvider;
