import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import CelebrationIcon from '@mui/icons-material/Celebration';
import Typography from '@mui/material/Typography';
import {
  Box,
  Card,
  Divider,
  Grid,
  Link,
  styled,
  useMediaQuery,
} from '@mui/material';
import { CardExperience } from '../../../Card/CardWithChipAndDesc';
import { renderHtml } from '../../../../Utils/HtmlUtils';
import { TypeAnimation } from 'react-type-animation';

type ReleaseInfo = {
  version: string;
  date: string;
  bulletpoints: any[];
};

const releaseInfo: ReleaseInfo[] = [
  {
    version: '1.1.0',
    date: '14.11.24',
    bulletpoints: [
      <Typography>
        Improved design of{' '}
        <Link href="/box-shadows" underline="none">
          CSS box-shadow Generator{' '}
        </Link>{' '}
        App. More intuative editor and layout with multiple card type options.
        Check it out!
      </Typography>,
      <Typography>
        Updated design of{' '}
        <Link href="/games" underline="none">
          Games
        </Link>{' '}
        home page
      </Typography>,
      <Typography>Now saving theme mode when it is changed</Typography>,
    ],
  },
  {
    version: '1.0.0',
    date: '13.11.24',
    bulletpoints: [
      <Typography>
        Updated design of{' '}
        <Link href="/box-shadows" underline="none">
          CSS box-shadow Generator{' '}
        </Link>{' '}
        App
      </Typography>,
      <Typography>
        Updated design of{' '}
        <Link href="/games" underline="none">
          Games
        </Link>{' '}
        home page
      </Typography>,
      <Typography>
        <Link href="/games/number-recognition" underline="none">
          Number Recognition
        </Link>{' '}
        Game
      </Typography>,
      <Typography>
        <Link href="/conversion" underline="none">
          Conversion
        </Link>{' '}
        App (Weight & Distance)
      </Typography>,
      <Typography>Mobile responsiveness improvements</Typography>,
      <Typography>Fixed Nav bar resizing for tablet screens</Typography>,
    ],
  },
  {
    version: '0.0.3',
    date: '04.11.24',
    bulletpoints: [
      <Typography>
        Added DNS for www.everythingstevens.com
        {/* <Link href="www.everythingstevens.com" underline="none">
          www.everythingstevens.com
        </Link>{' '} */}
      </Typography>,
      <Typography>
        Updated design and responsiveness for multiple pages & sections
      </Typography>,
      <Typography>
        Refined 'Experience' section on the{' '}
        <Link href="/home" underline="none">
          Home
        </Link>{' '}
        page
      </Typography>,
      <Typography>
        Added functionality to download my CV from the{' '}
        <Link href="/home" underline="none">
          Home
        </Link>{' '}
        page
      </Typography>,
    ],
  },
  {
    version: '0.0.2',
    date: '30.10.24',
    bulletpoints: [
      <Typography>Release notes page</Typography>,
      <Typography>
        Updated the&nbsp;
        <Link href="/games" underline="none">
          Games and Apps
        </Link>
        &nbsp;homepage
      </Typography>,
      <Typography>
        Improved the Countdown Timer game with a How To Play modal
      </Typography>,
      <Typography>Other minor responsive updates</Typography>,
    ],
  },
  {
    version: '0.0.1',
    date: '30.10.24',
    bulletpoints: [
      <Typography>
        Initial deployment to Firebase and GoDaddy for the everythingstevens.com
        website
      </Typography>,
      <Typography>
        Pages include Main,{' '}
        <Link href="/games" underline="none">
          Home
        </Link>
        ,{' '}
        <Link href="/games" underline="none">
          Calculator
        </Link>
        ,{' '}
        <Link href="/games" underline="none">
          Tasks
        </Link>{' '}
        and{' '}
        <Link href="/games" underline="none">
          Games
        </Link>
      </Typography>,
      <Typography>Calculator App (Beta)</Typography>,
      <Typography>Countdown Timer game (Beta)</Typography>,
      <Typography>
        The ability to change themes, Light to Dark and the Color
      </Typography>,
      <Typography>
        There is also a components view where you can see how the theme effects
        different components
      </Typography>,
      <Typography>
        Mobile responsive view for each page (Alpha version of this,
        improvements will be made over time)
      </Typography>,
    ],
  },
];

export default function ReleaseNotes() {
  const isTabletOrBleow = useMediaQuery('(max-width:768px)');

  const aboveTabletContent = (
    <Timeline position="alternate" sx={{ px: 20, pt: 10 }}>
      {releaseInfo.map((ri) => (
        <ReleaseItem ri={ri} />
      ))}
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0', fontSize: '1.75rem' }}
          variant="body1"
          color="text.secondary"
        >
          30.10.24
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <LaptopMacIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h3" component="span">
            <TypeAnimation
              style={{
                padding: '10px 0px',
                display: 'inline-block',
                margin: '10px 0px',
              }}
              sequence={['Website go live', 400, 'Website go live!', 800]}
              wrapper="span"
              speed={10}
              repeat={Infinity}
              deletionSpeed={99}
              cursor={false}
            />
          </Typography>
          <Typography variant="h5">Because it&apos;s awesome.</Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );

  const belowTabletContent = (
    <Grid container direction="column" spacing={4} sx={{ p: 4 }}>
      {releaseInfo.map((ri) => (
        <Grid item xs={12}>
          {' '}
          <CardExperience
            sx={{
              px: 7,
              py: 3,
              textAlign: 'left',
              boxShadow:
                'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;',
              borderRadius: 4,
            }}
          >
            <Typography variant="h4" component="span">
              Release - <VersionNumberSpan> v{ri.version}</VersionNumberSpan> -{' '}
              {ri.date}
            </Typography>
            <Divider sx={{ p: 1 }} />
            <ul style={{ marginTop: 20 }}>
              {ri.bulletpoints.map((bp: string) => (
                <>
                  <li>{bp}</li>
                  <br />
                </>
              ))}
            </ul>
          </CardExperience>
        </Grid>
      ))}
      <Grid item xs={6}></Grid>
    </Grid>
  );

  return <>{!isTabletOrBleow ? aboveTabletContent : belowTabletContent}</>;
}

const ReleaseItem = ({ ri }) => {
  return (
    <TimelineItem>
      <TimelineOppositeContent
        sx={{ m: 'auto 0', fontSize: '1.75rem' }}
        align="right"
        variant="body1"
        color="text.secondary"
      >
        {ri.date}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot color="primary">
          <CelebrationIcon />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '40px', px: 2 }}>
        <CardExperience
          sx={{
            px: 7,
            py: 3,
            textAlign: 'left',
            boxShadow:
              'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;',
            borderRadius: 4,
          }}
        >
          <Typography variant="h4" component="span">
            Release - <VersionNumberSpan> v{ri.version}</VersionNumberSpan>{' '}
          </Typography>
          <Divider sx={{ p: 1 }} />
          <ul style={{ marginTop: 20 }}>
            {ri.bulletpoints.map((bp: string) => (
              <>
                <li>{bp}</li>
                <br />
              </>
            ))}
          </ul>
        </CardExperience>
      </TimelineContent>
    </TimelineItem>
  );
};

export const VersionNumberSpan = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}));
