interface Props {
  htmlString?: string;
  children?: React.ReactNode;
}

const HTMLRenderer: React.FC<Props> = ({ htmlString, children }) => {
  if (htmlString) {
    // Create a function to sanitize the HTML string if necessary
    const sanitizeHTML = (html: string) => {
      // Implement your sanitization logic here if needed
      return html;
    };

    return (
      <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(htmlString) }} />
    );
  } else {
    return <div>{children}</div>;
  }
};

export default HTMLRenderer;
