// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,100..900;1,100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* index.css or App.css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* outline: 1px solid red; */
}

body {
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Asap', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div,
input,
button {
  /* Ensure font applies globally to all text elements */
  /* font-family: 'Poppins', sans-serif !important;  */
  /* font-family: 'Montserrat', sans-serif !important; */
  font-family: 'Asap', sans-serif !important;
}

/* body { */
/* margin: 0;
  font-family: "Nunito Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; */
/* background-color: #bdd9f6; */
/* } */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAKzB;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,4BAA4B;AAC9B;;AAEA;EACE,wCAAwC;EACxC,2CAA2C;EAC3C,+BAA+B;AACjC;;AAEA;;;;;;;;;;;EAWE,sDAAsD;EACtD,oDAAoD;EACpD,sDAAsD;EACtD,0CAA0C;AAC5C;;AAEA,WAAW;AACX;;;;;;;;;sBASsB;AACtB,+BAA+B;AAC/B,MAAM;;AAEN;;;GAGG","sourcesContent":["/* index.css or App.css */\r\n@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');\r\n@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');\r\n@import url('https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,100..900;1,100..900&display=swap');\r\n\r\n* {\r\n  margin: 0;\r\n  padding: 0;\r\n  box-sizing: border-box;\r\n  /* outline: 1px solid red; */\r\n}\r\n\r\nbody {\r\n  /* font-family: 'Poppins', sans-serif; */\r\n  /* font-family: 'Montserrat', sans-serif; */\r\n  font-family: 'Asap', sans-serif;\r\n}\r\n\r\nh1,\r\nh2,\r\nh3,\r\nh4,\r\nh5,\r\nh6,\r\np,\r\nspan,\r\ndiv,\r\ninput,\r\nbutton {\r\n  /* Ensure font applies globally to all text elements */\r\n  /* font-family: 'Poppins', sans-serif !important;  */\r\n  /* font-family: 'Montserrat', sans-serif !important; */\r\n  font-family: 'Asap', sans-serif !important;\r\n}\r\n\r\n/* body { */\r\n/* margin: 0;\r\n  font-family: \"Nunito Sans\", sans-serif !important;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n  margin: 0;\r\n  padding: 0;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  min-height: 100vh; */\r\n/* background-color: #bdd9f6; */\r\n/* } */\r\n\r\n/* code {\r\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\r\n    monospace;\r\n} */\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
