import { Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Item } from '../../../General/Components';
import ScatterChart from './Charts/ScatterChart';
import BarChart, { otherData } from './Charts/BarChart';
import { BankingDataType } from './Banking';
import { convertTransactionsToBarChartFormat } from './BankingUtils';

type AnalyticsProps = {
  transactions: BankingDataType[];
};

const Analytics = ({ transactions }: AnalyticsProps) => {
  return (
    <Grid container direction="row">
      <Grid xs={0} md={1}>
        <Item></Item>
      </Grid>
      <Grid xs={12} md={5}>
        <Item>
          {/* <ScatterChart /> */}
          <BarChart data={convertTransactionsToBarChartFormat(transactions)} />
        </Item>
      </Grid>
      <Grid xs={12} md={5}>
        <Item></Item>
      </Grid>
      <Grid xs={0} md={1}>
        <Item></Item>
      </Grid>
    </Grid>
  );
};

export default Analytics;
