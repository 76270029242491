import { Alert, Button, Drawer, Grid, Snackbar, Stack } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import {
  addObjectToArrayInLocalStorage,
  getJSONFromLocalStorage,
  removeItemByUUID,
} from '../../../../Utils/LocalStorageUtils';
import { getRandomNumber } from '../../../../Utils/NumberUtils';
import { FACTS } from '../../../../constants';
import { Item } from '../../../General/Components';
import OutlinedCardText from '../../../General/OutlinedCardText';
import RandomFact from './RandomFact';
import { facts } from './randomFacts';
import { Fade } from 'react-awesome-reveal';
// @ts-ignore
// import Fade from "react-awesome-reveal/Fade";

type FactsProps = {
  text?: string;
};

export type Fact = {
  uuid: string;
  text: string;
};

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const getRandomFact = () => {
  return facts[getRandomNumber(0, facts.length - 1)];
};

const Facts = ({ text }: FactsProps) => {
  const [randomFact, setRandomFact] = useState(getRandomFact());
  const [favouriteFacts, setFavouriteFacts] = useState([]);

  const getRandomFactHandler = () => {
    setRandomFact(getRandomFact());
  };

  const favouritiseFactHandler = (fact: Fact, addToLS: boolean) => {
    if (addToLS) {
      addObjectToArrayInLocalStorage(FACTS, fact);
      handleClick();
    } else {
      removeItemByUUID(FACTS, fact.uuid);
    }
    setFavouriteFacts(getJSONFromLocalStorage(FACTS));
  };

  const isFavouriteFact = (fact: Fact) => {
    if (favouriteFacts) {
      return favouriteFacts.some((item: Fact) => item.uuid === fact.uuid);
    }
    return false;
  };

  useEffect(() => {
    setFavouriteFacts(getJSONFromLocalStorage(FACTS));
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  return (
    <Fragment>
      <Stack
        direction="column"
        sx={{ p: 10, width: '100%' }}
        justifyContent="center"
        alignItems="center"
      >
        <RandomFact
          obj={randomFact}
          isFavourite={isFavouriteFact(randomFact)}
          favouritiseFactHandler={favouritiseFactHandler}
          randomiseFact={getRandomFactHandler}
        />
        <Button onClick={toggleDrawer('right', true)} sx={{ width: 100 }}>
          Favourites
        </Button>
      </Stack>
      <Grid container direction="row" style={{ padding: '10px 0px' }}>
        <Grid>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Item></Item>
            </Grid>
            {facts.map((el: Fact, index: number) => {
              return index % 2 === 0 ? (
                <Grid item xs={6} key={index}>
                  <Fade duration={600}>
                    <Item>
                      <OutlinedCardText
                        obj={el}
                        isFavourite={isFavouriteFact(el)}
                        favouritiseFactHandler={favouritiseFactHandler}
                      />
                    </Item>
                  </Fade>
                </Grid>
              ) : (
                <Grid item xs={6} key={index}>
                  <Fade duration={600}>
                    <Item>
                      <OutlinedCardText
                        obj={el}
                        isFavourite={isFavouriteFact(el)}
                        favouritiseFactHandler={favouritiseFactHandler}
                      />
                    </Item>
                  </Fade>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            borderRight: '2px solid #bcbcbc',
            margin: '40px 6px 40px 0px',
          }}
        >
          <Item></Item>
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={1250}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Fact added to favourites!
        </Alert>
      </Snackbar>
      <Drawer
        anchor="right"
        open={state['right']}
        onClose={toggleDrawer('right', false)}
      >
        <Grid container direction="row" sx={{ width: 500 }}>
          {favouriteFacts.map((fav: Fact, index: number) => (
            <Grid item xs={12} key={index}>
              <Item>
                <OutlinedCardText
                  favouritiseFactHandler={favouritiseFactHandler}
                  key={index}
                  obj={fav}
                  isFavourite={isFavouriteFact(fav)}
                />
              </Item>
            </Grid>
          ))}
        </Grid>
      </Drawer>
    </Fragment>
  );
};

export default Facts;

// <Stack direction="row">
//   <div style={{ height: 200, width: "50%" }}>
//     <OutlinedCardText
//       favouritiseFactHandler={favouritiseFactHandler}
//       key={index}
//       obj={fav}
//       isFavourite={isFavouriteFact(fav)}
//     />
//   </div>
// </Stack>
