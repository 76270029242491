import { Box, Typography, styled } from '@mui/material';
import HTMLRenderer from './HTMLRenderer';

type TitleDescriptionProps = {
  title?: string;
  description?: string;
};

export const HeadingSmallBorderLeft = styled(Typography)(({ theme }) => ({
  fontFamily: 'Nunito Sans',
  borderLeft: '2px solid rgb(66, 165, 245)',
  paddingLeft: 8,
  margin: '0px 0px 10px 8px',
  fontWeight: '700',
  fontSize: '0.95rem',
}));

const TitleDescription = ({ title, description }: TitleDescriptionProps) => {
  return (
    <Box sx={{ padding: '10px 0px' }}>
      <HeadingSmallBorderLeft variant="h6">{title}</HeadingSmallBorderLeft>
      <Typography variant="body1" style={{ paddingLeft: 18 }}>
        <HTMLRenderer htmlString={description} />
      </Typography>
    </Box>
  );
};

export default TitleDescription;
