import { Grid, Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import CountUp from 'react-countup';
import { BankingDataType } from './Banking';
import { Fragment } from 'react';
import { centerStyle } from '../../../../constants';

type BankingTypeCardProps = {
  obj: BankingDataType;
};

export const BankingDataCard = styled('div')(({ theme }) => ({
  fontFamily: 'Nunito Sans',
  padding: theme.spacing(1),
  textAlign: 'center',
  fontWeight: 100,
  borderBottom: `1px solid  ${theme.palette.primary.light}`,
  // marginBottom: 20,
  backgroundColor: `${theme.palette.containerSecondary?.main}`,
}));

export const AmountSpan = styled('span')(({ theme }) => ({
  padding: 10,
}));

const BankingTypeCard = ({ obj }: BankingTypeCardProps) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const amountContent = (
    <Grid xs={5} md={3} sx={{ textAlign: 'right' }}>
      {obj.type === 'expense' && (
        <AmountSpan>
          -
          <CountUp
            start={0}
            end={obj.amount}
            decimals={2}
            prefix="$"
            duration={1}
          />
        </AmountSpan>
      )}
      {obj.type === 'deposit' && (
        <AmountSpan style={{ color: `${theme.palette.success.main}` }}>
          +
          <CountUp
            start={0}
            end={obj.amount}
            decimals={2}
            prefix="$"
            duration={1}
          />
        </AmountSpan>
      )}
    </Grid>
  );

  return (
    <Fragment>
      {isTablet ? (
        <BankingDataCard>
          <Stack direction="row" spacing={2}>
            <Grid container direction="row">
              <Grid xs={7} md={9} style={centerStyle}>
                <Grid
                  container
                  direction="column"
                  spacing={4}
                  justifyContent={'left'}
                >
                  <Grid item xs={4} sx={{ textAlign: 'left' }}>
                    <span>
                      {obj.category} - {obj.description}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              {amountContent}
            </Grid>
          </Stack>
        </BankingDataCard>
      ) : (
        <BankingDataCard>
          <Stack direction="row" spacing={2}>
            <Grid container direction="row">
              <Grid xs={7} md={9} style={centerStyle}>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  justifyContent={'left'}
                >
                  <Grid item xs={3} sx={{ textAlign: 'left' }}>
                    <span>{obj.category}</span>
                  </Grid>
                  <Grid item xs={9} sx={{ textAlign: 'left' }}>
                    <span>{obj.description}</span>
                  </Grid>
                </Grid>
              </Grid>
              {amountContent}
            </Grid>
          </Stack>
        </BankingDataCard>
      )}
    </Fragment>
  );
};

export default BankingTypeCard;
