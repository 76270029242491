export const formatNumberWithCommas = (number) => {
  // Use toLocaleString() with 'en-US' locale to format the number with commas
  return number.toLocaleString('en-US');
};

export const convertToEPower = (number) => {
  // Convert the number to a string
  const numberString = number.toString();

  // Find the position of the decimal point
  const decimalIndex = numberString.indexOf('.');

  // If there's no decimal point, use the length of the string as the exponent
  const exponent =
    decimalIndex === -1 ? numberString.length - 1 : decimalIndex - 1;

  // Format the result as x.xxxxxxxe+yy
  const result = `${numberString[0]}.${numberString.substring(
    1,
    7
  )}e+${exponent}`;

  return result;
};

export const getRandomNumber = (min, max) => {
  // Generate a random number between min and max (inclusive)
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getWeightedRandomNumber = (min, max, power) => {
  // Generate a random number between 0 and 1, then apply a power to skew towards 0
  const bias = Math.pow(Math.random(), power); // Adjust power for more or less bias
  return Math.floor(min + bias * (max - min));
};

export const randomFloatBetween = (min, max) => {
  // Generate a random float between 0 and 1
  const random = Math.random();

  // Calculate a random float between min and max with two decimal places
  const result = min + random * (max - min);

  // Round the result to two decimal places
  return parseFloat(result.toFixed(2));
};

export const evaluateEquation = (equation) => {
  let result = 0;
  try {
    result = eval(equation);
    console.log(`Result: ${result}`);
  } catch (error) {
    console.error(`Error: ${error.message}`);
  }
  return result;
};

export const getRandomNumbers = (min, max, count) => {
  const randomNumbers = [];
  for (let i = 0; i < count; i++) {
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    randomNumbers.push(randomNumber);
  }
  return randomNumbers;
};

export const getRandomNumbersNoDuplicates = (min, max, count) => {
  const randomNumbers = new Set();

  while (randomNumbers.size < count) {
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    randomNumbers.add(randomNumber);
  }

  return Array.from(randomNumbers);
};

export const hasDuplicateNumbers = (arr) => {
  // console.log("arr: ", arr);
  const seenNumbers = new Set();
  for (const item of arr) {
    if (seenNumbers.has(item.num)) {
      // console.log("returning true");
      return true; // Duplicate found
    }
    seenNumbers.add(item.num);
  }
  // console.log("returning false");
  return false; // No duplicates
};

export const getRandomNumberToNearestThousand = (min, max) => {
  // Generate a random number between min and max
  let randomNumber = Math.random() * (max - min) + min;

  // Round the random number to the nearest thousand
  let roundedNumber = Math.round(randomNumber / 1000) * 1000;

  return roundedNumber;
};

export const formatNumberExpo = (number, threshold = 1000000000) => {
  // If the number is greater than the threshold, convert it to exponential notation
  return number > threshold ? number.toExponential(8) : number.toLocaleString();
};

export const isPrime = (n) => {
  if (n <= 1) return false;
  for (let i = 2; i <= Math.sqrt(n); i++) {
    if (n % i === 0) return false;
  }
  return true;
};

export const isPalindrome = (n) => {
  const str = n.toString();
  return str === str.split('').reverse().join('');
};

export const sumDigits = (n) => {
  return n
    .toString()
    .split('')
    .reduce((acc, digit) => acc + parseInt(digit), 0);
};

export const isFibonacci = (n) => {
  const isPerfectSquare = (x) => Math.sqrt(x) % 1 === 0;
  return isPerfectSquare(5 * n * n + 4) || isPerfectSquare(5 * n * n - 4);
};

export const generateRandomRange = (min, max, count) => {
  if (count < 1 || max < min) return [];

  const result = new Set();

  // Keep adding random numbers until we have the desired count
  while (result.size < count) {
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    result.add(randomNum); // Use Set to avoid duplicates
  }

  return Array.from(result); // Convert Set to Array before returning
};
