// constants.js

// style

export const centerStyle = {
  display: 'flex',
  justifyContent: 'start',
  alignContent: 'center',
  alignItems: 'center',
};

// Statistics
export const EVERYTHING_STEVENS_PREFIX = 'everything_stevens_';
export const STATISTIC = 'statistic_';

// API endpoints
export const API_BASE_URL = 'https://api.example.com';
export const API_USERS_ENDPOINT = '/users';
export const API_PRODUCTS_ENDPOINT = '/products';

// Configuration settings
export const MAX_LOGIN_ATTEMPTS = 5;
export const DEFAULT_PAGE_SIZE = 10;

// Colors
export const PRIMARY_COLOR = '#007bff';
export const SECONDARY_COLOR = '#6c757d';

// Text
export const CONVERSIONS = 'conversions';
export const SPACE = ' ';

export const WEIGHT = 'weight';
export const WEIGHT_KG = 'kg';
export const WEIGHT_KG_NAME = 'kilogram';
export const WEIGHT_G = 'g';
export const WEIGHT_G_NAME = 'gram';
export const WEIGHT_LB = 'lb';
export const WEIGHT_LB_NAME = 'pound';
export const WEIGHT_OZ = 'oz';
export const WEIGHT_OZ_NAME = 'ounce';

export const WEIGHT_ARRAY = ['kg', 'g', 'lb', 'oz'];

export const DISTANCE = 'distance';
export const DISTANCE_M = 'm';
export const DISTANCE_M_NAME = 'meter';
export const DISTANCE_KM = 'km';
export const DISTANCE_KM_NAME = 'km';
export const DISTANCE_FT = 'ft';
export const DISTANCE_FT_NAME = 'foot';
export const DISTANCE_MI = 'mi';
export const DISTANCE_MI_NAME = 'mile';

export const DISTANCE_ARRAY = ['m', 'km', 'ft', 'mi'];

// Calculator

// Define constants for mathematical symbols
export const PLUS = '+';
export const MINUS = '-';
// export const TIMES = "×"; // Note: This is the "multiplication sign," not the letter x.
export const TIMES = '*'; // Note: This is the "multiplication sign," not the letter x.
// export const DIVIDE = "÷"; // Note: This is the "division sign," not the letter /.
export const DIVIDE = '/'; // Note: This is the "division sign," not the letter /.
export const EQUALS = '=';

export const CALCULATOR_BUTTONS = [
  // { label: '%', value: '' },
  // { label: 'CE', value: '' },
  // { label: 'C', value: '' },
  // { label: 'Del', value: '' },
  // { label: "<sup>1</sup>&frasl;<sub>x</sub/>", value: "" },
  // { label: "x<sup>2</sup>", value: "" },
  // { label: "<sup>2</sup>&radic;<sub>x</sub>", value: "" },
  { label: '', value: '' },
  // { label: '?', value: '' },
  // { label: '?', value: '' },
  { label: 'C', value: '' },
  { label: 'Del', value: '' },
  { label: DIVIDE, value: '' },
  { label: '7', value: '' },
  { label: '8', value: '' },
  { label: '9', value: '' },
  { label: TIMES, value: '' },
  { label: '4', value: '' },
  { label: '5', value: '' },
  { label: '6', value: '' },
  { label: MINUS, value: '' },
  { label: '1', value: '' },
  { label: '2', value: '' },
  { label: '3', value: '' },
  { label: PLUS, value: '' },
  { label: '+/-', value: '' },
  { label: '0', value: '' },
  { label: '.', value: '' },
  { label: EQUALS, value: '' },
];

export const CALCULATOR_NUMBERS_ONLY_BUTTONS = [
  // { label: '%', value: '' },
  // { label: 'CE', value: '' },
  // { label: 'C', value: '' },
  // { label: 'Del', value: '' },
  // { label: "<sup>1</sup>&frasl;<sub>x</sub/>", value: "" },
  // { label: "x<sup>2</sup>", value: "" },
  // { label: "<sup>2</sup>&radic;<sub>x</sub>", value: "" },
  // { label: '', value: '' },
  // { label: '?', value: '' },
  // { label: '?', value: '' },
  // { label: 'C', value: '' },
  // { label: 'Del', value: '' },
  // { label: DIVIDE, value: '' },
  { label: '7', value: '' },
  { label: '8', value: '' },
  { label: '9', value: '' },
  // { label: TIMES, value: '' },
  { label: '4', value: '' },
  { label: '5', value: '' },
  { label: '6', value: '' },
  // { label: MINUS, value: '' },
  { label: '1', value: '' },
  { label: '2', value: '' },
  { label: '3', value: '' },
  // { label: PLUS, value: '' },
  { label: '', value: '' },
  { label: '0', value: '' },
  { label: '.', value: '' },
  // { label: EQUALS, value: '' },
];

// End Calculator

// Facts

export const FACTS = 'facts';

// End Facts

// Gallery

export const BEACH = 'beach';
export const FOOD = 'food';

// End Gallery

// Tasks

export const IN_PROGRESS_STATUS = 'In Progress';
export const COMPLETED_STATUS = 'Completed';
export const PENDING_STATUS = 'Pending';
export const ON_HOLD_STATUS = 'On Hold';
export const BLOCKED_STATUS = 'Blocked';
export const WONT_DO_STATUS = 'Wont Do';

// V1
// export const IN_PROGRESS_STATUS_COLOUR = "#277DA1";
// export const COMPLETED_STATUS_COLOUR = "#43AA8B";
// export const PENDING_STATUS_COLOUR = "#F9844A";
// export const ON_HOLD_STATUS_COLOUR = "#F8C838";
// export const BLOCKED_STATUS_COLOUR = "#F94144";
// export const WONT_DO_STATUS_COLOUR = "#577590";

// V2
export const IN_PROGRESS_STATUS_COLOUR = '#BAF2E9';
export const COMPLETED_STATUS_COLOUR = '#B0F2B4';
export const PENDING_STATUS_COLOUR = '#BAD7F2';
export const ON_HOLD_STATUS_COLOUR = '#F2E2BA';
export const BLOCKED_STATUS_COLOUR = '#F2BAC9';
export const WONT_DO_STATUS_COLOUR = '#EFEFEF';

// End Tasks

// Banking

export const DEPOSIT = 'deposit';
export const EXPENSE = 'expense';

export const categories = [
  'Groceries',
  'Subscriptions',
  'Misc',
  'Eating Out',
  'Takeaways',
  'Vapes',
  'Travel',
];

// End Banking

// Number Recognition Game

export const NUMBER_RECOGNITION_GAME_PREFIX = 'number_recognition_game_';

// TODO, need to replace the 'n' in the equation and not just have it as SOMEHTING then the 'eq' below, have ALL the formulas as 'N blah'

export const NUMBER_QUESTIONS = [
  { label: 'Is divisible by 7', eq: '% 7 === 0' },
  { label: 'Is a multiple of 3', eq: '% 3 === 0' },
  { label: 'Is less than 10', eq: '< 10' },
  { label: 'Even Number', eq: '% 2 === 0' },
  { label: 'Odd Number', eq: '% 2 != 0' },
  { label: 'Is not even', eq: '% 2 != 0' },
  { label: 'Is not odd', eq: '% 2 === 0' },
  { label: 'Is > 29', eq: '> 29' },
  { label: 'Modulus 2 has remainder', eq: '% 2 > 0' },
  { label: 'Modulus 7 has remainder > 3', eq: '% 7 > 3' },
  // '10 < X < 30',
  // 'The digits together equal an even number',
  // 'The digits together equal an odd number',
  // 'Is not even',
  // 'Is not odd',
  // 'Is not not even',
  // 'Is not not odd',
  // 'Modulus 2 has a remainder',
  // 'Contains the number 8',
  // 'Has a 0 in it',
  // { label: 'Is a prime number', eq: 'isPrime(n)' }, // You’ll need a helper function to check for prime numbers
  { label: 'Greater than 40', eq: '> 40' },
  { label: 'A multiple of 5', eq: '% 5 === 0' },
  // { label: 'Square number', eq: 'Math.sqrt(n) % 1 === 0' },
  { label: 'Ends in 0', eq: '% 10 === 0' },
  // { label: 'Is a palindrome', eq: 'isPalindrome(n)' }, // Helper function needed to check if the number reads the same forwards and backwards
  // { label: 'Has exactly 2 digits', eq: 'n >= 10 && n < 100' }, // broken atm
  // { label: 'Sum of digits is even', eq: 'sumDigits(n) % 2 === 0' }, // Helper function needed to sum digits
  // { label: 'Is a power of 2', eq: '(n & (n - 1)) === 0 && n > 0' }, // broken atm
  // { label: 'Is greater than 100', eq: '> 100' },
  { label: 'Is negative', eq: '< 0' },
  { label: 'Divisible by 9', eq: '% 9 === 0' },
  { label: 'Less than or equal to 20', eq: '<= 20' },
  // { label: 'Is a cube number', eq: 'Math.cbrt(n) % 1 === 0' },
  // {
  //   label: 'Odd number between 10 and 30',
  //   eq: '% 2 > 0 && n > 10 && n < 30',
  // },
  // broken atm
  // { label: 'Is a Fibonacci number', eq: 'isFibonacci(n)' }, // You’ll need a helper function to check for Fibonacci numbers
  { label: 'Greater than or equal to 0', eq: '>= 0' },
  // { label: 'Positive even number', eq: 'n > 0 && n % 2 === 0' }, // broken atm
  { label: 'Last digit is 5', eq: '% 10 === 5' },
];

// Number Recognition Game

// Box Shadow Generator

export const ANIMAL_NAMES = [
  'Lion',
  'Elephant',
  'Tiger',
  'Giraffe',
  'Penguin',
  'Kangaroo',
  'Dolphin',
  'Panda',
  'Zebra',
  'Koala',
  'Rhinoceros',
  'Hippopotamus',
  'Leopard',
  'Cheetah',
  'Flamingo',
  'Ostrich',
  'Gorilla',
  'Eagle',
  'Wolf',
  'Bear',
];

export const TITLES = [
  'Employees',
  'Clients',
  'Projects',
  'Tickets',
  'Events',
  'Reports',
  'Offices',
  'Licenses',
  'Accounts',
];

export const BOX_COLORS = [
  '#fbf8cc',
  '#fde4cf',
  '#ffcfd2',
  '#f1c0e8',
  '#cfbaf0',
  '#a3c4f3',
  '#90dbf4',
  '#8eecf5',
  '#98f5e1',
  '#b9fbc0',
];

export const DEFINITIONS = [
  {
    word: 'benevolent',
    partOfSpeech: 'adjective',
    definition: 'well meaning and kindly.',
    example: 'She gave him a benevolent smile.',
  },
  {
    word: 'ephemeral',
    partOfSpeech: 'adjective',
    definition: 'lasting for a very short time.',
    example: 'The beauty of the sunset was ephemeral.',
  },
  {
    word: 'elucidate',
    partOfSpeech: 'verb',
    definition: 'make (something) clear; explain.',
    example: 'The teacher tried to elucidate the complex theory.',
  },
  {
    word: 'resilient',
    partOfSpeech: 'adjective',
    definition:
      'able to withstand or recover quickly from difficult conditions.',
    example:
      'Children are often more resilient than adults in times of hardship.',
  },
  {
    word: 'pernicious',
    partOfSpeech: 'adjective',
    definition:
      'having a harmful effect, especially in a gradual or subtle way.',
    example: 'The pernicious influence of social media on self-esteem.',
  },
  {
    word: 'serendipity',
    partOfSpeech: 'noun',
    definition:
      'the occurrence of events by chance in a happy or beneficial way.',
    example: 'They met by pure serendipity at a concert.',
  },
  {
    word: 'ubiquitous',
    partOfSpeech: 'adjective',
    definition: 'present, appearing, or found everywhere.',
    example: 'Smartphones have become ubiquitous in modern society.',
  },
  {
    word: 'conundrum',
    partOfSpeech: 'noun',
    definition: 'a confusing and difficult problem or question.',
    example:
      'The ethical conundrum of the new technology puzzled the committee.',
  },
  {
    word: 'ameliorate',
    partOfSpeech: 'verb',
    definition: 'make (something bad or unsatisfactory) better.',
    example: 'The reforms were designed to ameliorate living conditions.',
  },
  {
    word: 'cogent',
    partOfSpeech: 'adjective',
    definition: 'clear, logical, and convincing.',
    example: 'She presented a cogent argument that won over the audience.',
  },
  {
    word: 'zealous',
    partOfSpeech: 'adjective',
    definition:
      'having or showing great enthusiasm in pursuit of a cause or objective.',
    example: 'He was a zealous supporter of environmental protection.',
  },
  {
    word: 'obfuscate',
    partOfSpeech: 'verb',
    definition: 'render obscure, unclear, or unintelligible.',
    example: 'The politician tried to obfuscate the truth with complex jargon.',
  },
  {
    word: 'quintessential',
    partOfSpeech: 'adjective',
    definition:
      'representing the most perfect or typical example of a quality or class.',
    example: 'She was the quintessential artist, always lost in her work.',
  },
  {
    word: 'ameliorate',
    partOfSpeech: 'verb',
    definition: 'to make something better or more tolerable.',
    example: 'They worked to ameliorate the effects of pollution.',
  },
  {
    word: 'catharsis',
    partOfSpeech: 'noun',
    definition:
      'the process of releasing, and thereby providing relief from, strong or repressed emotions.',
    example: 'Writing in her journal provided a catharsis for her worries.',
  },
  {
    word: 'fortuitous',
    partOfSpeech: 'adjective',
    definition: 'happening by a lucky chance; fortunate.',
    example: 'It was a fortuitous encounter that led to their friendship.',
  },
  {
    word: 'gregarious',
    partOfSpeech: 'adjective',
    definition: 'sociable; enjoying the company of others.',
    example: 'She was gregarious and loved hosting gatherings.',
  },
  {
    word: 'ineffable',
    partOfSpeech: 'adjective',
    definition: 'too great or extreme to be expressed or described in words.',
    example: 'The beauty of the mountains was ineffable.',
  },
  {
    word: 'quixotic',
    partOfSpeech: 'adjective',
    definition: 'exceedingly idealistic; unrealistic and impractical.',
    example:
      'His quixotic plans to save the world were admirable but impractical.',
  },
  {
    word: 'magnanimous',
    partOfSpeech: 'adjective',
    definition:
      'generous or forgiving, especially toward a rival or less powerful person.',
    example:
      'He was magnanimous in victory, offering a handshake to his opponent.',
  },
];

// End Box Shadow Generator
