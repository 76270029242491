// Helper function to import all images
const importAllImages = (requireContext: any): Record<string, string> => {
  const images: Record<string, string> = {};
  requireContext.keys().forEach((item: any) => {
    images[item.replace('./', '')] = requireContext(item).default;
  });
  return images;
};

// Import images
const images = importAllImages(
  require.context('../resources/images', false, /\.(png|jpe?g|svg)$/)
);

export default images;
