import React, { useState, useEffect } from 'react';
import {
  clearObjectInLocalStorage,
  getJSONFromLocalStorage,
} from '../../../../Utils/LocalStorageUtils';
import { Button, Grid, styled } from '@mui/material';
import { Item } from '../../../General/Components';
import { capitalize } from '../../../../Utils/StringUtils';
import { CONVERSIONS } from '../../../../constants';

type ConversionHistoryProps = {
  value: any;
};

export const ConversionHistoryEl = styled('div')(({ theme }) => ({
  //   ...theme.typography.h1,
  width: '100%',
  fontFamily: 'Nunito Sans',
  // padding: theme.spacing(1),
  margin: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.primary,
  fontWeight: 100,
  //   background: theme.palette.background.paper,
  borderBottom: '1px solid #ccc',
}));

const ConversionHistory = ({ value }: ConversionHistoryProps) => {
  return (
    <div>
      <Grid container direction="row">
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={2}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => clearObjectInLocalStorage(CONVERSIONS)}
          >
            Clear History
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="row" sx={(theme) => ({ textAlign: 'center' })}>
        <Grid item xs={3}>
          Date
        </Grid>
        <Grid item xs={3}>
          Type
        </Grid>
        <Grid item xs={3}>
          From Value
        </Grid>
        <Grid item xs={3}>
          Equals
        </Grid>
      </Grid>
      {value &&
        value.length > 0 &&
        value.map((conversion: any, index: number) => (
          <ConversionHistoryEl key={index}>
            <Grid container direction="row">
              <Grid item xs={3}>
                {conversion.date}
              </Grid>
              <Grid item xs={3}>
                {capitalize(conversion.type)}
              </Grid>
              <Grid item xs={3}>
                {conversion.sourceValue}
                <b> {conversion.from}</b>
              </Grid>
              <Grid item xs={3}>
                {conversion.targetValue.toFixed(4)}
                <b> {conversion.to}</b>
              </Grid>
            </Grid>
          </ConversionHistoryEl>
        ))}
      {(!value || value.length <= 0) && (
        <ConversionHistoryEl>
          <Grid container direction="row">
            <Grid item xs={12}>
              No previous conversions
            </Grid>
          </Grid>
        </ConversionHistoryEl>
      )}
    </div>
  );
};

export default ConversionHistory;
