import React, { useContext } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {
  atomOneLight,
  atomOneDark,
} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { ThemeContext } from '../../../../themes/MyThemeProvider';
import { Box } from '@mui/material';

interface CodeHighlighterProps {
  cardStyleString: string;
}

const CodeHighlighter: React.FC<CodeHighlighterProps> = ({
  cardStyleString,
}) => {
  const colorMode = useContext(ThemeContext);

  let codeTheme = atomOneLight;
  if (colorMode?.isDarkMode()) {
    codeTheme = atomOneDark;
  }

  return (
    <Box sx={(theme) => ({ width: '100%' })}>
      <SyntaxHighlighter language="css" style={codeTheme}>
        {cardStyleString}
      </SyntaxHighlighter>
    </Box>
  );
};

export default CodeHighlighter;
