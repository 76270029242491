import React, { useState, useEffect } from 'react';
import { Item } from '../../../General/Components';
import { Button, Chip, Grid, Stack, Typography, styled } from '@mui/material';
// import Task from "./Task";
import CircleIcon from '@mui/icons-material/Circle';
// import Task from "./Task";
import { Anchor, StatusListType, TaskType } from './TaskList';
import { capitalize, generateLoremIpsum } from '../../../../Utils/StringUtils';
import { getRandomNumber } from '../../../../Utils/NumberUtils';
import Task from './Task';
import { darkenHexColor, lightenHexColor } from '../../../../Utils/ColourUtils';

type StatusListProps = {
  index: number;
  obj: StatusListType;
  // addItem: (statusIndex: number, newItemTitle: string) => void;
  // removeItem: (statusIndex: number, itemIndex: number) => void;
  addItem: (status: string) => void;
  removeItem: (title: string) => void;
  changeTaskStatus: (title: string, status: string) => void;
  toggleDrawer: (anchor: Anchor, open: boolean) => any;
};
export const TasksLengthTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: '#bbbbbb',
  fontWeight: 'bold',
}));

const StatusList = ({
  index,
  obj,
  addItem,
  removeItem,
  changeTaskStatus,
  toggleDrawer,
}: StatusListProps) => {
  const statusIndex = index;

  // console.log('obj', obj);

  return (
    <Stack
      direction="column"
      spacing={1}
      // width={1000}
      sx={(theme) => ({
        padding: 1,
        minWidth: '300px',
        backgroundColor: `${lightenHexColor(obj.statusColour, 1)}`,
        // backgroundColor: theme.palette.containerPrimary?.main,
      })}
    >
      <Grid
        container
        direction="row"
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignContent: 'center',
          alignItems: 'center',
          // width: '200px',
        }}
      >
        <Grid>
          <Stack
            direction="row"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <Chip
              variant="filled"
              label={`${obj.status} - ${obj.items.length}`}
              size="small"
              sx={{
                backgroundColor: `${darkenHexColor(obj.statusColour, 18)}`,
                fontWeight: 'bold',
                color: '#fff',
              }}
              icon={<CircleIcon style={{ fontSize: '12px', color: '#fff' }} />}
            />
          </Stack>
        </Grid>
        {/* <Grid>
          <Item>
            <TasksLengthTypography variant="body2" sx={{ fontWeight: 'bold' }}>
              {obj.items.length}
            </TasksLengthTypography>
          </Item>
        </Grid> */}
        <Grid>
          <Item></Item>
        </Grid>
      </Grid>
      {obj.items.length > 0 &&
        obj.items.map((item, index) => (
          <Task
            statusListIndex={statusIndex}
            taskIndex={index}
            item={item}
            removeItem={removeItem}
            changeTaskStatus={changeTaskStatus}
            toggleDrawer={toggleDrawer}
            taskColour={obj.statusColour}
          />
        ))}
      <Button
        variant="text"
        sx={{ textAlign: 'left', textTransform: 'capitalize' }}
        onClick={() => addItem(obj.status)}
      >
        + New
      </Button>
    </Stack>
  );
};

export default StatusList;
