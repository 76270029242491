import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Grid, useTheme } from '@mui/material';
import { TypeAnimation } from 'react-type-animation';
// import example1 from '../../../../../resources/images/GamesAndApps/example-1.png';
// import example2 from '../../../../../resources/images/GamesAndApps/example-2.png';
// import example3 from '../../../../../resources/images/GamesAndApps/example-3.png';
import { getFirebaseImage } from '../../../../../Utils/FirebaseUtils';

interface GameModalProps {
  isOpen: boolean;
  setIsHowToPlayModalOpen: (value: boolean) => void;
  openHowToPlayModalHandler: () => void;
  children: React.ReactNode;
  // highscore?: number
}

const style = (theme: any) => ({
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: 24,
  maxWidth: '600px',
  width: '100%',
  p: 4,
  pt: 6,
  pb: 4,
});

const GameModal: FC<GameModalProps> = ({
  isOpen,
  setIsHowToPlayModalOpen,
  openHowToPlayModalHandler,
  children,
  // highscore,
}) => {
  const theme = useTheme();

  const [open, setOpen] = useState(isOpen);
  const handleOpen = () => setOpen(true);

  // const [exampleImg1, setExampleImg1] = useState<string>('');
  // const [exampleImg2, setExampleImg2] = useState<string>('');
  // const [exampleImg3, setExampleImg3] = useState<string>('');

  // useEffect(() => {
  //   getFirebaseImage('/games/countdown-timer/example-1.png', setExampleImg1);
  //   getFirebaseImage('/games/countdown-timer/example-2.png', setExampleImg2);
  //   getFirebaseImage('/games/countdown-timer/example-3.png', setExampleImg3);
  // }, []);

  // const titleText = userHasWon ? "Congratulations!" : "Unlucky!";
  // const messages = userHasWon ? winningMessages : losingMessages;

  const handleClose = () => {
    // Only close if not a backdrop click
    // if (reason !== 'backdropClick') {
    setOpen(false);
    openHowToPlayModalHandler();
    // }
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        sx={{
          '&:focus-visible': {
            outline: 'none',
          },
          '& *:focus': {
            outline: 'none',
          },
        }}
      >
        <Fade in={open}>
          <Box sx={{ ...style(theme), width: { xs: '95%', sm: '600px' } }}>
            <Grid
              container
              direction="column"
              spacing={0}
              sx={{
                height: '100%',
                justifyContent: 'space-between',
              }}
            >
              {children}
              <Grid item sx={{ mt: 2, textAlign: 'center' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ borderRadius: 10 }}
                  size="large"
                  onClick={() => {
                    setOpen(false);
                    setIsHowToPlayModalOpen(false);
                  }}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

const StatisticsItem = ({ value, text }) => {
  return (
    <Grid item xs={3}>
      <Typography variant="h4" fontWeight={600} textAlign={'center'}>
        {value}
      </Typography>
      <Typography
        variant="body1"
        sx={{ fontSize: '0.95rem', textAlign: 'center' }}
      >
        {text}
      </Typography>
    </Grid>
  );
};

export default GameModal;
