import { BarChart } from '@mui/icons-material';
import { Grid, Stack, Box, useTheme } from '@mui/material';
import React, { useState, useEffect, Fragment } from 'react';
import { sortByDate } from '../../../../Utils/DateUtils';
import { theme } from '../../../../themes/green';
import { Item } from '../../../General/Components';
import { BankingDataType } from './Banking';
import BankingTypeCard from './BankingTypeCard';
import { groupTransactionsByDate } from './BankingUtils';
import { otherData } from './Charts/BarChart';
import ScatterChart from './Charts/ScatterChart';

type TransactionsProps = {
  transactions: BankingDataType[];
};

const Transactions = ({ transactions }: TransactionsProps) => {
  const theme = useTheme();

  return (
    <Grid container direction="row" sx={{ px: 1 }}>
      <Grid xs={0} sm={2} md={2} lg={3}>
        <Item></Item>
      </Grid>
      <Grid xs={12} sm={8} md={8} lg={6}>
        <Item>
          <Stack direction="column">
            {groupTransactionsByDate(sortByDate(transactions)).map(
              (bd: any) => (
                <Fragment>
                  <Box
                    sx={{
                      textAlign: 'left',
                      borderBottom: `2px solid ${theme.palette.primary.main}`,
                      mt: 3,
                      pb: 1,
                    }}
                  >
                    <span style={{ fontSize: '1.1rem' }}>{bd.date}</span>
                  </Box>
                  {bd.transactions.map((transaction: BankingDataType) => (
                    <BankingTypeCard obj={transaction} />
                  ))}
                </Fragment>
              )
            )}
          </Stack>
        </Item>
      </Grid>
      <Grid xs={0} sm={2} md={2} lg={3}>
        <Item></Item>
      </Grid>
    </Grid>
  );
};

export default Transactions;
