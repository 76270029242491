import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
  addItemToLocalStorage,
  getItemFromLocalStorage,
} from '../../../../../Utils/LocalStorageUtils';
import {
  evaluateEquation,
  generateRandomRange,
  getRandomNumber,
  getRandomNumbers,
} from '../../../../../Utils/NumberUtils';
import {
  EVERYTHING_STEVENS_PREFIX,
  NUMBER_QUESTIONS,
  NUMBER_RECOGNITION_GAME_PREFIX,
  STATISTIC,
} from '../../../../../constants';
import { BorderLinearProgress } from '../CountdownTimer/CountdownTimer';
import HowToPlayModal from '../CountdownTimer/HowToPlayModal';
import GameModal from '../GameModal';
import OffScreenDynamicGrid from './QuestionsContainer';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { ThemeModeSwitch } from '../../../../General/Components';
import { useNavigate } from 'react-router-dom';
import QuestionsContainer from './QuestionsContainer';

interface NumberRecognitionGameProps {}

// const startTimer = getRandomNumber(20, 30);
const startTimer = 60;

const game_highscore_key =
  EVERYTHING_STEVENS_PREFIX +
  NUMBER_RECOGNITION_GAME_PREFIX +
  STATISTIC +
  'highscore';

const INITIAL_QUESTIONS = generateRandomRange(
  0,
  NUMBER_QUESTIONS.length - 1,
  8
);

// Functional Component using props and state
const NumberRecognitionGame: React.FC<NumberRecognitionGameProps> = ({}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const [count, setCount] = useState(100);
  const [userAttempts, setUserAttempts] = useState(0);
  const [randomNumbers, setRandomNumbers] = useState(
    getRandomNumbers(-50, 50, 60)
  );
  const [questionIndex, setQuestionIndex] = useState(0);
  const [numberQuestion, setNumberQuestion] = useState({});

  const [progress, setProgress] = useState(100);
  const [animationId, setAnimationId] = useState<number | null>(null);
  const animationIdRef = useRef<number | null>(null);
  const [seconds, setSeconds] = useState<number>(startTimer);
  const [countdownIntervalId, setCountdownIntervalId] = useState<number | null>(
    null
  );

  const [highscore, setHighscore] = useState(
    parseInt(getItemFromLocalStorage(game_highscore_key) ?? '') || 0
  );

  const [isGameOver, setIsGameOver] = useState(false);

  const [flash, setFlash] = useState(false);
  const [greenFlash, setGreenFlash] = useState(true);
  const [flashingIndex, setFlashingIndex] = useState(null);

  // Questions stuff
  const items = Array.from({ length: 100 }, (_, i) => i + 1);
  const [questionItems, setQuestionItems] = useState(INITIAL_QUESTIONS);

  const addQuestionToArrayHandler = () => {
    const nextQuestionIndex = getRandomNumber(0, NUMBER_QUESTIONS.length - 1);

    let tempArr = questionItems;
    tempArr.push(nextQuestionIndex);
    setQuestionItems([...tempArr]);
  };

  //How to Play
  const [isHowToPlayModalOpen, setIsHowToPlayModalOpen] = useState(false);
  const openHowToPlayModalHandler = () => {
    setIsHowToPlayModalOpen((prev) => !prev);
  };

  const handleFlashClick = (value: boolean) => {
    setFlash(true);
    if (value) {
      setGreenFlash(true);
    } else {
      setGreenFlash(false);
    }

    // Reset flash state after 500ms to remove the green color
    setTimeout(() => setFlash(false), 125);
  };

  useEffect(() => {
    setQuestionItems(INITIAL_QUESTIONS);
    setNumberQuestion(NUMBER_QUESTIONS[questionItems[0]]);

    // Function to handle the animation frame
    const updateProgress = (timestamp: number) => {
      const elapsed = timestamp - startTimer; // Calculate elapsed time since animation started
      const newProgress = Math.max(
        100 - (elapsed / 1000) * (100 / startTimer),
        0
      ); // Decrease over time, at 10% per second

      setProgress(newProgress);

      // Continue the animation as long as progress is greater than 0
      if (newProgress > 0) {
        animationIdRef.current = requestAnimationFrame(updateProgress);
        setAnimationId(id); // Store the animation ID in state
      }
    };

    // Start the animation
    const id = requestAnimationFrame(updateProgress);
    setAnimationId(id);

    // Cleanup function to stop the animation when the component unmounts
    return () => cancelAnimationFrame(id);
  }, []);

  useEffect(() => {
    const interval = window.setInterval(() => {
      if (seconds >= 0) {
        setSeconds((prevSeconds) => {
          const newSeconds = prevSeconds - 1;
          if (newSeconds < 0) return 0;
          return newSeconds;
        });
      } else {
        console.log('gameover');
      }
    }, 1000);

    setCountdownIntervalId(interval);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures this runs only once

  const handleClick = (value, index) => {
    setFlashingIndex(index);
    onNumberClickedHandler(value, index);

    // Remove the flash effect after a short delay
    // setTimeout(() => {
    //   setFlashingIndex(null);
    // }, 300); // Duration of the flash in milliseconds
  };

  const onNumberClickedHandler = (value: number, index: number) => {
    console.log('index: ', index);
    setFlashingIndex(index);

    setUserAttempts((prev) => prev + 1);
    console.log(`${value} with ${numberQuestion.eq}`);
    let equation = `${value} ${numberQuestion.eq}`;
    console.log(
      `${value} ${numberQuestion.eq} = ${evaluateEquation(equation)}`
    );
    if (evaluateEquation(equation)) {
      handleFlashClick(true);
      setCount((prev) => prev + Math.abs(value));

      // update numbers index with new number
      const randNum = getRandomNumber(-50, 50);
      const tempArr = randomNumbers;
      tempArr[index] = randNum;
      setRandomNumbers(tempArr);
    } else {
      handleFlashClick(false);
      setCount((prev) => prev - Math.abs(value));
    }
  };

  useEffect(() => {
    if (seconds === 0) {
      setIsGameOver(true);
      setIsHowToPlayModalOpen(false);
      console.log('GAMEOVER');

      if (count > highscore) {
        addItemToLocalStorage(game_highscore_key, count);
        setHighscore(count);
      }
    }
  }, [seconds]);

  const setNumberQuestionHandler = () => {
    setQuestionIndex((prev) => {
      const newIndex = prev + 1;
      console.log('updating question to index: ', newIndex);
      setNumberQuestion(NUMBER_QUESTIONS[questionItems[newIndex]]);
      console.log('Setting question to: ', NUMBER_QUESTIONS[newIndex]);
      return newIndex;
    });
  };

  const handleNavigateHomeClick = () => {
    navigate('/games'); // Specify the route you want to navigate to
  };

  const numberTiles = randomNumbers.map((el, index) => {
    return (
      <Grid
        key={index}
        item
        xs={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          borderRadius: 3,
          cursor: 'pointer',
        }}
      >
        <Box
          sx={(theme) => ({
            position: 'relative',
            width: 40,
            height: 40,
            p: 3,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.text.secondary,
            fontWeight: 500,
            transition:
              'font-size 0.15s ease-out, font-weight 0.3s ease-out, background-color 0.15s ease-out',
            borderRadius: '8px',
            fontSize: '1.25rem',
            backgroundColor: flash
              ? greenFlash
                ? 'rgba(22, 219, 147, 0.2)'
                : 'rgba(239, 121, 138, 0.1)'
              : '',
            '&:hover': {
              fontSize: '2rem',
              fontWeight: 700,
            },
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '100%',
              left: 0,
              width: 0,
              height: '2px',
              backgroundColor: '#1976d2',
              transition: '0.3s',
            },
            '&:hover::before': {
              width: '100%',
            },
          })}
          onClick={() => handleClick(el, index)}
        >
          {el}
        </Box>
      </Grid>
    );
  });

  return (
    <Box>
      <Grid
        container
        direction="column"
        // spacing={2}
        // justifyContent="center"
        // alignContent="center"
        // alignItems="center"
        sx={(theme) => ({ height: '100px' })}
      >
        <Grid item xs={6} sx={{ textAlign: 'center', pt: '0px !important' }}>
          <Grid
            container
            direction="row"
            // spacing={0}
            justifyContent="center"
            alignItems="center"
            // alignContent="center"
            sx={{ mt: 2 }}
          >
            <Grid item xs={3} sx={(theme) => ({ textAlign: 'left' })}>
              {/* <Box display="flex" gap={1} alignItems="center"> */}
              <IconButton
                aria-label="search"
                color="primary"
                onClick={handleNavigateHomeClick}
                size="large"
                sx={{ transform: 'rotateY(180deg)', padding: 2 }}
              >
                <ExitToAppIcon
                  sx={{ fontSize: '2rem', fontWeight: 'lighter' }}
                />
              </IconButton>
              {/* </Box> */}
            </Grid>
            <Grid
              item
              xs={6}
              sx={(theme) => ({
                textAlign: 'center',
                // backgroundColor: flash
                //   ? greenFlash
                //     ? 'rgba(22, 219, 147, 0.2)'
                //     : 'rgba(239, 121, 138, 0.1)'
                //   : '#fff',
                opacity: 1,
              })}
            >
              <Typography
                variant="h2"
                sx={{
                  mt: 0,
                  textAlign: 'center',
                  fontWeight: 500,
                  color: '#333',
                }}
              >
                {count}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={(theme) => ({ textAlign: 'right' })}>
              {/* <Box display="flex" gap={1} alignItems="flex-end"> */}
              <ThemeModeSwitch show={false} />
              <IconButton
                aria-label="search"
                color="primary"
                onClick={openHowToPlayModalHandler}
              >
                <QuestionMarkIcon sx={{ fontSize: '2.35rem' }} />
              </IconButton>
              {/* </Box> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        spacing={0}
        sx={(theme) => ({
          // overflow: 'hidden',
          width: '1600px',
          left: '42%',
          position: 'relative',
        })}
      >
        <Grid
          item
          xs={12}
          sx={(theme) => ({
            textAlign: 'center',
            // width: '100%',
            // overflow: 'hidden',
          })}
        >
          {/* <Grid
            container
            direction="row"
            spacing={2}
            sx={(theme) => ({ overflow: 'hidden' })}
          > */}
          {/* <Grid item xs={6}>
              h
            </Grid>
            <Grid item xs={6}>
              s */}
          <QuestionsContainer
            items={questionItems}
            question={numberQuestion.label}
            flash={flash}
            greenFlash={greenFlash}
            addQuestionToArrayHandler={addQuestionToArrayHandler}
            setNumberQuestionHandler={setNumberQuestionHandler}
          />
          {/* </Grid> */}
          {/* </Grid> */}
        </Grid>
      </Grid>
      <Box sx={{ width: '102%' }}>
        <BorderLinearProgress
          variant="determinate"
          value={progress}
          sx={(theme) => ({
            height: 3,
            '& .MuiLinearProgress-bar': {
              backgroundColor: theme.palette.primary.main, // Progress bar color
            },
            '&.MuiLinearProgress-colorPrimary': {
              backgroundColor: 'efefef', // Background color
            },
            // TODO remove this if there are scrollbars on load
            left: '-3px',
            width: '100.1%',
          })}
        />
      </Box>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}></Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          px: { xs: 3, sm: '25%', md: '30%', lg: '40%' },
          pt: 2,
        }}
      >
        {numberTiles}
      </Grid>
      <GameModal
        isGameOver={isGameOver}
        seconds={seconds}
        userHasWon={false}
        highscore={highscore}
        showMessages={false}
        showTitle={false}
      />
      <HowToPlayModal
        isOpen={isHowToPlayModalOpen}
        setIsHowToPlayModalOpen={setIsHowToPlayModalOpen}
        openHowToPlayModalHandler={openHowToPlayModalHandler}
      >
        <Grid item>
          <Grid item sx={{ textAlign: 'center', mb: 1 }}>
            <Typography
              id="transition-modal-title"
              variant="h3"
              sx={{ fontWeight: 500 }}
            >
              {highscore}
            </Typography>
            <Typography
              id="transition-modal-title"
              variant="h6"
              sx={{ fontWeight: 300, mb: 2 }}
            >
              Highscore
            </Typography>
            {/* {showTitle && ( */}
            <Divider
              sx={(theme) => ({
                bgcolor: theme.palette.primary.main,
                height: 1,
              })}
            />
            {/* )} */}
          </Grid>

          <Typography variant="h5" sx={{ fontSize: '24px', fontWeight: '700' }}>
            How To Play
          </Typography>
          <br />
          <Typography variant="body1" sx={{ fontSize: '16px' }}>
            Tap on a number that is associated with the question in the top bar
          </Typography>
          <br />
          <Typography variant="h6">Good luck!</Typography>
        </Grid>
      </HowToPlayModal>
    </Box>
  );
};

export default NumberRecognitionGame;
