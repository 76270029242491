import { Divider, Grid, styled } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import {
  convertDistance,
  convertWeight,
} from '../../../../Utils/ConversionUtils';
import {
  addObjectToArrayInLocalStorage,
  getJSONFromLocalStorage,
} from '../../../../Utils/LocalStorageUtils';
import { getRandomNumber } from '../../../../Utils/NumberUtils';
import { getRandomStringFromArr } from '../../../../Utils/StringUtils';
import {
  CONVERSIONS,
  DISTANCE,
  DISTANCE_ARRAY,
  DISTANCE_KM,
  DISTANCE_M,
  WEIGHT,
  WEIGHT_ARRAY,
  WEIGHT_G,
  WEIGHT_KG,
} from '../../../../constants';
import { Item, PageTitle } from '../../../General/Components';
import Header from '../Header';
import Calculation from './Calculation';
import ConversionHistory from './ConversionHistory';
import TypeSelect from './TypeSelect';
import { getCurrentDateTime } from '../../../../Utils/DateUtils';

type ConversionProps = {
  text?: string;
};

export const TitleLanding = styled('h1')(({ theme }) => ({
  ...theme.typography.h1,
  fontFamily: 'Nunito Sans',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
  fontWeight: 100,
}));

const Conversion = ({ text }: ConversionProps) => {
  const [conversionType, setConversionType] = useState('weight');
  const [fromType, setFromType] = useState('');
  const [toType, setToType] = useState('');
  const [sourceValue, setSourceValue] = useState(10);
  const [targetValue, setTargetValue] = useState(0);

  const [conversionsHistory, setConversionsHistory] = useState([]);

  const handleTypeSelectChange = (event: any) => {
    // console.log("handleTypeSelectChange", event.target.value);
    setConversionType(event.target.value);
    switch (event.target.value) {
      case WEIGHT:
        setFromType(WEIGHT_G);
        setToType(WEIGHT_KG);
        break;
      case DISTANCE:
        setFromType(DISTANCE_M);
        setToType(DISTANCE_KM);
        break;
      default:
        break;
    }
  };

  const handleSourceInputChange = (event: any) => {
    // console.log("handleSourceInputChange", event.target.value);
    setSourceValue(event.target.value);
  };

  const handleSourceInputChangeWithBtn = (value: number) => {
    // console.log("handleSourceInputChangeWithBtn", value);
    setSourceValue(value);
  };

  const handleTargetInputChange = (event: any) => {
    // console.log("handleTargetInputChange", event.target.value);
    setTargetValue(event.target.value);
  };

  const handleFromTypeChange = (event: any) => {
    // console.log("handleFromTypeChange", event.target.value);
    setFromType(event.target.value);
  };

  const handleToTypeChange = (event: any) => {
    // console.log("handleToTypeChange", event.target.value);
    setToType(event.target.value);
  };

  const randomiseConversionHandler = () => {
    setSourceValue(getRandomNumber(1, 1000));
    switch (conversionType) {
      case WEIGHT:
        setFromType(getRandomStringFromArr(WEIGHT_ARRAY));
        setToType(getRandomStringFromArr(WEIGHT_ARRAY));
        break;
      case DISTANCE:
        setFromType(getRandomStringFromArr(DISTANCE_ARRAY));
        setToType(getRandomStringFromArr(DISTANCE_ARRAY));
        break;
      default:
        break;
    }
  };

  const swapSourceTargetValues = () => {
    const sourceTmp = sourceValue;
    const fromTypeTmp = fromType;
    const targetTmp = targetValue;
    const toTypeTmp = toType;

    setSourceValue(parseFloat(targetTmp.toFixed(3)));
    setTargetValue(sourceTmp);

    setToType(fromTypeTmp);
    setFromType(toTypeTmp);
  };

  const saveToLocalStorageHandler = () => {
    // Usage example:
    const locale = 'en-AU'; // Change this to your desired locale
    const formattedDateTime = getCurrentDateTime(locale);

    const objToAdd = {
      date: formattedDateTime,
      type: conversionType,
      from: fromType,
      to: toType,
      sourceValue: sourceValue,
      targetValue: targetValue,
    };
    addObjectToArrayInLocalStorage(CONVERSIONS, objToAdd);

    setConversionsHistory(getJSONFromLocalStorage(CONVERSIONS));
  };

  useEffect(() => {
    switch (conversionType) {
      case 'weight':
        setTargetValue(convertWeight(sourceValue, fromType, toType));
        break;
      case 'distance':
        setTargetValue(convertDistance(sourceValue, fromType, toType));
        break;
      default:
        break;
    }
  }, [sourceValue, fromType, toType]);

  useEffect(() => {
    setConversionsHistory(getJSONFromLocalStorage(CONVERSIONS));
  }, []);

  useEffect(() => {
    switch (conversionType) {
      case WEIGHT:
        setFromType(WEIGHT_KG);
        setToType(WEIGHT_G);
        break;
      default:
        break;
    }
  }, []);

  return (
    <Fragment>
      <Grid
        container
        justifyContent="space-around"
        alignContent="center"
        direction="column"
        alignItems="center"
      >
        <Grid item xs={12}>
          <PageTitle>Conversion</PageTitle>
        </Grid>
        <Grid
          item
          xs={12}
          sx={(theme) => ({ width: { xs: '100%', sm: '200px' }, p: 2 })}
        >
          <TypeSelect
            value={conversionType}
            handleTypeSelectChange={handleTypeSelectChange}
          />
        </Grid>
        <Grid style={{ width: '100%', maxWidth: '1600px', padding: '0px 5%' }}>
          <Calculation
            handleSourceInputChange={handleSourceInputChange}
            handleSourceInputChangeWithBtn={handleSourceInputChangeWithBtn}
            handleTargetInputChange={handleTargetInputChange}
            handleFromTypeChange={handleFromTypeChange}
            conversionType={conversionType}
            fromType={fromType}
            handleToTypeChange={handleToTypeChange}
            toType={toType}
            sourceValue={sourceValue}
            targetValue={targetValue}
            randomiseConversionHandler={randomiseConversionHandler}
            swapSourceTargetValues={swapSourceTargetValues}
            saveToLocalStorageHandler={saveToLocalStorageHandler}
          />
        </Grid>
        <Divider />
        <Grid item xs={10} style={{ width: '100%', padding: '0px 5%' }}>
          <ConversionHistory value={conversionsHistory} />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Conversion;
