import { Grid } from '@mui/material';

import { GridItem, RandomNumberAnswer } from './CountdownTimer';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';

interface AnswerBoxProps {
  index: number;
  value: RandomNumberAnswer;
}

const AnswerBox: React.FC<AnswerBoxProps> = ({ index, value }) => {
  return (
    <Grid item xs={2} key={index}>
      <GridItem
        sx={{
          backgroundColor: value.correct ? '#7FDBAC' : '',
          border: value.correct ? '2px solid #2D9F64' : '2px solid #ccc',
          color: value.correct ? '#fff' : '#333',
          fontWeight: 'bold',
          justifyContent: 'center',
          display: 'grid',
          padding: 0.6,
        }}
      >
        {value.correct ? (
          value.num + 1
        ) : (
          <QuestionMarkOutlinedIcon color="action" />
        )}
      </GridItem>
    </Grid>
  );
};

export default AnswerBox;
