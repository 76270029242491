import {
  Box,
  Button,
  Grid,
  Stack,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState, useEffect, Fragment } from 'react';
import { Item } from '../../../General/Components';
import CalcBox from './CalcBox';
import ResultsBox from './ResultsBox';

type CalculationProps = {
  text?: string;
  handleSourceInputChange: any;
  handleSourceInputChangeWithBtn: any;
  handleTargetInputChange: any;
  handleFromTypeChange: any;
  conversionType: string;
  fromType: string;
  handleToTypeChange: any;
  toType: string;
  sourceValue: number;
  targetValue: number;
  randomiseConversionHandler: () => void;
  swapSourceTargetValues: () => void;
  saveToLocalStorageHandler: () => void;
};

const helperNumbers = [1, 10, 50, 100, 500];

export const ToText = styled('h3')(({ theme }) => ({
  ...theme.typography.h3,
  fontFamily: 'Nunito Sans',
  // padding: theme.spacing(1),
  textAlign: 'center',
  // color: "white",
  fontWeight: 100,
  margin: '0px 0px 0px 0px',
}));

const Calculation = ({
  text,
  handleSourceInputChange,
  handleSourceInputChangeWithBtn,
  handleTargetInputChange,
  handleFromTypeChange,
  conversionType,
  fromType,
  handleToTypeChange,
  toType,
  sourceValue,
  targetValue,
  randomiseConversionHandler,
  swapSourceTargetValues,
  saveToLocalStorageHandler,
}: CalculationProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Fragment>
      {conversionType && (
        <Grid
          container
          direction={isMobile ? 'column' : 'row'}
          alignItems="center"
        >
          <Grid item sm={12} md={5} sx={(theme) => ({ width: '100%' })}>
            {/* <Item> */}
            <CalcBox
              conversionType={conversionType}
              text="from"
              type={fromType}
              handleInputChange={handleSourceInputChange}
              handleFromTypeChange={handleFromTypeChange}
              sourceValue={sourceValue}
            />
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              sx={(theme) => ({ mt: 2 })}
            >
              {helperNumbers.map((number, index) => (
                <Button
                  key={index}
                  variant="outlined"
                  fullWidth
                  onClick={() => handleSourceInputChangeWithBtn(number)}
                >
                  {number}
                </Button>
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={2}>
            <ToText>=</ToText>
          </Grid>
          <Grid item sm={12} md={5} sx={(theme) => ({ width: '100%' })}>
            <ResultsBox
              conversionType={conversionType}
              type={toType}
              text={'To'}
              handleToTypeChange={handleToTypeChange}
              targetValue={targetValue}
            />
            <Stack direction="row" spacing={2} sx={(theme) => ({ mt: 2 })}>
              <Button
                variant="outlined"
                fullWidth
                onClick={swapSourceTargetValues}
              >
                Swap
              </Button>
              <Button
                variant="outlined"
                fullWidth
                onClick={randomiseConversionHandler}
              >
                Randomise
              </Button>
              <Button
                variant="contained"
                fullWidth
                onClick={saveToLocalStorageHandler}
              >
                Save
              </Button>
            </Stack>
          </Grid>
        </Grid>
      )}
      {!conversionType && (
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <ToText style={{ fontSize: '2rem' }}>
              Please select a Type to continue...
            </ToText>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default Calculation;
