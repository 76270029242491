import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

type TypeSelectProps = {
  text?: string;
  value: string;
  handleTypeSelectChange: any;
};

const TypeSelect = ({
  text,
  value,
  handleTypeSelectChange,
}: TypeSelectProps) => {
  // const [value, setValue] = React.useState("");

  // const handleChange = (event: SelectChangeEvent) => {
  //   setValue(event.target.value as string);
  // };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label="Type"
          onChange={(event) => handleTypeSelectChange(event)}
          style={{ textAlign: 'left' }}
        >
          <MenuItem value={'weight'}>Weight</MenuItem>
          <MenuItem value={'distance'}>Distance</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default TypeSelect;
