// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-bottom: 3px solid #1976d2;
  display: flex;
  justify-content: space-between;
  z-index: 100;
}

.header a {
  text-decoration: none;
}

.logo {
  font-size: 26px;
  color: #1976d2;
  font-weight: 700;
}

.navbar a {
  /* text-decoration: none; */
  position: relative;
  font-size: 18px;
  color: #1976d2 !important;
  font-weight: 500;
  margin-left: 40px;
}

.navbar a::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #1976d2;
  transition: 0.3s;
}

.navbar a:hover::before {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/General/NavBar/Navbar.css"],"names":[],"mappings":"AAAA;EACE,MAAM;EACN,OAAO;EACP,WAAW;EACX,sBAAsB;EACtB,gCAAgC;EAChC,aAAa;EACb,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,WAAW;EACX,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".header {\n  top: 0;\n  left: 0;\n  width: 100%;\n  background-color: #fff;\n  border-bottom: 3px solid #1976d2;\n  display: flex;\n  justify-content: space-between;\n  z-index: 100;\n}\n\n.header a {\n  text-decoration: none;\n}\n\n.logo {\n  font-size: 26px;\n  color: #1976d2;\n  font-weight: 700;\n}\n\n.navbar a {\n  /* text-decoration: none; */\n  position: relative;\n  font-size: 18px;\n  color: #1976d2 !important;\n  font-weight: 500;\n  margin-left: 40px;\n}\n\n.navbar a::before {\n  content: '';\n  position: absolute;\n  top: 100%;\n  left: 0;\n  width: 0;\n  height: 3px;\n  background-color: #1976d2;\n  transition: 0.3s;\n}\n\n.navbar a:hover::before {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
