import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { SlideDirection } from '@mui/x-date-pickers/DateCalendar/PickersSlideTransition';
import { Fade } from 'react-awesome-reveal';
import { useNavigate } from 'react-router-dom';

type MainProps = {
  text?: string;
};

const Main = ({ text }: MainProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/home'); // Specify the route you want to navigate to
  };

  const upperTitleDesktop = (title: string, direction?: SlideDirection) => (
    <Grid
      item
      xs={12}
      display="flex"
      alignItems="flex-end"
      justifyContent="center"
      className="hover-target-1"
      sx={{
        p: 0,
        borderRadius: 1,
        transition: 'transform 0.3s ease, background-color 0.3s ease',
        textAlign: 'center',
      }}
    >
      <Typography
        variant="h1"
        sx={{
          letterSpacing: '12px',
          color: '#fff',
          fontSize: {
            xs: '2rem', // Small screens
            sm: '4rem', // Medium screens
            md: '6rem', // Large screens
          },
        }}
      >
        {title}
      </Typography>
    </Grid>
  );

  const upperTitleMobile = (title: string) => (
    <Grid
      item
      xs={12}
      display="flex"
      alignItems="flex-end"
      justifyContent="center"
      className="hover-target-1"
      sx={{
        p: 2,
        borderRadius: 1,
        transition: 'transform 0.3s ease, background-color 0.3s ease',
      }}
    >
      <Typography
        variant="h1"
        // fontSize={160}
        sx={{
          letterSpacing: '10px',
          position: 'relative',
          color: '#fff',
          fontSize: {
            xs: '60px', // Font size on extra-small screens (0px - 599px)
            // sm: '30px', // Font size on small screens (600px - 899px)
            // md: '30px', // Font size on medium screens (900px - 1199px)
            // lg: '30px', // Font size on large screens (1200px - 1535px)
            // xl: '30px', // Font size on extra-large screens (1536px+)
          },
        }}
      >
        {title}
      </Typography>
    </Grid>
  );

  return (
    <Grid
      container
      direction="row"
      spacing={2}
      sx={{
        backgroundColor: '#26222c',
      }}
      onClick={handleClick}
    >
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          spacing={0}
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          sx={{
            height: '100vh',
            textAlign: 'center',
          }}
        >
          {upperTitleDesktop('Everything')}

          <Fade duration={3000}>
            <Grid item xs={12} sx={{ textAlign: 'center', padding: '0px 10%' }}>
              <Typography
                variant="body1"
                fontWeight={500}
                sx={{
                  paddingY: '10px',
                  colorr: '#00c17f',
                  color: '#fff',
                  fontSize: {
                    xs: '0.9rem', // Small screens
                    sm: '1rem', // Medium screens
                    md: '1rem', // Large screens
                  },
                }}
              >
                <EmphTxt sx={{ fontSize: '2rem' }}>Welcome</EmphTxt> to my
                website, meticulously crafted from scratch using the powerful{' '}
                <EmphTxt>React</EmphTxt> and <EmphTxt>MUI v5</EmphTxt>{' '}
                frameworks! This site showcases a seamless blend of
                functionality and design, leveraging React’s dynamic
                capabilities with MUI’s modern,
                <EmphTxt> responsive</EmphTxt> components. My goal is to provide
                an intuitive, high-performing user experience across all
                devices, all while building from the ground up with{' '}
                <EmphTxt>customizations</EmphTxt> that highlight the{' '}
                <EmphTxt>flexibility</EmphTxt> and strength of these leading
                technologies. Dive in and explore a platform built to be as{' '}
                <EmphTxt>innovative</EmphTxt> as the frameworks behind it!{' '}
                <br />
                <br />
                <EmphTxt>Everything</EmphTxt> you will see is designed by myself
                showcasing the
                <EmphTxt> skills</EmphTxt> I have developed with over{' '}
                <EmphTxt> 8 years</EmphTxt> in the technology industry
              </Typography>
              {/* <Divider sx={{ borderColor: '#fff', my: 2 }} /> */}
            </Grid>
          </Fade>
          {/* <Fade duration={3000}> */}
          {/* <Typography variant="h4" sx={{ color: '#fff', borderColor: 'red' }}>
            Technologies and Frameworks
          </Typography> */}
          {/* <Grid item xs={12} sx={{ padding: '0px 20%', textAlign: 'left' }}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={5}></Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  fontWeight={500}
                  sx={{
                    paddingY: '10px',
                    colorr: '#00c17f',
                    color: '#fff',
                    fontSize: {
                      xs: '0.9rem', // Small screens
                      sm: '1rem', // Medium screens
                      md: '1rem', // Large screens
                    },
                  }}
                >
                  <ul>
                    <li>ReactJS & Typescript</li>
                    <li>Material UI v5</li>
                    <li>HTML, CSS and JS</li>
                    <li>Github & Github Actions for CI/CD</li>
                    <li>Firebase Storage</li>
                    <li>GoDaddy Domain</li>
                  </ul>
                </Typography>
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
          </Grid> */}
          {/* </Fade> */}
          {upperTitleDesktop('Stevens')}
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="flex-end"
            justifyContent="center"
            sx={{ mt: 2 }}
          >
            <StylishButton
              variant="text"
              size="large"
              onClick={handleClick}
              endIcon={<ArrowForwardIcon />}
            >
              Continue
            </StylishButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const StylishButton = styled(Button)(({ theme }) => ({
  color: '#fff', // Text color
  '&:hover': {
    backgroundColor: theme.palette.primary.light, // Light blue on hover
  },
}));

const EmphTxt = styled('span')(({ theme }) => ({
  fontWeight: '700',
  fontSize: '1.3rem',
  color: 'rgb(94 234 212)',
}));

export default Main;
