import { blue, pink } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const darkBlueTheme = createTheme({
  palette: {
    primary: {
      // main: "#4A8FE7",
      main: '#2A628F',
      dark: '#13293D',
      light: '#3E92CC',
    },
    secondary: {
      main: '#fcfcfc',
      dark: '#555',
      light: '#fafafa',
    },
    background: {
      default: '#fff', // Default background color
      paper: '#fafafa', // Paper background color (e.g., cards)
    },
    text: {
      primary: '#444444', // Primary text color
      secondary: '#888888', // Secondary text color
    },
    // You can define other colors like success, error, warning, etc.
    // For example:
    success: {
      main: '#73FBD3', // Success color
    },
  },
  // You can customize other aspects of the theme like typography, spacing, etc.
  typography: {
    // Customize typography settings here if needed
  },
  // Add other customizations as needed
});

export const lightBlueTheme = createTheme({
  palette: {
    primary: {
      // main: "#4A8FE7",
      main: '#42A5F5',
      dark: '#2196F3',
      light: '#64B5F6',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f1f1f1',
      dark: '#555',
      light: '#fafafa',
    },
    background: {
      // default: "#fff", // Default background color
      // paper: "#fafafa", // Paper background color (e.g., cards)
    },
    text: {
      primary: '#444444', // Primary text color
      secondary: '#888888', // Secondary text color
    },
    // You can define other colors like success, error, warning, etc.
    // For example:
    success: {
      main: '#73FBD3', // Success color
    },
  },
  // You can customize other aspects of the theme like typography, spacing, etc.
  typography: {
    // Customize typography settings here if needed
    fontFamily: 'Nunito Sans',
  },
  // Add other customizations as needed
});

// export default theme;
