import { Grid, useTheme } from '@mui/material';
import { Fragment, useState } from 'react';
import SocialLinks from './SocialLinks';

type FooterProps = {
  show: boolean | undefined;
};

const Footer = ({ show }: FooterProps) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  return (
    <Fragment>
      {show && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            padding: 20,
            position: 'absolute',
            bottom: 0,
            backgroundColor: '#fff',
          }}
        >
          <Grid>
            <SocialLinks />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default Footer;
