export const getCurrentDateTime = (locale) => {
  const currentDate = new Date();
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };

  const formattedDateTime = currentDate.toLocaleDateString(locale, options);

  return formattedDateTime;
};

export const sortByDate = (array) => {
  // Convert date strings to Date objects
  array.forEach((item) => {
    const [day, month, year] = item.date.split('/');
    item.date = new Date(`${year}-${month}-${day}`);
  });

  // Sort the array by date
  array.sort((a, b) => b.date - a.date);

  // Convert Date objects back to date strings if needed
  array.forEach((item) => {
    const date = item.date;
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    item.date = `${day}/${month}/${year}`;
  });

  return array;
};

export const getRandomDateBeforeToday = () => {
  const today = new Date();
  const randomDays = Math.floor(Math.random() * 365); // Change 365 to any desired range
  const randomDate = new Date(today);
  randomDate.setDate(today.getDate() - randomDays);
  const randomDateString = randomDate.toISOString().split('T')[0];
  return randomDateString;
};
