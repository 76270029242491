import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Snackbar,
  TextField,
  styled,
  useTheme,
} from '@mui/material';
import emailjs from 'emailjs-com';
import { useState } from 'react';
import { Item, SectionContainerBox } from '../../../General/Components';
import SocialLinks from '../../../General/Footer/SocialLinks';
import TitleDescription from '../../../General/TitleDescription';
import { GridPadding } from './Portfolio';

type ContactMeProps = {
  text?: string;
  backgroundColour: string;
};

export const ContactMeBox = styled(Card)(({ theme }) => ({
  backgroundColor: `${theme.palette.background.default} !important`,
}));

const email = {
  title: 'Email',
  description: 'info@everythingstevens.com',
};

const location = {
  title: 'Location',
  description: 'Sydney, Australia',
};

const phone = {
  title: 'Phone',
  description: '+01 234 908 3333',
};

const ContactMe = ({ text, backgroundColour }: ContactMeProps) => {
  const theme = useTheme();

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);

  const handleClickSuccess = () => {
    setOpenSuccess(true);
  };

  const handleCloseSuccess = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };

  const handleClickError = () => {
    setOpenError(true);
  };

  const handleCloseError = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError(false);
  };

  const emailValidationHandler = (e: any) => {
    let emailAddress: string = e.target.value;
    if (!emailAddress.includes('@')) {
      setShowEmailError(true);
    } else {
      setShowEmailError(false);
    }
  };

  const sendEmail = (e: any) => {
    e.preventDefault(); //This is important, i'm not sure why, but the email won't send without it

    //TODO switch service to everythingstevens00@gmail.com
    //TODO add CAPTCHA to avoid getting SPAM

    emailjs
      .sendForm(
        'service_560dfz3',
        'template_7831qi8',
        e.target,
        'nSEVfNCnWLN-1dGy4'
      )
      .then(
        (result) => {
          handleClickSuccess();
        },
        (error) => {
          // console.log(error.text);
          handleClickError();
        }
      );
  };

  return (
    <SectionContainerBox bgcolor={backgroundColour}>
      <GridPadding container direction="row" maxWidth={1600}>
        <Grid xs={12} sm={12} md={4}>
          <Item style={{ height: '100%' }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="left"
              style={{
                textAlign: 'left',
                height: '100%',
              }}
            >
              <ContactMeBox style={{ width: '100%', padding: '16px' }}>
                {/* <TitleDescription
                  title={email.title}
                  description={email.description}
                /> */}
                <TitleDescription
                  title={location.title}
                  description={location.description}
                />
                {/* <TitleDescription
                  title={phone.title}
                  description={phone.description}
                /> */}
                <SocialLinks />
              </ContactMeBox>
            </Box>
          </Item>
        </Grid>
        <Grid xs={12} sm={12} md={8}>
          <Item>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="left"
              style={{ textAlign: 'left' }}
            >
              <ContactMeBox style={{ width: '100%', padding: '16px' }}>
                <TitleDescription title="Get in touch" />
                <form onSubmit={sendEmail}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Name"
                        name="from_name"
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        label="Email"
                        name="reply_to"
                        variant="outlined"
                        size="small"
                        onChange={emailValidationHandler}
                        error={showEmailError}
                        helperText={
                          showEmailError ? 'Invalid email address' : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        label="Subject"
                        name="subject"
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        label="Your Message"
                        name="message"
                        variant="outlined"
                        multiline
                        rows={4}
                        size="small"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ float: 'right', textAlign: 'right' }}
                    >
                      <Button type="submit" variant="contained" color="primary">
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </ContactMeBox>
            </Box>
          </Item>
        </Grid>
      </GridPadding>
      <Snackbar
        open={openSuccess}
        autoHideDuration={3000}
        onClose={handleCloseSuccess}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{ width: '100%' }}
        >
          Email sent successfully. I will respond as soon as possible. Thank
          you. Have a great day.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openError}
        autoHideDuration={3000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: '100%' }}
        >
          <span>Email not sent, an error has occured</span>
          <br />
          <span>Please try again later</span>
        </Alert>
      </Snackbar>
    </SectionContainerBox>
  );
};

export default ContactMe;
