// src/theme/presets/default.ts

import { createTheme } from '@mui/material/styles';
import { AppTheme } from '.';

const { palette } = createTheme();
const defaultLight = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    mode: 'light',
  },
});
const defaultDark = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    mode: 'dark',
  },
});
export const theme: AppTheme = {
  light: {
    ...defaultLight,
    palette: {
      ...defaultLight.palette,
      success: palette.augmentColor({
        color: {
          main: '#2dc653',
          contrastText: '#fff',
        },
      }),
      error: palette.augmentColor({
        color: {
          main: '#da1e37',
          contrastText: '#fff',
        },
      }),
      text: {
        primary: '#222', // Replace with your desired color
        secondary: '#555', // Customize secondary color if needed
        tertiary: '#aaa', // Customize secondary color if needed
      },
      primary: {
        main: '#1976d2',
        dark: '#13293D',
        light: '#3E92CC',
      },
      secondary: {
        main: '#fcfcfc',
        dark: '#555',
        light: '#fafafa',
      },
      upvote: palette.augmentColor({
        color: {
          main: '#2e7d32',
          contrastText: '#32009a',
        },
      }),
      downvote: palette.augmentColor({
        color: {
          main: '#d32f2f',
          contrastText: '#fff',
        },
      }),
      containerPrimary: palette.augmentColor({
        color: {
          main: '#fff',
          contrastText: '#black',
        },
      }),
      containerSecondary: palette.augmentColor({
        color: {
          main: '#f3f3f3',
          contrastText: '#black',
        },
      }),
      containerTertiary: palette.augmentColor({
        color: {
          main: '#C0E5FF',
          contrastText: '#black',
        },
      }),
      background: {
        default: '#fff',
        paper: '#efefef',
      },
    },
  },
  dark: {
    ...defaultDark,
    palette: {
      ...defaultDark.palette,
      secondary: {
        main: '#232e37', // Custom color for secondary buttons
        contrastText: '#ffffff', // Text color for contrast
      },
      upvote: palette.augmentColor({
        color: {
          main: '#66bb6a',
          contrastText: 'rgba(0,0,0,0.87)',
        },
      }),
      downvote: palette.augmentColor({
        color: {
          main: '#f44336',
          contrastText: '#fff',
        },
      }),
      containerPrimary: palette.augmentColor({
        color: {
          main: '#121212',
          contrastText: 'white',
        },
      }),
      divider: '#79757f',
      containerSecondary: palette.augmentColor({
        color: {
          main: '#333',
          contrastText: 'white',
        },
      }),
      background: {
        default: '#1b1c17',
        paper: '#1b1c17',
      },
    },
  },
};
