import React, { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Header from './Header';
import Navbar from '../../General/NavBar/Navbar';

const Container = styled('div')({
  textAlign: 'center',
  marginTop: '100px',
});

const Heading = styled(Typography)({
  fontSize: '3rem',
  marginBottom: '20px',
});

const Message = styled(Typography)({
  fontSize: '1.2rem',
  marginBottom: '20px',
});

const StyledButton = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: `${theme.palette.primary.main}`,
  border: `1px solid ${theme.palette.primary.main}`,
  padding: '10px 20px',
  borderRadius: '5px',
  fontSize: '1.2rem',
  transition: 'background-color 0.3s ease',

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const NotFound = () => {
  return (
    <Fragment>
      {/* <Header /> */}
      <Navbar />
      <Container>
        <Heading variant="h1">404 Not Found</Heading>
        <Message variant="body1">
          The page you are looking for does not exist.
        </Message>
        <StyledButton to="/home">Go to Home</StyledButton>
      </Container>
    </Fragment>
  );
};

export default NotFound;
