import dayjs from 'dayjs';
import { getRandomDateBeforeToday } from '../../../../Utils/DateUtils';
import {
  getRandomNumber,
  randomFloatBetween,
} from '../../../../Utils/NumberUtils';
import { capitalize, generateLoremIpsum } from '../../../../Utils/StringUtils';
import { categories } from '../../../../constants';

// Create random banking entries
export const createInitialBankingEntries = () => {
  const bankingArr = [];

  const entriesLength = getRandomNumber(5, 100);

  for (let index = 0; index < entriesLength; index++) {
    let tmpObj = {
      type: getRandomNumber(0, 1) === 1 ? 'deposit' : 'expense',
      date: getRandomDateBeforeToday(),
      amount: randomFloatBetween(2.0, 1000),
      description: capitalize(generateLoremIpsum(getRandomNumber(2, 10))),
      category: categories[getRandomNumber(0, categories.length - 1)],
    };
    bankingArr.push(tmpObj);
  }

  return bankingArr;
};

export const groupTransactionsByDate = (transactions) => {
  const groupedByDate = {};

  transactions.forEach((transaction) => {
    const date = transaction.date;
    if (!groupedByDate[date]) {
      groupedByDate[date] = [];
    }
    groupedByDate[date].push(transaction);
  });

  const resultArray = Object.keys(groupedByDate).map((date) => ({
    date,
    transactions: groupedByDate[date],
  }));

  return resultArray;
};

export const calculateTotalAmount = (array) => {
  return array.reduce((total, item) => total + item.amount, 0);
};

export const calculateNetAmount = (array) => {
  let netAmount = 0;

  array.forEach((item) => {
    if (item.type === 'deposit') {
      netAmount += item.amount;
    } else if (item.type === 'expense') {
      netAmount -= item.amount;
    }
  });

  return netAmount;
};

export const convertTransactionsToBarChartFormat = (transactions, year) => {
  const monthlyArr = [
    ['Month', 'Deposits', 'Expenses'],
    ['Jan', 0, 0],
    ['Feb', 0, 0],
    ['Mar', 0, 0],
    ['Apr', 0, 0],
    ['May', 0, 0],
    ['Jun', 0, 0],
    ['Jul', 0, 0],
    ['Aug', 0, 0],
    ['Sep', 0, 0],
    ['Oct', 0, 0],
    ['Nov', 0, 0],
    ['Dec', 0, 0],
  ];

  // export const otherData = [
  //   ["Month", "Deposits", "Expenses"],
  //   ["Jan", 1000, 400],
  //   ["Feb", 1170, 460],
  //   ["Mar", 660, 1120],
  //   ["Apr", 1030, 540],
  //   ["May", 1030, 540],
  //   ["Jun", 1030, 540],
  //   ["Jul", 1030, 540],
  //   ["Aug", 1030, 540],
  //   ["Sep", 1030, 540],
  //   ["Oct", 1030, 540],
  //   ["Nov", 1030, 540],
  //   ["Dec", 1030, 540],
  // ];

  // [
  //   {
  //     type: "expense",
  //     date: "31/12/2023",
  //     amount: 186.08,
  //     description: "Lorem voluptate ipsum exercitation ullamco Cupidatat",
  //     category: "Travel",
  //   },
  //   {
  //     type: "expense",
  //     date: "30/12/2023",
  //     amount: 815.67,
  //     description: "Nulla laborum proident amet Lorem. commodo",
  //     category: "Takeaways",
  //   },
  //   {
  //     type: "expense",
  //     date: "28/12/2023",
  //     amount: 847,
  //     description:
  //       "Ullamco aliquip consectetur Lorem veniam ullamco cupidatat nulla",
  //     category: "Groceries",
  //   },
  // ];

  transactions.forEach((el) => {
    let monthAbbr = dayjs(el.date, 'DD/MM/YYYY').format('MMM');

    let monthArr = monthlyArr.find((item) => item[0] === monthAbbr);
    let monthArrIndex = monthlyArr.findIndex((item) => item[0] === monthAbbr);

    if (el.type === 'deposit') {
      monthArr[1] += el.amount;
    } else {
      // === expense
      monthArr[2] += el.amount;
    }
    monthlyArr[monthArrIndex] = monthArr;
  });

  console.log('monthlyArr :', monthlyArr);
  return monthlyArr;
};
