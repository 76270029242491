import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Item } from '../../General/Components';
import Auth from '../../Auth/authTsx';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
// import { auth } from '../../../App';

type LoginProps = {
  text?: string;
};

const Login = ({ text }: LoginProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onLogin = (e: any) => {
    console.log('login');
    e.preventDefault();
    // signInWithEmailAndPassword(auth, email, password)
    //   .then((userCredential) => {
    //     // Signed in
    //     const user = userCredential.user;
    //     navigate('/');
    //     console.log(user);
    //   })
    //   .catch((error) => {
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     console.log(errorCode, errorMessage);
    //   });
  };

  return (
    <div style={{ position: 'absolute', height: '100%', width: '100%' }}>
      {/* <Auth /> */}
      <Grid container direction="row" style={{ height: '100%' }}>
        <Grid sm={12} md={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: `${theme.palette.primary.light}`,
            }}
          ></div>
        </Grid>
        <Grid
          sm={12}
          md={4}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
          }}
          sx={{ px: 10 }}
        >
          <Stack spacing={3} width={'100%'} alignItems="flex-start">
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              Welcome to Everything Stevens
            </Typography>
            <Typography variant="body1" color={theme.palette.text.secondary}>
              Admin Login
            </Typography>
            <TextField
              required
              fullWidth
              label="Email"
              name="email"
              variant="outlined"
              size="small"
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              required
              fullWidth
              label="Password"
              name="password"
              variant="outlined"
              size="small"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Stack
              spacing={2}
              direction="row"
              justifyContent="space-between"
              alignContent="center"
              alignItems="center"
              width="100%"
            >
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Remember this device"
                />
              </FormGroup>
              <Typography variant="body1" sx={{ float: 'right', right: 0 }}>
                Forgot Password?
              </Typography>
              {/* TODO sign up page */}
              {/* <p className="text-sm text-white text-center">
              No account yet? <NavLink to="/signup">Sign up</NavLink>
            </p> */}
            </Stack>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ width: '50%', float: 'right' }}
              onClick={onLogin}
            >
              Sign in
            </Button>
          </Stack>
          {/* </FormControl> */}
          {/* </Item> */}
        </Grid>
        <Grid sm={12} md={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: `${theme.palette.primary.light}`,
            }}
          ></div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
