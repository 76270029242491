import { Box, Grid, styled } from '@mui/material';
import { SectionContainerBox } from '../../../General/Components';
import CardImageText from '../../../General/CardImageText';
import { Fade } from 'react-awesome-reveal';
// @ts-ignore
// import Fade from "react-awesome-reveal/Fade";

type PortfolioProps = {
  text?: string;
  backgroundColour: string;
};

export const GridPadding = styled(Grid)(({ theme, maxWidth }) => ({
  // Responsive styling
  //   padding: theme.spacing(1),
  //   [theme.breakpoints.down("md")]: {
  //     backgroundColor: red[500],
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     backgroundColor: blue[500],
  //   },
  //   [theme.breakpoints.up("lg")]: {
  // backgroundColor: green[500],
  // padding: "0px 420px",
  margin: '0 auto',
  maxWidth: `${(props: any) => props.maxWidth}`,
  //   },
}));

const pages = [
  {
    title: 'Bio',
    description: 'Information about me, myself and I',
  },
  {
    title: 'Blog',
    description: 'Coming Soon.',
  },
  {
    title: 'Gallery',
    description: 'Coming Soon.',
  },
  {
    title: 'Calculator',
    description: 'A simple calculator app',
  },
  {
    title: 'Facts',
    description: 'Coming Soon.',
  },
  {
    title: 'Conversion',
    description: 'Coming Soon.',
  },
  {
    title: 'Tasks',
    description: 'A simple tasks design and functionality',
  },
  {
    title: 'Banking',
    description: 'Coming Soon.',
  },
  {
    title: 'Countdown Timer Game',
    description: 'Can you find the incorrect seconds? Give it a go!',
  },
];
const Portfolio = ({ text, backgroundColour }: PortfolioProps) => {
  return (
    <SectionContainerBox bgcolor={backgroundColour}>
      <GridPadding container justifyContent="center" maxWidth={1000}>
        {pages.map((page, index) => (
          <Grid item xs={12} sm={6} md={4}>
            <Fade duration={2000} delay={index * 125}>
              <Box
                sx={{ m: 2 }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CardImageText
                  title={page.title}
                  description={page.description}
                />
              </Box>
            </Fade>
          </Grid>
        ))}
      </GridPadding>
    </SectionContainerBox>
  );
};

export default Portfolio;
