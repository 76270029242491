import { Card, CardContent } from '@mui/material';
import Chart from 'react-google-charts';

type BarChartProps = {
  data: any;
};

export const data = [
  ['Year', 'Sales', 'Expenses', 'Profit'],
  ['2014', 1000, 400, 200],
  ['2015', 1170, 460, 250],
  ['2016', 660, 1120, 300],
  ['2017', 1030, 540, 350],
];

export const otherData = [
  ['Month', 'Deposits', 'Expenses'],
  ['Jan', 1000, 400],
  ['Feb', 1170, 460],
  ['Mar', 660, 1120],
  ['Apr', 1030, 540],
  ['May', 1030, 540],
  ['Jun', 1030, 540],
  ['Jul', 1030, 540],
  ['Aug', 1030, 540],
  ['Sep', 1030, 540],
  ['Oct', 1030, 540],
  ['Nov', 1030, 540],
  ['Dec', 1030, 540],
];

export const options = {
  chart: {
    title: 'My Transactions by Month',
    subtitle: 'Deposits and Expenses',
  },
};

const BarChart = ({ data }: BarChartProps) => {
  return (
    <Card sx={{ backgroundColor: '#fff' }}>
      <CardContent>
        <Chart
          chartType="Bar"
          width="100%"
          height="400px"
          data={data}
          options={options}
        />
      </CardContent>
    </Card>
  );
};

export default BarChart;
