import React, { Component, ReactNode } from 'react';

interface ErrorBoundaryHelperProps {
  children: ReactNode;
  fallback?: ReactNode;
}

interface ErrorBoundaryHelperState {
  hasError: boolean;
}

class ErrorBoundaryHelper extends Component<
  ErrorBoundaryHelperProps,
  ErrorBoundaryHelperState
> {
  constructor(props: ErrorBoundaryHelperProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryHelperState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.error('Error caught in error boundary:', error, info);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback || <h2>Something went wrong.</h2>;
    }
    return this.props.children;
  }
}

export default ErrorBoundaryHelper;
