import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../App';

export const getFirebaseImage = (
  url: string,
  useStateFunc: (url: string) => void
) => {
  const imageRef = ref(storage, url);
  // console.log('imageRef: ', imageRef);
  getDownloadURL(imageRef)
    .then((url) => {
      useStateFunc(url); // Set the image URL to the state
    })
    .catch((error) => {
      console.error('Error fetching image URL:', error);
      return null;
    });
};
