import { styled } from '@mui/material';
import OutlinedCardText from '../../../General/OutlinedCardText';
import { Fact } from './Facts';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../../App';
import RandomFactCard from './RandomFactCard';
// import { analytics } from "../../../App";

type RandomFactProps = {
  obj?: any;
  isFavourite: boolean;
  favouritiseFactHandler: (fact: Fact, addToLS: boolean) => void;
  randomiseFact: () => void;
};

export const RandomFactContainer = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  // width: 500,
  textAlign: 'center',
}));

const RandomFact = ({
  obj,
  isFavourite,
  favouritiseFactHandler,
  randomiseFact,
}: RandomFactProps) => {
  return (
    <RandomFactContainer
      onClick={() => {
        logEvent(analytics, `random_fact_generated`);
        randomiseFact();
      }}
    >
      {/* Random Fact */}
      <RandomFactCard
        obj={obj}
        isFavourite={isFavourite}
        favouritiseFactHandler={favouritiseFactHandler}
      />
    </RandomFactContainer>
  );
};

export default RandomFact;
