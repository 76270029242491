import { getRandomNumber } from './NumberUtils';

// A simple utility function to capitalize the first letter of a string.
export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const convertShortToLong = (short) => {
  switch (short) {
    case 'g':
      return 'grams';
      break;
    case 'kg':
      return 'kilograms';
      break;
    case 'lb':
      return 'pounds';
      break;
    case 'oz':
      return 'ounces';
      break;
    case 'm':
      return 'meters';
      break;
    case 'km':
      return 'kilometers';
      break;
    case 'ft':
      return 'foot';
      break;
    case 'mi':
      return 'miles';
      break;
    default:
      break;
  }
};

export const getRandomStringFromArr = (arr) => {
  return arr[getRandomNumber(0, arr.length - 1)];
};

export const getThemeColour = (number) => {
  switch (number) {
    case 0:
      return 'default';
      break;
    case 1:
      return 'green';
      break;
    case 2:
      return 'blue';
      break;
    case 3:
      return 'red';
      break;
    default:
      return 'default';
      break;
  }
};

export const generateLoremIpsum = (words) => {
  const loremIpsum =
    'Ullamco voluptate commodo mollit veniam laborum ullamco irure do nulla magna ut proident commodo esse. Nostrud amet consectetur exercitation cillum.Minim cupidatat ad deserunt magna proident dolore ipsum exercitation quis tempor aliquip Lorem minim ea. Cupidatat excepteur do culpa eu quis Lorem.';
  const loremIpsumWords = loremIpsum.split(' ');
  const result = [];

  for (let i = 0; i < words; i++) {
    const randomIndex = Math.floor(Math.random() * loremIpsumWords.length);
    result.push(loremIpsumWords[randomIndex]);
  }

  return result.join(' ');
};
