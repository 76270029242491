import { Button, Grid, styled } from '@mui/material';
import { Fragment, useState } from 'react';
import {
  evaluateEquation,
  formatNumberExpo,
  formatNumberWithCommas,
} from '../../../../Utils/NumberUtils';
import {
  CALCULATOR_BUTTONS,
  DIVIDE,
  EQUALS,
  MINUS,
  PLUS,
  SPACE,
  TIMES,
} from '../../../../constants';
import { lightBlueTheme } from '../../../../themes/theme';
import { Item } from '../../../General/Components';

type CalculatorProps = {
  text?: string;
};

export const NumberButton = styled(Button)(({ theme, value }) => ({
  width: '100%',
  height: 80,
  borderRadius: 5,
  fontFamily: 'Nunito Sans',
  textAlign: 'center',
  fontWeight: 300,
  fontSize: '1.6rem',
  boxShadow:
    'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px',
  '&:hover': {
    backgroundColor: 'rgb(208, 230, 248) !important',
  },
}));

const Calculator = ({ text }: CalculatorProps) => {
  const [input, setInput] = useState('');
  const [result, setResult] = useState(0);

  const doCalcAction = (event: any) => {
    const value = event.target.value;
    switch (value) {
      case value:
        break;

      default:
        break;
    }
  };

  const updateInputHandler = (event: any) => {
    // console.log("event.target.value:", event.target.value);
    const value: string = event.target.value;
    switch (value.toLocaleLowerCase()) {
      case TIMES:
      case MINUS:
      case PLUS:
      case DIVIDE:
      case EQUALS:
        if (result > 0) {
          setInput(
            ('' + result).includes('.')
              ? result.toFixed(3)
              : result + ` ${value} `
          );
        } else {
          setInput((prev) => prev + SPACE + event.target.value + SPACE);
        }
        break;
      default:
        setInput((prev) => prev + '' + event.target.value + '');
        break;
    }
  };

  const doCalculation = (event: any) => {
    const calc = evaluateEquation(input);
    setResult(calc);
  };

  const clearAllInput = () => {
    setInput('');
    setResult(0);
  };

  const clearLastChar = () => {
    let tmp = input.substring(0, input.length - 1);
    setInput(tmp);
  };

  const whichFunction = (event: any, label: string) => {
    switch (label) {
      case '=':
        doCalculation(event);
        break;
      case 'C':
        clearAllInput();
        break;
      case 'Del':
        clearLastChar();
        break;
      default:
        updateInputHandler(event);
        break;
    }
  };

  return (
    <Fragment>
      <Grid
        container
        justifyContent="center" // Horizontal centering
        alignItems="center" // Vertical centering
        sx={{
          // minHeight: '100vh',
          padding: { xs: '50px 5% 0px 5%', md: '125px 30%' },
          pt: 0,
        }} // Ensure full viewport height>
        spacing={1}
      >
        <Grid xs={12}>
          <Item style={{ padding: '6px 0px' }}>
            <p
              style={{
                // fontSize: '4rem',
                // fontSize: '10vw',
                fontSize: 'clamp(2.5rem, 5vw, 4rem)',
                margin: '0px 0px 0px 7px',
                textAlign: 'right',
                // height: 88,
                padding: '0px 6px',
                borderBottom: `2px solid ${lightBlueTheme.palette.primary.main}`,
                borderTopLeftRadius: '6px',
                borderTopRightRadius: '6px',
              }}
            >
              {formatNumberWithCommas(formatNumberExpo(result))}
            </p>
            <p
              style={{
                fontSize: '1.4rem',
                margin: '10px 10px 0px 0px',
                textAlign: 'right',
                color: lightBlueTheme.palette.text.secondary,
                minHeight: 44,
              }}
            >
              {input}
            </p>
          </Item>
        </Grid>
        {CALCULATOR_BUTTONS.map((btn: any) => {
          return (
            <Grid item xs={3} key={btn.label}>
              <NumberButton
                variant={'contained'}
                color={btn.label === '=' ? 'primary' : 'secondary'}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                value={btn.label}
                onClick={(event) => {
                  // console.log("event:", event);
                  whichFunction(event, btn.label);
                }}
              >
                {btn.label}
                {/* <HTMLRenderer htmlString={btn.label} /> */}
              </NumberButton>
            </Grid>
          );
        })}
      </Grid>
    </Fragment>
  );
};

export default Calculator;
