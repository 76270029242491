import Add from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  styled,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Dayjs } from 'dayjs';

import { capitalize } from '../../../../Utils/StringUtils';
import { categories } from '../../../../constants';
import { Item } from '../../../General/Components';
import { useState } from 'react';

type SubmitTypeModalProps = {
  type: string;
  amount: number;
  date: Dayjs | null;
  category: string;
  description: string;
  handleTypeChange: (event: SelectChangeEvent) => void;
  handleCategoryChange: (event: SelectChangeEvent) => void;
  handleAmountChange: (event: any) => void;
  handleDescriptionChange: (event: any) => void;
  handleDateChange: (value: any) => void;
  handleBankingDataSubmit: () => void;
};

const style = {};

export const ModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 900,
  // height: 300,
  backgroundColor: `${theme.palette.background.default}`,
  borderRadius: 10,
  padding: 30,
}));

export const AddFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  right: '20px',
  bottom: '20px',
}));

const SubmitTypeModal = ({
  type,
  amount,
  date,
  category,
  description,
  handleTypeChange,
  handleCategoryChange,
  handleAmountChange,
  handleDescriptionChange,
  handleDateChange,
  handleBankingDataSubmit,
}: SubmitTypeModalProps) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Grid container direction="row">
        <Grid xs={12}>
          <Item>
            <AddFab
              color="primary"
              aria-label="add"
              size="large"
              onClick={handleOpen}
            >
              <Add />
            </AddFab>
          </Item>
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalBox
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          <FormControl>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select
                    label="Type"
                    name="type"
                    value={type}
                    onChange={handleTypeChange}
                  >
                    {['expense', 'deposit'].map((type) => (
                      <MenuItem key={type} value={type}>
                        {capitalize(type)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Number Input"
                  name="numberInput"
                  value={amount}
                  onChange={handleAmountChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    sx={{ width: '100%' }}
                    onChange={handleDateChange}
                    format="DD/MM/YYYY"
                    label="Date"
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Category</InputLabel>
                  <Select
                    label="Category"
                    name="category"
                    value={category}
                    onChange={handleCategoryChange}
                  >
                    {categories.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  // multiline
                  // rows={4}
                  value={description}
                  onChange={handleDescriptionChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Box textAlign="right">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleBankingDataSubmit}
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </FormControl>
        </ModalBox>
      </Modal>
    </div>
  );
};

export default SubmitTypeModal;
