import React, { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Header from './Header';
import { capitalize } from '../../../Utils/StringUtils';

type UnderConstructionProps = {
  text?: string;
};

const Container = styled('div')({
  textAlign: 'center',
  marginTop: '100px',
});

const Heading = styled(Typography)({
  fontSize: '3rem',
  marginBottom: '20px',
});

const Message = styled(Typography)({
  fontSize: '1.2rem',
  marginBottom: '20px',
});

const StyledButton = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
  padding: '10px 20px',
  borderRadius: '5px',
  fontSize: '1.2rem',
  transition: 'background-color 0.3s ease',

  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

const UnderConstruction = ({ text }: UnderConstructionProps) => {
  return (
    <Fragment>
      <Container>
        <Heading variant="h1">
          {capitalize(text)} Page Under Construction
        </Heading>
        <Message variant="body1">Please come back soon...</Message>
        <StyledButton to="/home">Go to Home</StyledButton>
      </Container>
    </Fragment>
  );
};

export default UnderConstruction;
