// import styled from "@emotion/styled";
// import '@fontsource/nunito-sans'; // Defaults to weight 400
// import '@fontsource/nunito-sans/400-italic.css'; // Specify weight and style
// import '@fontsource/nunito-sans/400.css'; // Specify weight
// import '@fontsource/nunito-sans/700-italic.css'; // Specify weight and style
// import '@fontsource/nunito-sans/700.css'; // Specify weight
import {
  Card,
  Grid,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Item } from '../General/Components';
import { CompanyLink } from '../Layout/Public/Bio/Intro';
import { centerStyle } from '../../constants';
import { FC } from 'react';

type CardWithChipAndDescProps = {
  obj?: any;
};

export const CardExperience = styled(Card)(({ theme }) => ({
  // minWidth: 900,
  // maxWidth: 1600,
  // width: 1500,
  width: '100%',
  padding: 24,
  // boxShadow:
  // 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
  boxShadow:
    'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;',
  borderRadius: 24,
  backgroundColor: `${theme.palette.background.default}`,
}));

const CardWithChipAndDesc = ({ obj }: CardWithChipAndDescProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let style: any = {
    padding: isMobile ? 0 : 2,
    borderRight: isMobile ? 'none' : '2px solid #42A5F5',
    // textAlign: isMobile ? 'left' : 'right',
    // justifyContent: 'flex-end',
  };

  if (!isMobile) {
    style = {
      // marginTop: 0,
      // paddingLeft: 1,
      // paddingRight: 4,
      // marginRight: 4,
      ...style,
      ...centerStyle,
    };
  }

  // marginTop: 54,
  // paddingLeft: 0,
  // paddingRight: 16,
  // marginRight: 32,
  // padding: isMobile ? 0 : 8,
  // borderRight: isMobile ? "none" : "2px solid #42A5F5",
  // textAlign: isMobile ? "left" : "right",

  // console.log('obj: ', obj);

  return (
    <CardExperience>
      <Grid container direction="row">
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          sx={{ ...style, textAlign: 'right' }}
          // spacing={9}
          justifyContent="right"
        >
          <Item sx={{ textAlign: isMobile ? 'left' : 'right' }}>
            <Typography variant="h6" sx={{ mb: 1, fontWeight: '700' }}>
              {obj?.title}
            </Typography>
            {obj.company && (
              <Typography variant="body1" sx={{ m: 0 }}>
                <CompanyLink href={obj.companyLink} target="_blank">
                  @ {obj.company}
                </CompanyLink>
              </Typography>
            )}
            <Typography variant="h6">{obj?.period}</Typography>
            {obj.location && (
              <Typography variant="body2" sx={{ mt: 0 }}>
                - {obj?.location}
              </Typography>
            )}
          </Item>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={9}
          style={{ textAlign: 'left', paddingLeft: '20px' }}
        >
          {/* <Typography variant="h6" sx={{ mb: 1, fontWeight: '700' }}>
            {obj?.title}
          </Typography>
          {obj.company && (
            <Typography variant="body1" sx={{ m: 0 }}>
              <CompanyLink href={obj.companyLink} target="_blank">
                @ {obj.company}
              </CompanyLink>
            </Typography>
          )} */}
          <Typography variant="body1" sx={{ mt: 1, mb: 2 }}>
            <DescriptionsList descriptions={obj?.descriptions} />
          </Typography>
        </Grid>
      </Grid>
    </CardExperience>
  );
};

const DescriptionsList = ({ descriptions }) => {
  // console.log('descriptions: ', descriptions);

  return (
    <ul
      style={{
        listStyleType: 'circle',
        paddingLeft: '20px',
        lineHeight: '1.8em',
      }}
    >
      {descriptions.map((desc, index) => (
        <li key={index} style={{ marginBottom: '8px' }}>
          {desc}
        </li>
      ))}
    </ul>
  );
};

export default CardWithChipAndDesc;
