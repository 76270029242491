import GitHubIcon from '@mui/icons-material/GitHub';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { Grid, IconButton, Link, Stack } from '@mui/material';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../App';
import { Item } from '../Components';

type SocialLinksProps = {
  text?: string;
};

export type SocialLink = {
  id: number;
  label: string;
  icon: any;
  url: string;
};

export const socialLinks: SocialLink[] = [
  {
    id: 1,
    label: 'LinkedIn',
    icon: <LinkedInIcon />,
    url: 'https://www.linkedin.com/in/henrystevensprofile/',
  },
  // {
  //   id: 2,
  //   label: 'GitHub',
  //   icon: <GitHubIcon />,
  //   url: 'https://github.com/',
  // },
  {
    id: 3,
    label: 'Instagram',
    icon: <InstagramIcon />,
    url: 'https://www.instagram.com/stenry00/?hl=en',
  },
  {
    id: 4,
    label: 'Resume (CV)',
    icon: <ContactPageIcon />,
    url: '/cv/Henry-Stevens-Senior Software Engineer-web.pdf',
    // url: 'https://www.instagram.com/stenry00/?hl=en',
  },
];

const SocialLinks = ({ text }: SocialLinksProps) => {
  return (
    <Stack direction="row" spacing={1} justifyContent="center" sx={{ mt: 4 }}>
      {socialLinks.map((link: SocialLink) => (
        <Link
          key={link.id}
          href={link.url}
          underline="none"
          sx={{ fontSize: '0.7rem' }}
          target="_blank"
          onClick={() =>
            logEvent(analytics, `social_media_link_${link.label}_clicked`)
          }
          download={link.label.includes('Resume') ? true : false}
        >
          <Grid container direction="row">
            <Grid xs={12}>
              <Item style={{ padding: 0 }}>
                <IconButton color="primary" aria-label={link.label}>
                  {link.icon}
                </IconButton>
              </Item>
            </Grid>
            <Grid xs={12}>
              <Item style={{ padding: 0 }}>
                <span>{link.label}</span>
              </Item>
            </Grid>
          </Grid>
        </Link>
      ))}
    </Stack>
  );
};

export default SocialLinks;
