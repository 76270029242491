import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  styled,
} from '@mui/material';
import React, { useState, useEffect, Fragment } from 'react';
import { capitalize, convertShortToLong } from '../../../../Utils/StringUtils';
import { Item } from '../../../General/Components';
import { ClassNames } from '@emotion/react';
import {
  DISTANCE,
  DISTANCE_ARRAY,
  WEIGHT,
  WEIGHT_ARRAY,
} from '../../../../constants';

type CalcBoxProps = {
  conversionType: string;
  type: string;
  text: string;
  handleInputChange?: any;
  handleFromTypeChange: any;
  sourceValue: number;
};

export const CalcContainer = styled(Paper)(({ theme }) => ({
  // ...theme.typography.h1,
  fontFamily: 'Nunito Sans',
  width: '100%',
  height: '150px',
  // background: '#fff',
  boxshadow:
    'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
}));

const CalcBox = ({
  conversionType,
  type,
  text,
  handleInputChange,
  handleFromTypeChange,
  sourceValue,
}: CalcBoxProps) => {
  let fromToValues = WEIGHT_ARRAY;

  switch (conversionType) {
    case WEIGHT:
      fromToValues = WEIGHT_ARRAY;
      break;
    case DISTANCE:
      fromToValues = DISTANCE_ARRAY;
      break;
    default:
      break;
  }

  return (
    <Fragment>
      <Grid container direction="row" sx={(theme) => ({ p: 0 })}>
        <Grid item xs={0} sm={4}></Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={(theme) => ({ width: { xs: '100%', sm: '200px' }, mb: 2 })}
        >
          {/* <Item> */}
          <FormControl fullWidth>
            <InputLabel id="calcbox-select-label">
              {capitalize(text)}
            </InputLabel>
            <Select
              labelId="calcbox-select-label"
              id="calcbox-select"
              value={type}
              label="Source"
              onChange={(event) => handleFromTypeChange(event)}
              style={{ textAlign: 'left' }}
            >
              {fromToValues.map((weight, index) => (
                <MenuItem key={weight} value={weight}>
                  {weight}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* </Item> */}
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>

      <CalcContainer>
        <div style={{ minWidth: '120px', height: '100%' }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={(theme) => ({
              height: '100%',
              py: 3,
              px: 2,
              backgroundColor: theme.palette.containerPrimary?.light,
            })}
          >
            <Grid item xs={12}>
              <Input
                id="standard-adornment-weight"
                endAdornment={
                  <InputAdornment position="end">{type}</InputAdornment>
                }
                aria-describedby="standard-weight-helper-text"
                inputProps={{ style: { fontSize: 40 } }} // font size of input text
                onChange={(event) => handleInputChange(event)}
                value={sourceValue}
              />
              <p style={{ fontSize: '1.4rem', marginTop: 10 }}>
                ({convertShortToLong(type)})
              </p>
            </Grid>
          </Grid>
        </div>
      </CalcContainer>
    </Fragment>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: 300,
    margin: 100,
  },
  //style for font size
  resize: {
    fontSize: 50,
  },
};

export default CalcBox;
