import {
  Box,
  Grid,
  Tab,
  Tabs,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import Header from '../Header';
import GalleryCarousel from './GalleryCarousel';
import MasonryImageList from './MasonryImageList';

import { listAll, ref } from 'firebase/storage';
import { storage } from '../../../../App';
import { getRandomNumbers } from '../../../../Utils/NumberUtils';
import { BEACH, FOOD } from '../../../../constants';

type GalleryProps = {
  text?: string;
};

export const TitleLanding = styled('h1')(({ theme }) => ({
  ...theme.typography.h1,
  fontFamily: 'Nunito Sans',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
  fontWeight: 100,
}));

export const GalleryTab = styled(Tab)(({ theme }) => ({
  fontSize: '1.3rem',
  textTransform: 'capitalize',
}));

const Gallery = ({ text }: GalleryProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [randomImageUrls, setRandomImageUrls] = useState<string[]>([]);

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    let folder = '';

    switch (value) {
      case 0:
        folder = BEACH;
        break;
      case 1:
        folder = FOOD;
        break;
      default:
        break;
    }
    const listRef = ref(storage, folder);

    listAll(listRef)
      .then((res) => {
        res.prefixes.forEach((folderRef) => {});
        const imgUrls: string[] = [];
        res.items.forEach((itemRef) => {
          imgUrls.push(
            // `https://firebasestorage.googleapis.com/v0/b/everythingstevens-8a3f4.appspot.com/o/${folder}%2F${itemRef.name}?alt=media&token=34dcc265-38fe-4819-9097-a7edb3f03b61`
            ``
          );
        });
        setImageUrls(imgUrls);
      })
      .catch((error) => {
        console.log('error', error);
      });

    getRandomImages();
  }, [value]);

  useEffect(() => {
    getRandomImages();
  }, [imageUrls]);

  const getRandomImages = () => {
    console.log('getting');
    const randomNumbers = getRandomNumbers(0, imageUrls.length - 1, 6);

    const randomImgUrls: string[] = [];

    console.log('randomNumbers', randomNumbers);

    randomNumbers.forEach((num) => {
      console.log('imageUrls[num]', imageUrls[num], num);
      randomImgUrls.push(imageUrls[num]);
    });
    console.log('1 randomImgUrls', randomImgUrls);

    setRandomImageUrls(randomImgUrls);
  };

  return (
    <Fragment>
      <Grid container justifyContent="center" direction="row">
        <Grid xs={12} justifyContent="center" style={{ textAlign: 'center' }}>
          <h1
            style={{
              fontFamily: "'Dancing Script', cursive",
              fontSize: '5rem',
              marginBottom: 0,
            }}
          >
            Gallery
          </h1>
        </Grid>
        {!isMobile && (
          <Grid xs={9}>
            <GalleryCarousel randomImageUrls={randomImageUrls} />
          </Grid>
        )}
        <Grid xs={12}>
          <Grid container direction="row">
            <Grid xs={12} style={{ display: 'flex' }} justifyContent="center">
              <Box sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="gallery-tabs"
                  >
                    <GalleryTab label="Beach" {...a11yProps(0)} />
                    <GalleryTab label="Food" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <MasonryImageList imageUrls={imageUrls} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <MasonryImageList imageUrls={imageUrls} />
                </CustomTabPanel>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default Gallery;
