import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Card, CardContent, Grid, IconButton, useTheme } from '@mui/material';
import { Fact } from '../Layout/Public/Facts/Facts';
import { Item } from './Components';
// // @ts-ignore
// import Fade from "react-awesome-reveal/Fade";

type OutlinedCardTextProps = {
  obj?: any;
  isFavourite: boolean;
  favouritiseFactHandler: (fact: Fact, addToLS: boolean) => void;
};

const OutlinedCardText = ({
  obj,
  isFavourite,
  favouritiseFactHandler,
}: OutlinedCardTextProps) => {
  const theme = useTheme();

  return (
    <Card
      variant="outlined"
      style={{
        fontFamily: 'Nunito Sans',
        padding: 10,
        height: 140,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        backgroundColor: 'transparent',
        border: 'none',
      }}
    >
      <CardContent
        style={{
          width: '100%',
          height: '100%',
          padding: '0px 10px',
          borderLeft: `2px solid ${theme.palette.primary.main}`,
        }}
      >
        {/* <Fade left duration={400}> */}
        <Grid container direction="row">
          <Grid item xs={11}>
            <Item>
              <div className="" style={{}}>
                <blockquote>
                  <p style={{ marginTop: 30 }}>{obj.text}</p>
                </blockquote>
              </div>
            </Item>
          </Grid>
          <Grid item xs={1} style={{ float: 'right' }}>
            <Item>
              <IconButton
                aria-label="add to favorites"
                onClick={() =>
                  isFavourite
                    ? favouritiseFactHandler(obj, false)
                    : favouritiseFactHandler(obj, true)
                }
              >
                {isFavourite ? (
                  <FavoriteIcon color="error" />
                ) : (
                  <FavoriteBorderIcon />
                )}
              </IconButton>
            </Item>
          </Grid>
        </Grid>
        {/* </Fade> */}
      </CardContent>
      {/* <CardActions style={{ float: "right" }}></CardActions> */}
    </Card>
    // </Box>
  );
};

export default OutlinedCardText;
