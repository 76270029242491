import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import beach1 from '../../../../resources/images/beach1.jpg';
import beach2 from '../../../../resources/images/beach2.jpg';
import beach3 from '../../../../resources/images/beach3.jpg';
import beach4 from '../../../../resources/images/beach4.jpg';
import beach5 from '../../../../resources/images/beach5.jpg';
import beach6 from '../../../../resources/images/beach6.jpg';
import beach7 from '../../../../resources/images/beach7.jpg';
import beach8 from '../../../../resources/images/beach8.jpg';
import beach9 from '../../../../resources/images/beach9.jpg';
import beach10 from '../../../../resources/images/beach10.jpg';
import {
  Button,
  Modal,
  Backdrop,
  Fade,
  Typography,
  ImageListItemBar,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImageWithPlaceholder from './ImageWithPlaceholder';

type MasonryImageListProps = {
  imageUrls: string[];
};

const itemData = [
  {
    img: beach1,
    title: 'Bed',
  },
  {
    img: beach2,
    title: 'Books',
  },
  {
    img: beach3,
    title: 'Sink',
  },
  {
    img: beach10,
    title: 'Storage',
  },
  {
    img: beach4,
    title: 'Kitchen',
  },
  {
    img: beach5,
    title: 'Blinds',
  },
  {
    img: beach10,
    title: 'Storage',
  },
  {
    img: beach6,
    title: 'Chairs',
  },
  {
    img: beach7,
    title: 'Laptop',
  },
  {
    img: beach8,
    title: 'Doors',
  },
  {
    img: beach9,
    title: 'Coffee',
  },
  {
    img: beach10,
    title: 'Storage',
  },
];

const MasonryImageList = ({ imageUrls }: MasonryImageListProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = React.useState(false);
  const [myImage, setMyImage] = React.useState(beach2);

  const handleOpen = (image: any) => {
    setOpen(true);
    setMyImage(image);
  };
  const handleClose = () => setOpen(false);

  return (
    <React.Fragment>
      <Box sx={{ width: '100%', overflowY: 'scroll', margin: '0 auto' }}>
        <ImageList variant="masonry" cols={isMobile ? 1 : 4} gap={16}>
          {imageUrls.map((item, index) => (
            <ImageListItem key={item + ':' + index}>
              {/* <img
                key={index}
                srcSet={item}
                src={item}
                alt={item}
                loading="lazy"
                style={{ borderRadius: '4px' }}
                onClick={() => handleOpen(item)}
              /> */}
              <ImageWithPlaceholder
                // src="https://example.com/your-image.jpg"
                // alt="Description of image"
                key={index}
                // srcSet={item}
                src={item}
                alt={item}
                // loading="lazy"
                // style={{ borderRadius: '4px' }}
                onClick={() => handleOpen(item)}
                // width={500}
                // height={300}
              />
              {/* <LazyLoadImage
                // alt={item}
                // height={image.height}
                // src={item} // use normal <img> attributes as props
                // width={image.width}
                key={index}
                srcSet={item}
                src={item}
                alt={item}
                loading="lazy"
                style={{ borderRadius: '4px' }}
                onClick={() => handleOpen(item)}
              /> */}
              <LazyLoadImage src="image.jpg" alt="description" effect="blur" />
              <ImageListItemBar
                title="Breakfast"
                subtitle="@henrystevens-google"
                actionIcon={
                  <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    aria-label={`info about ${item}`}
                    onClick={() => handleOpen(item)}
                  >
                    <InfoOutlined />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          style={{ backdropFilter: 'blur(2px)', backgroundColor: 'ffffff7a' }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <img
                srcSet={myImage}
                src={myImage}
                alt={'title'}
                loading="lazy"
                style={{ borderRadius: '4px', width: '100%' }}
              />
              <div
                style={{
                  width: '100%',
                  padding: 10,
                  position: 'absolute',
                  bottom: 7,
                  backgroundColor: '#000000bb',
                  color: '#fff',
                }}
              >
                <Typography variant="h6">Breakfast</Typography>
                <Typography variant="body1">@henrystevens-google</Typography>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
    </React.Fragment>
  );
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 600,
  // height: 800,
  // maxHeight: 780,
  // bgcolor: "background.paper",
  // boxShadow: 24,
  // p: 1,
  borderRadius: '4px',
};

export default MasonryImageList;
