import React, { useState, useEffect, Fragment, ReactNode } from 'react';
import Header from './Header';
import { Box, Grid, Typography, styled } from '@mui/material';
import { Item } from '../../General/Components';
import ButtonWithImage from '../../General/ButtonWithImage';
import beach1 from '../../../resources/images/beach1.jpg';
import Footer from '../../General/Footer/Footer';
import Navbar from '../../General/NavBar/Navbar';

type PublicPageProps = {
  children?: ReactNode;
  text?: string;
  showHeader?: boolean;
  showFooter?: boolean;
};

export const TitleLanding = styled('h1')(({ theme }) => ({
  ...theme.typography.h1,
  fontFamily: 'Nunito Sans',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
  fontWeight: 100,
}));

const Container = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  margin: '160px auto',
  alignItems: 'flex-start',
  width: '100%',
}));

const pages = ['Blog', 'Gallery', 'Calculator', 'Facts', 'Conversion'];

const PublicPage = ({
  children,
  text,
  showHeader = true,
  showFooter,
}: PublicPageProps) => {
  return (
    <Box>
      {/* {showHeader && <Header />} */}
      {showHeader && <Navbar />}
      {children}
      <Footer show={showFooter} />
    </Box>
  );
};

export default PublicPage;
