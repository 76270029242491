import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ScreenRotationAltIcon from '@mui/icons-material/ScreenRotationAlt';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  useTheme,
} from '@mui/material';
import { Fact } from './Facts';
import { Item } from '../../../General/Components';
// import '@fontsource/nunito-sans'; // Defaults to weight 400
// import '@fontsource/nunito-sans/400-italic.css'; // Specify weight and style

type RandomFactCardProps = {
  obj?: any;
  isFavourite: boolean;
  favouritiseFactHandler: (fact: Fact, addToLS: boolean) => void;
};

const RandomFactCard = ({
  obj,
  isFavourite,
  favouritiseFactHandler,
}: RandomFactCardProps) => {
  const theme = useTheme();

  return (
    <Card
      variant="outlined"
      style={{
        fontFamily: 'Nunito Sans',
        padding: 10,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        backgroundColor: 'transparent',
        border: 'none',
        fontStyle: 'italic',
      }}
    >
      <CardContent
        style={{
          width: '100%',
          height: '100%',
          padding: '0px 10px',
          borderRadius: 10,
          fontSize: '3rem',
        }}
      >
        <Grid container direction="row">
          <Grid item xs={11}>
            <Item>
              <div className="" style={{}}>
                <blockquote>
                  <p style={{ marginTop: 30 }}>{obj.text}</p>
                </blockquote>
              </div>
            </Item>
          </Grid>
          <Grid item xs={1} style={{ float: 'right' }}>
            <Item>
              <Stack direction="row">
                <Tooltip title="Click here or on the text to randomise the fact">
                  <IconButton aria-label="how-to-randomise" color="primary">
                    <ScreenRotationAltIcon />
                  </IconButton>
                </Tooltip>
                <IconButton
                  aria-label="add to favorites"
                  onClick={() =>
                    isFavourite
                      ? favouritiseFactHandler(obj, false)
                      : favouritiseFactHandler(obj, true)
                  }
                >
                  {isFavourite ? (
                    <FavoriteIcon color="error" />
                  ) : (
                    <FavoriteBorderIcon />
                  )}
                </IconButton>
              </Stack>
            </Item>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RandomFactCard;
