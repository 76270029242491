export const facts = [
  {
    uuid: '1',
    text: 'Honey never spoils. Archaeologists have found pots of honey in ancient Egyptian tombs that are over 3,000 years old and still perfectly edible.',
  },
  {
    uuid: '2',
    text: 'The shortest war in history lasted between Britain and Zanzibar on August 27, 1896, and lasted for only 38 minutes.',
  },
  {
    uuid: '3',
    text: 'Bananas are berries, but strawberries are not.',
  },
  {
    uuid: '4',
    text: 'Octopuses have three hearts. Two pump blood to the gills, while the third pumps it to the rest of the body.',
  },
  {
    uuid: '5',
    text: 'The Eiffel Tower can be 6 inches taller during the summer due to the expansion of the iron in the heat.',
  },
  {
    uuid: '6',
    text: 'A day on Venus, the planet, is longer than a year on Venus. It takes Venus about 243 Earth days to rotate on its axis and only 225 Earth days to orbit the Sun.',
  },
  {
    uuid: '7',
    text: 'Cows have best friends and can become stressed when they are separated from them.',
  },
  {
    uuid: '8',
    text: 'The Great Wall of China is not visible from space with the naked eye, contrary to popular belief.',
  },
  {
    uuid: '9',
    text: "The unicorn is Scotland's national animal.",
  },
  {
    uuid: '10',
    text: 'The total weight of ants on Earth is estimated to be roughly equal to the total weight of all the humans on Earth.',
  },
  {
    uuid: '11',
    text: "A group of flamingos is called a 'flamboyance.'",
  },
  {
    uuid: '12',
    text: 'The largest desert in the world is not the Sahara, but Antarctica.',
  },
  {
    uuid: '13',
    text: 'The longest hiccuping spree on record lasted for 68 years.',
  },
  {
    uuid: '14',
    text: "The world's largest recorded snowflake was 15 inches wide and 8 inches thick.",
  },
  {
    uuid: '15',
    text: 'The average person will spend six months of their life waiting for red lights to turn green.',
  },
  {
    uuid: '16',
    text: "A group of owls is called a 'parliament.'",
  },
  {
    uuid: '17',
    text: "The world's smallest mammal is the bumblebee bat, with a wingspan of about 5.7 inches.",
  },
  {
    uuid: '18',
    text: 'Polar bears are left-handed.',
  },
  {
    uuid: '19',
    text: 'The shortest war in history was between Britain and Zanzibar on August 27, 1896. It lasted only 38 minutes.',
  },
  {
    uuid: '20',
    text: "The world's oldest known recipe is for beer, dating back over 4,000 years.",
  },
  {
    uuid: '21',
    text: "A single strand of spaghetti is called a 'spaghetto.'",
  },
  {
    uuid: '22',
    text: 'Cows have regional accents.',
  },
  {
    uuid: '23',
    text: 'The average person will walk the equivalent of three times around the Earth in their lifetime.',
  },
  {
    uuid: '24',
    text: 'Hippopotamus milk is pink.',
  },
  {
    uuid: '25',
    text: "The longest word in the English language without a vowel is 'rhythms.'",
  },
  {
    uuid: '26',
    text: 'The longest time between two twins being born is 87 days.',
  },
  {
    uuid: '27',
    text: 'The oldest known sample of the smallpox virus has been found in the teeth of a 17th-century child buried in Lithuania.',
  },
  {
    uuid: '28',
    text: 'The unicorn is the national animal of Scotland.',
  },
  {
    uuid: '29',
    text: 'The largest snowflake ever recorded was 15 inches wide.',
  },
  {
    uuid: '30',
    text: "The world's oldest piece of chewing gum is over 9,000 years old.",
  },
  {
    uuid: '31',
    text: "A group of ferrets is called a 'business.'",
  },
  {
    uuid: '32',
    text: 'Honey never spoils. Archaeologists have found pots of honey in ancient Egyptian tombs that are over 3,000 years old and still perfectly edible.',
  },
  {
    uuid: '33',
    text: 'The longest hiccuping spree on record lasted for 68 years.',
  },
  {
    uuid: '34',
    text: 'A day on Venus is longer than a year on Venus. It takes Venus about 243 Earth days to rotate on its axis and only 225 Earth days to orbit the Sun.',
  },
  {
    uuid: '35',
    text: "The world's largest desert is not the Sahara, but Antarctica.",
  },
  {
    uuid: '36',
    text: 'The average person will spend six months of their life waiting for red lights to turn green.',
  },
  {
    uuid: '37',
    text: 'Bananas are berries, but strawberries are not.',
  },
  {
    uuid: '38',
    text: 'Octopuses have three hearts. Two pump blood to the gills, while the third pumps it to the rest of the body.',
  },
  {
    uuid: '39',
    text: 'Cows have best friends and can become stressed when they are separated from them.',
  },
  {
    uuid: '40',
    text: 'The Great Wall of China is not visible from space with the naked eye, contrary to popular belief.',
  },
  {
    uuid: '41',
    text: 'The total weight of ants on Earth is estimated to be roughly equal to the total weight of all the humans on Earth.',
  },
  {
    uuid: '42',
    text: "A group of flamingos is called a 'flamboyance.'",
  },
  {
    uuid: '43',
    text: "The world's smallest mammal is the bumblebee bat, with a wingspan of about 5.7 inches.",
  },
];
