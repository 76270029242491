import {
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import React, { useState, useEffect, Fragment } from 'react';
import { Item } from '../../../General/Components';
import { type } from '@testing-library/user-event/dist/type';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import BankingTypeCard from './BankingTypeCard';
import { DEPOSIT, EXPENSE, categories } from '../../../../constants';
// import { Dayjs } from "dayjs";
import dayjs, { Dayjs } from 'dayjs';
import { AtmOutlined } from '@mui/icons-material';
import SubmitTypeModal from './SubmitTypeModal';
import { sortByDate } from '../../../../Utils/DateUtils';
import { formatNumberWithCommas } from '../../../../Utils/NumberUtils';
import CountUp from 'react-countup';
import {
  calculateTotalAmount,
  createInitialBankingEntries,
  groupTransactionsByDate,
} from './BankingUtils';
import ScatterChart from './Charts/ScatterChart';
import BarChart, { otherData } from './Charts/BarChart';
import Transactions from './Transactions';
import Analytics from './Analytics';

type BankingProps = {
  text?: string;
};

export type BankingDataType = {
  type: string;
  date: string;
  amount: number;
  description: string;
  category: string;
};

export type BankingGrouped = {
  date: string;
  transactions: BankingDataType[];
};

export type BankingType = 'expense' | 'deposit';

export const BankingTypeButton = styled(Button)(({ theme }) => ({
  textTransform: 'capitalize',
}));

const Banking = ({ text }: BankingProps) => {
  const theme = useTheme();

  const [category, setCategory] = useState<string>('');
  const [type, setType] = useState<BankingType>(EXPENSE);
  const [amount, setAmount] = useState<number>();
  const [description, setDescription] = useState<string>('');
  const [date, setDate] = useState<Dayjs | null>(dayjs());
  const [bankingData, setBankingData] = useState<BankingDataType[]>(
    createInitialBankingEntries()
  );
  const [total, setTotal] = useState(calculateTotalAmount(bankingData));
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value as BankingType);
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  const handleAmountChange = (event: any) => {
    setAmount(Number(event.target.value));
  };

  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  };

  const handleDateChange = (value: Dayjs) => {
    console.log('date: ', value);
    setDate(value);
  };

  const handleBankingDataSubmit = () => {
    const temp: BankingDataType = {
      type: type,
      category: category,
      amount: amount || 0,
      description: description,
      date: dayjs(date).format('DD/MM/YYYY').toString(),
    };

    console.log('format date: ', dayjs(date).format('DD/MM/YYYY').toString());

    console.log('temp', temp);

    setBankingData([...bankingData, temp]);
  };

  let tabContent = <></>;
  switch (tabValue) {
    case 0:
      tabContent = <Transactions transactions={bankingData} />;
      break;
    case 1:
      tabContent = <Analytics transactions={bankingData} />;
      break;
    default:
      tabContent = (
        <Typography variant="h3">Error: Tab doesnt exist</Typography>
      );
      break;
  }

  return (
    <Stack direction="column">
      <Grid
        container
        direction="row"
        sx={{ p: 4, backgroundColor: `${theme.palette.primary.main}` }}
      >
        <Grid xs={12}>
          <Item>
            <Typography
              variant="h2"
              sx={{ color: `${theme.palette.primary.contrastText}` }}
            >
              <CountUp
                start={0}
                end={calculateTotalAmount(bankingData)}
                decimals={2}
                prefix="$"
              />
            </Typography>
          </Item>
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid xs={12}>
          <Tabs value={tabValue} onChange={handleChange} centered>
            <Tab label="Transactions" />
            <Tab label="Analytics" />
          </Tabs>
        </Grid>
      </Grid>
      {tabContent}
      <SubmitTypeModal
        type={type}
        amount={amount || 0}
        date={date}
        category={category}
        description={description}
        handleTypeChange={handleTypeChange}
        handleCategoryChange={handleCategoryChange}
        handleAmountChange={handleAmountChange}
        handleDescriptionChange={handleDescriptionChange}
        handleDateChange={handleDateChange}
        handleBankingDataSubmit={handleBankingDataSubmit}
      />
    </Stack>
  );
};

export default Banking;
