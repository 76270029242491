import React, { useState, useEffect, Fragment } from 'react';
import Intro from './Intro';
import About from './About';
import Ideology from './Ideology';
import Experience from './Experience';
import Portfolio from './Portfolio';
import ContactMe from './ContactMe';
import { useTheme } from '@mui/material';
// import { useTheme } from "@emotion/react";

type HomeProps = {
  text?: string;
};

const Home = ({ text }: HomeProps) => {
  const theme = useTheme();

  // Determine the backgroundColour within the WSection component
  const bgcM = theme.palette.containerPrimary?.main || '';
  const bgcS = theme.palette.containerSecondary?.main || '';

  console.log(
    'theme.palette.containerPrimary?.main',
    theme.palette.containerPrimary?.main
  );

  console.log(
    'theme.palette.containerSecondary?.main',
    theme.palette.containerSecondary?.main
  );

  const sections = [Intro, About, Experience, Portfolio, ContactMe];
  return (
    <Fragment>
      {sections.map((Component, index) => (
        <Component
          key={index}
          backgroundColour={index % 2 === 0 ? bgcM : bgcS}
        />
      ))}
    </Fragment>
  );
};

export default Home;
