import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { Fragment } from 'react';
import { capitalize, convertShortToLong } from '../../../../Utils/StringUtils';
import { Item } from '../../../General/Components';
import { Tracing } from 'trace_events';
import {
  convertToEPower,
  formatNumberWithCommas,
} from '../../../../Utils/NumberUtils';
import {
  DISTANCE,
  DISTANCE_ARRAY,
  WEIGHT,
  WEIGHT_ARRAY,
} from '../../../../constants';

type ResultsBoxProps = {
  conversionType: string;
  type: string;
  text: string;
  handleInputChange?: any;
  handleToTypeChange: any;
  targetValue: number;
};

export const CalcContainer = styled(Paper)(({ theme }) => ({
  ...theme.typography.h1,
  fontFamily: 'Nunito Sans',
  width: '100%',
  height: '150px',
  // background: theme.palette.background.paper,
  background: '#fff',
  // boxShadow:
  //   "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
  // boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
  boxshadow:
    'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
}));

export const ResultsBoxText = styled('div')(({ theme }) => ({
  // ...theme.typography.h1,
  fontFamily: 'Nunito Sans',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.primary,
  fontWeight: 100,
  fontSize: '3rem',
  // marginTop: '50px',
}));

const ResultsBox = ({
  type,
  text,
  handleInputChange,
  handleToTypeChange,
  targetValue,
  conversionType,
}: ResultsBoxProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let fromToValues = WEIGHT_ARRAY;

  switch (conversionType) {
    case WEIGHT:
      fromToValues = WEIGHT_ARRAY;
      break;
    case DISTANCE:
      fromToValues = DISTANCE_ARRAY;
      break;

    default:
      break;
  }

  return (
    <Fragment>
      <Grid container direction="row">
        <Grid item xs={4}></Grid>
        <Grid item xs={4} style={{ marginBottom: 10 }}>
          <Item>
            <FormControl fullWidth>
              <InputLabel id="results-box-select-label">
                {capitalize(text)}
              </InputLabel>
              <Select
                labelId="results-box-select-label"
                id="demo-simple-select"
                value={type}
                label="Source"
                onChange={handleToTypeChange}
                style={{ textAlign: 'left' }}
              >
                {fromToValues.map((weight, index) => (
                  <MenuItem key={weight} value={weight}>
                    {weight}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>

      <CalcContainer>
        <div style={{ height: '100%' }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={(theme) => ({
              height: '100%',
              backgroundColor: theme.palette.containerPrimary?.light,
            })}
          >
            <Grid item xs={12}>
              <ResultsBoxText>
                {targetValue > 1000000
                  ? convertToEPower(targetValue)
                  : formatNumberWithCommas(targetValue)}
                <b> {type}</b>
                <p style={{ fontSize: '1.4rem', marginTop: 10 }}>
                  ({convertShortToLong(type)})
                </p>
              </ResultsBoxText>
            </Grid>
          </Grid>
        </div>
      </CalcContainer>
    </Fragment>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: 300,
    margin: 100,
  },
  //style for font size
  resize: {
    fontSize: 50,
  },
};

export default ResultsBox;
