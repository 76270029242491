import {
  Box,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { useState } from 'react';
import { Anchor, TagType } from './TaskList';
import { Fade } from 'react-awesome-reveal';
// @ts-ignore
// import Fade from "react-awesome-reveal/Fade";

type TaskProps = {
  statusListIndex: number;
  taskIndex: number;
  item: any;
  // removeItem: (statusIndex: number, itemIndex: number) => void;
  removeItem: (title: string) => void;
  changeTaskStatus: (title: string, status: string) => void;
  toggleDrawer: (anchor: Anchor, open: boolean, itemDescription: string) => any;
  taskColour: string;
};

export const TaskStack = styled(Stack)(({ theme }) => ({
  minHeight: 56,
  // width: 450,
  backgroundColor: `${theme.palette.containerPrimary?.light}`,
  padding: 10,
  borderRadius: 4,
  color: `${theme.palette.containerPrimary?.contrastText}`,
  cursor: 'pointer',
  // borderLeft: `4px solid ${theme.palette.primary.main}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
  alignItems: 'center',
  // backgroundColor: "#fff",
}));

const Task = ({
  statusListIndex,
  taskIndex,
  item,
  removeItem,
  changeTaskStatus,
  toggleDrawer,
  taskColour,
}: TaskProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: any) => {
    setAnchorEl(null);
    changeTaskStatus(item.title, event?.target.value);
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <Fade duration={200}>
      <TaskStack
        direction="row"
        justifyContent="space-between"
        style={{
          borderLeft: `4px solid ${taskColour}`,
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Stack
          direction="column"
          spacing={2}
          sx={{ width: '100%', minHeight: 66 }}
        >
          <Stack
            direction="row"
            sx={{ minHeight: 34 }}
            justifyContent="space-between"
          >
            <Typography
              variant="body1"
              sx={{ width: '60%' }}
              onClick={toggleDrawer('right', true, item.description)}
            >
              {item.title && item.title.length > 0 ? item.title : 'Untitled'}
            </Typography>

            <Stack direction="row">
              {/* {!isMobile && isHovered && ( */}
              <>
                <IconButton
                  aria-label="delete"
                  size="small"
                  color="primary"
                  onClick={() => removeItem(item.title)}
                  sx={{ zIndex: 10 }}
                  // disabled={isHovered ? false : true}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  size="small"
                  color="primary"
                  onClick={handleClick}
                  sx={{ zIndex: 10 }}
                  // disabled={isHovered ? false : true}
                >
                  <ChangeCircleIcon />
                </IconButton>
              </>
              {/* )} */}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={(e) => handleClose(e)} value="Completed">
                  Completed
                </MenuItem>
                <MenuItem onClick={(e) => handleClose(e)} value="Pending">
                  Pending
                </MenuItem>
                <MenuItem onClick={(e) => handleClose(e)} value="In Progress">
                  In Progress
                </MenuItem>
              </Menu>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1}>
            {item.tags.map((tag: TagType) => (
              <Chip
                // color="info"
                size="small"
                label={tag.text}
                sx={{ backgroundColor: `${tag.colour}` }}
              />
            ))}

            {/* <Chip color="secondary" size="small" label="Development" /> */}
          </Stack>
        </Stack>
      </TaskStack>
    </Fade>
  );
};

export default Task;
