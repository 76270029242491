import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const SafeRoute = ({ element, ...rest }) => {
  const location = useLocation();

  useEffect(() => {
    try {
      // Log the requested URI
      console.log('Requested URI:', location.pathname);

      // Attempt to decode the URI
      // decodeURIComponent(location.pathname);
    } catch (error) {
      // Catch the error and log the malformed URI
      console.error('Malformed URI:', location.pathname);
    }
  }, [location]);

  return (
    // Render the element directly
    <div {...rest}>{element}</div>
  );
};

export default SafeRoute;
